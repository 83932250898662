import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { isLoadingAtom, selectedInstallationAtom } from "../../recoil/atoms";
import Loading from "../Loading/Loading";
import useOnInit from "../../hooks/useOnInit";
import Header from "../Layout/header/Header";
import InstallationBody from "./InstallationBody";

const Installations = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const resetInstallation = useResetRecoilState(selectedInstallationAtom);

  const onInit = useOnInit();
  // on mount calls BE asking for all Plants

  const init = async () => {
    resetInstallation();
    await onInit.initPlant();
    setIsLoading(false)
  }
  useEffect(() => {
    init()
  }, []);

  if (isLoading) return <Loading></Loading>;
  return (
    <Stack height={"100%"}>
      <Box height={"var(--header-height)"}>
        <Header />
      </Box>
      <InstallationBody />
    </Stack>
  );
};

export default Installations;
