import { atom } from "recoil";
import { EHttpBodyType, EHttpLanguageType, EHttpReturnType, EMethod, EServiceType, IRestService, IService } from "../common-interfaces/interfaces";

export const newServiceAtom = atom<IService>({
  key: 'newService',
  default: {
    _id: "newService",
    name: "",
    description: "",
    editable: true,
    protocol: EServiceType.Rest,
    host: "",
    serviceType: EServiceType.Rest,
    brand: 'custom'
  }
});

export const restServiceAtom = atom<IRestService>({
  key: "restService",
  default: {
    requestState: {
      endpoint: "",
      method: EMethod.GET,
      response: {
        languageType: EHttpLanguageType.JSON,
        fieldToReturn: "",
        type: EHttpReturnType.BOOLEAN,
      },
    },
    requestSet: {
      endpoint: "",
      method: EMethod.PUT,
      body: {
        mode: EHttpBodyType.RAW,
        raw: EHttpBodyType.RAW,
        options: {
          raw: {
            lang: EHttpLanguageType.JSON,
          },
        },
      },
      response: {
        languageType: EHttpLanguageType.JSON,
        fieldToReturn: "",
        type: EHttpReturnType.BOOLEAN,
      },
    },
  },
});

export const stepOneAtom = atom<boolean>({
  key: 'stepOne',
  default: false
})
export const stepTwoAtom = atom<boolean>({
  key: 'stepTwo',
  default: false
})
export const stepThreeAtom = atom<boolean>({
  key: 'stepThree',
  default: false
})
export const errAtom = atom<boolean>({
  key: 'err',
  default: false
})
