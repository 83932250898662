import { IComponent, IConfiguration, IDashboard, IInstallation, IPlant, IService } from "../../common-interfaces/interfaces"

export enum ADMINTOOOL_Value {
  installation = 'installation',
  configuration = 'configuration',
  dashboard = 'dashboard',
  component = 'component',
  service = 'service',
}


export const ADMINTOOOL_COLUMNS = Object.values(ADMINTOOOL_Value)

export interface IAdminToolFilterBy {
  installation: { id: string }
  configuration: { id: string }
  dashboard: { ids: string[] }
  component: { ids: string[] }
  service: { ids: string[] }
}
export interface IAdminToolQuery {
  section: ADMINTOOOL_Value,
  value: string
}
export interface IAdminToolStore {
  installation: Record<string, IInstallation>
  configuration: Record<string, IConfiguration>
  dashboard: Record<string, IDashboard>
  component: Record<string, IComponent>
  service: Record<string, IService>
}

export const emptyQueries: IAdminToolFilterBy = {
  installation: { id: '' },
  configuration: { id: '' },
  dashboard: { ids: [''] },
  component: { ids: [''] },
  service: { ids: [''] },
}