import React, { useEffect, useState } from "react";
import { Grid, TextField, Box, Card, ThemeProvider, Link, InputAdornment, Alert } from "@mui/material";
import { theme } from "../../theme";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../../styles/Login.css";
import { useRecoilState, useResetRecoilState } from "recoil";
import { isVerboseAtom, loggedUserAtom } from "../../recoil/atoms";
import { ILoggedUser } from "../../local-interfaces/local-interfaces";
import packageJson from "../../../package.json";
import useOnInit from "../../hooks/useOnInit";
import { Print } from "../../helpers/utils";
import img from "./../../assets/svg/header.svg";
import { useOnUser } from "../../hooks/useOnUser";

const Login = () => {
  const onInit = useOnInit();
  const onUser = useOnUser();
  const navigate = useNavigate();

  const [__, setIsVerbose] = useRecoilState(isVerboseAtom);
  const resetLoggedUser = useResetRecoilState(loggedUserAtom);
  const [_, setLoggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);

  const [version, setVersion] = useState<string>("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [isLoginCorrect, setIsLoginCorrect] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [pswFocus, setPswFocus] = useState(false);
  const [errorMsg, setErrotMsg] = useState("");

  useEffect(() => {
    const version = packageJson.version;
    version && setVersion(version)
    resetLoggedUser()
    onInit.reset()
  }, []);

  const handleChangeUsername = (event: { target: { value: React.SetStateAction<string> } }) => {
    isLoginCorrect === false && setIsLoginCorrect(true)
    setIsValidEmail(true);
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: { target: { value: React.SetStateAction<string> } }) => {
    isLoginCorrect === false && setIsLoginCorrect(true);
    setPassword(event.target.value);
  };

  const isInvalidEmail = (e: any) => setIsValidEmail(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(e.target.value))

  const openApkNewTab = () => window.open(`${process.env.REACT_APP_MOBILEAPPURL}`, "_blank", "noreferrer")

  const handleLogin = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setloadingBtn(true);
    setisDisabled(true);
    try {
      const res: ILoggedUser | undefined = await onUser.login(username, password);
      setIsLoginCorrect(res !== undefined);
      if (res === undefined) {
        setErrotMsg("Invalid Username or Password. Please try again.");
        setOpen(true);
        throw new Error("No res");
      }
      if (res?.role !== "installer" && res?.role !== "admin") {
        setErrotMsg(
          `Access Denied.  ${res.role == "client" ? "Please contact the installer" : ""
          }`
        );
        setOpen(true);
        throw new Error("Role Error");
      }
      setLoggedUser(res);
      setIsVerbose(false);
      navigate("/installations");
    } catch (e) {
      Print([e]);
    } finally {
      setloadingBtn(false);
      setisDisabled(false);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          flexGrow: 1,
        }}
        container
        alignItems="center"
        justifyContent="center"
        height="100%"
        className="loginBodyBackground"
      >
        <Grid item xs={10} sm={8} lg={6} xl={4}>
          <Card
            variant="outlined"
            className="loginCard"
            sx={{
              p: 3,
            }}
          >
            <form onSubmit={handleLogin}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    textAlign="center"
                    sx={{
                      mt: {
                        xs: 5,
                      },
                      mb: {
                        xs: 5,
                      },
                    }}
                  >
                    <img src={img} alt="K" className="logoK" />
                    <Box
                      sx={{
                        fontSize: "10px",
                        width: "100%",
                        marginBottom: "75px",
                      }}
                    >
                      version : {version}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="loginInput"
                    id="email-input"
                    type="email"
                    fullWidth
                    error={!isValidEmail}
                    value={username}
                    variant="standard"
                    label="Username"
                    autoFocus
                    autoCapitalize="none"
                    onChange={handleChangeUsername}
                    onBlur={isInvalidEmail}
                    disabled={isDisabled}
                    sx={[
                      {
                        "& .Mui-error": {
                          color: "#952727 !important",
                        },
                      },
                    ]}
                    helperText={
                      !isValidEmail ? "Please enter a valid email address" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} position="relative">
                  <TextField
                    sx={{ mt: 4 }}
                    className="loginInput"
                    id="password-input"
                    type={showPass ? "text" : "password"}
                    fullWidth
                    autoCapitalize="none"
                    value={password}
                    variant="standard"
                    label={`Password`}
                    onChange={handleChangePassword}
                    disabled={isDisabled}
                    onFocus={() => setPswFocus(true)}
                    onBlur={() => setPswFocus(false)}
                    InputLabelProps={{
                      shrink: password.length > 0 ? true : pswFocus,
                      sx: { ml: 0.25 },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <Box
                            className="showPassIcon"
                            onClick={() => setShowPass(!showPass)}
                          >
                            {showPass ? (
                              <VisibilityOffIcon sx={{ fontSize: 20 }} />
                            ) : (
                              <VisibilityIcon sx={{ fontSize: 20 }} />
                            )}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* 
                  {!isLoginCorrect ? (
                    <Box className="handleWrongLogin">
                      Invalid Username or Password
                    </Box>
                  ) : (
                    <></>
                  )} */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: {
                      xs: 5,
                      md: 10,
                    },
                    mb: {
                      xs: 5,
                      md: 10,
                    },
                  }}
                >
                  <Box
                    className="center"
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <LoadingButton
                      type="submit"
                      color="steelBlue"
                      className="standardButton"
                      variant="contained"
                      loading={loadingBtn}
                    >
                      LOG IN
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
            {/* REMOVE FOR PRODUCTION - DOWNLOAD APP LINK
            <Link
              sx={{ m: "auto", display: "flex" }}
              component="button"
              variant="body2"
              onClick={openApkNewTab}
            >
              Download mobile app
            </Link> */}
            <Box textAlign="center"></Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleModalClose}
        className="loginModal"
        PaperProps={{
          style: {
            backgroundColor: "#fdeded",
            padding: "1%",
          },
        }}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Dialog>
    </ThemeProvider>
  );
};

export default Login;
