import { Box } from "@air/react-drag-to-select";
import { IPlant, IUser } from "../common-interfaces/interfaces";
import { IQuery, IComponent, EHttpBodyType, EHttpLanguageType } from "../common-interfaces/interfaces";

export interface IUniqueClient {
  fullname: string;
  email: string;
}
export interface IInstallationRow {
  location: string;
  plant: IPlant;
  installationName?: string;
  clients: string[];
  creationDate: string;
  lastEdit: string;
}
export interface IDragAndSelectItem {
  id: string;
  x?: number;
  y?: number;
  current: Box;
}

export interface ILoggedUser extends IUser {
  issued: number;
}
export interface IShortCut {
  key: string;
  localStorageKey: string;
  value: any;
}
export interface IAutocompleteOPT {
  value: any;
  label: string;
  family?: string;
}

export enum EQueryTypes {
  enum = "enum",
  boolean = "boolean",
  number = "number",
  string = "string",
  object = "object",
}

/****/
const emptyBodyRow: IBodyRow = {
  hasParam: false,
  key: 'NEW',
  value: ''
}

export interface IBodyRow {
  hasParam: boolean,
  key: string,
  value: string
  query?: IQuery,
}

export interface ICompoentField {
  name: keyof IComponent;
  type: string;
  disabled?: boolean;
  componentType?: string
}

export interface IQueryField {
  name: keyof IQuery;
  type: string;
  mandatory: boolean;
}

export interface IRequestBody {
  mode: EHttpBodyType,
  raw: string,
  options: {
    raw: {
      lang: EHttpLanguageType
    }
  }
}

export interface IUniquesDuration {
  durations: number[],
  selectedDurationIndex: number
}