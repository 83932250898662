import { useRecoilState } from "recoil";
import { useOnGet } from "./useOnGet";
import { ERequestSubtype, IComponent, IDashboard, IService } from "../common-interfaces/interfaces";
import { deepCopy } from "../helpers/utils";
import { uneditableDashboardAtom, uneditableServiceAtom } from "../recoil/Atom";
import { allComponentsAtom } from "../recoil/atoms";

export default function useOnCopy() {
  const [, setAllComponents] = useRecoilState<IComponent[]>(allComponentsAtom);
  const [, setAllServices] = useRecoilState<IService[]>(uneditableServiceAtom);
  const [, setUneditableDash] = useRecoilState<IDashboard[]>(uneditableDashboardAtom)

  const GET = useOnGet()

  const handleCopyComp = async (comp: IComponent) => {
    let tmpComp: IComponent = await deepCopy(comp);
    tmpComp._id = GET.id();
    tmpComp.brand = "custom";
    tmpComp.masters = [];
    tmpComp.slaves = [];
    delete tmpComp.origin;
    tmpComp.editable = false;
    tmpComp.services = await Promise.all(tmpComp.services ? tmpComp.services.map(s => handleCopyServ(s)) ?? [] : []);
    return tmpComp;
  };
  const handleCopyServ = async (s: IService) => {
    let tmpService = await deepCopy(s);
    tmpService._id = GET.id();
    tmpService.brand = 'custom';
    tmpService.editable = false;
    tmpService.host = '';
    delete tmpService.origin;
    return tmpService;
  };
  const handleCopyDash = async (dashboard: IDashboard) => {
    dashboard.origin = dashboard._id
    dashboard.brand = 'custom'
    dashboard.editable = false
    dashboard._id = GET.id()
    dashboard.components = await Promise.all(dashboard?.components.map(d => handleCopyComp(d)) ?? [])
    return dashboard
  }

  const handleAddCustom = async (copy: any, type: ERequestSubtype) => {
    switch (type) {
      case ERequestSubtype.COMPONENT:
        setAllComponents(prev => [...prev, copy])
        break;
      case ERequestSubtype.SERVICE:
        setAllServices(prev => [...prev, copy])
        break;
      case ERequestSubtype.DASHBOARD:
        setUneditableDash(prev => [...prev, copy])
        break;
      default:
        break;
    }
  };

  const handleCopy = async (original: IDashboard | IComponent | IService, type: ERequestSubtype, isSharing: boolean) => {
    let base = await deepCopy(original);
    const user = await GET.user()

    switch (type) {
      case ERequestSubtype.COMPONENT:
        base = await handleCopyComp(original as IComponent);

        break;
      case ERequestSubtype.SERVICE:
        base = await handleCopyServ(original as IService);
        break;
      case ERequestSubtype.DASHBOARD:
        base = await handleCopyDash(original as IDashboard);
        break;
      default:
        break;
    }
    if (!isSharing) {
      base.creator = [user];
    } else {
      base.description = 'SHARED'
    }
    base.disabled = isSharing
    return base
  }

  return { handleCopy, handleAddCustom, handleCopyDash }
}
