import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
} from "../../../../common-interfaces/interfaces";
import {
  allComponentsAtom,
  selectedComponentIDsAtom,
  selectedInstallationAtom,
} from "../../../../recoil/atoms";
import Save from "@mui/icons-material/Save";
import Draggable from "react-draggable";
import { useOnGet } from "../../../../hooks/useOnGet";
import { Print, deepCopy } from "../../../../helpers/utils";
import { useApiWrapper } from "../../../../hooks/useApiWrapper";
import { ControlWhiteDialog } from "../../../../theme";
import { enqueueSnackbar } from "notistack";

export default function HandleSave(props: any) {
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [allComponents, setAllComponents] = useRecoilState<IComponent[]>(allComponentsAtom);
  
  const apiWrapper = useApiWrapper();

  const [open, setOpen] = useState(false);
  const GET = useOnGet();
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "s" && event.ctrlKey === true) {
      handleDialogOpen();
    }
  };
  const copyComponent = async (component: any) => {
    if (!component) return;
    //component creation
    const url = `api/component`;
    Print(["sending this component to post ", component]);
    let newComponent = await apiWrapper.postWithAuth(url, component);
    Print(["returned this component from post", newComponent]);
    return !newComponent ? undefined : newComponent;
  };

  const handleSaveConfirm = async () => {
    handleDialogClose();
    if (selectedComponentIDs.length > 1 || !selectedInstallation) {
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    }
    let dash: IDashboard = await deepCopy(GET.dash());
    let tmpComp: IComponent = await deepCopy(
      dash.components.find((x) => x._id === selectedComponentIDs[0])
    );
    if (!tmpComp) {
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    }
    tmpComp = handleSaveComp(tmpComp);
    if (tmpComp?.components) {
      tmpComp.components = tmpComp.components.map((c) => {
        c = handleSaveComp(c);
        c.top = c.top - tmpComp.top;
        c.left = c.left - tmpComp.left;
        return c;
      });
    }
    await copyComponent(tmpComp);
    if (allComponents) setAllComponents([...allComponents, tmpComp]);
  };

  const handleSaveComp = (comp: IComponent) => {
    comp._id = GET.id();
    comp.brand = "custom";
    comp.masters = [];
    comp.slaves = [];
    delete comp.origin;
    comp.creator = [selectedInstallation.installer];
    comp.editable = false;
    comp = handleSaveService(comp);
    return comp;
  };
  const handleSaveService = (comp: IComponent) => {
    if (comp.services) {
      comp.services = comp.services.map((s) => {
        s._id = GET.id();
        s.brand = 'custom'
        s.editable = false;
        delete s.origin;
        return s;
      });
    }
    return comp;
  };
  return (
    <>
      <Button
        onClick={() => handleDialogOpen()}
        sx={{ color: "white", border: "1px solid #666" }}
        color="steelBlue"
        variant="contained"
        className="primaryButton"
      >
        <Save></Save>
      </Button>
      {/* DIALOG FOR DELETE */}
      <ControlWhiteDialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        onKeyDown={(e) => {
          // catch enter key for
          Print([e.key]);
          if (e.key === "Enter") {
            handleSaveConfirm();
          }
        }}
      >
        <DialogTitle>Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save selected component?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {selectedComponentIDs.length > 0 ? (
            <Button onClick={handleSaveConfirm}>Save</Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
