import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ControlSwitch } from "../../../../theme";
import PhoneComponentLabel from "./PhoneComponentLabel";
import { useOnIcons } from "../../../../hooks/useOnIcons";

export default function SwitchComponent(props: any) {
  const [comp, setComp] = useState(props.component);
  const [status, setStatus] = useState(true);
  const GET_ICONS = useOnIcons();

  useEffect(() => {
    setComp(props.component);
  }, [props.component]);

  let zoom = 0.3 + Math.min(comp.height, comp.width) / 2;

  return (
    <Box
      className="center col"
      sx={{ height: "100%" }}
      title={props?.component?.name?.toUpperCase()}
    >
      <PhoneComponentLabel component={props.component}></PhoneComponentLabel>
      <Box sx={{ zoom: zoom, opacity: "1 !important" }} position={"relative"}>
        <Box
          position={"absolute"}
          left={"20%"}
          width={"25%"}
          height={"100%"}
          color={comp.color}
          zIndex={status ? 1 : 0}
          className={"center"}
        >
          {props.component.icon && GET_ICONS.images(props.component.icon, 12)}
        </Box>
        <ControlSwitch
          checked={status}
          onClick={() => setStatus(!status)}
          sx={[
            {
              "& .MuiSwitch-root": {
                width: "100%",
              },
              "& .Mui-checked > .MuiSwitch-thumb": {
                backgroundColor: `${comp.color} !important`,
                opacity: 1,
              },
              "& .Mui-checked + .MuiSwitch-track": {
                backgroundColor: `${comp.backgroundColor} !important`,
                opacity: .65
              },
            }
          ]}
          className="smallComponentSwitch"
        />
      </Box>
    </Box>
  );
}
