import { Button, Grid, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IService } from '../../../../common-interfaces/interfaces'
import { isBodyAdvancedModeOpenAtom, selectedServiceAtom } from '../../../../recoil/Atom'
import BodyRaw from './BodyRaw'
import { emptyBody } from '../../../../config/config';
import { IRequestBody } from '../../../../local-interfaces/local-interfaces';

const BodyCreation: FC<{
  xs: number,
  dispatch: (updatedBody: IRequestBody) => Promise<void>
}> = ({ xs, dispatch }) => {
  const [selectedService] = useRecoilState<IService>(selectedServiceAtom)

  const [body, setBody] = useState<IRequestBody>(emptyBody)
  const [advanced, setAdvanced] = useRecoilState<boolean>(isBodyAdvancedModeOpenAtom)

  useEffect(() => {
    if (selectedService.restService?.requestSet?.body) {
      setBody({ ...selectedService.restService.requestSet.body })
    }
  }, [selectedService.restService, advanced]);

  const dispatchChange = async (body: IRequestBody) => {
    await dispatch(body)
    setBody(body)
  }


  return (
    <Grid container item direction="column" xs={xs} flexWrap={'nowrap'} overflow={'auto'} spacing={1}>
      <Grid item xs={1}>
        <Button endIcon={advanced ? <ExpandLessIcon /> : <ExpandMoreIcon />} variant='contained' sx={{ height: '20px', my: 2 }} onClick={() => setAdvanced(!advanced)} color="secondary">
          <Typography
            className='no-underline-title'
            textAlign={'start'}
            color={'primary'}
            pl={1}
          >
            Body:
          </Typography></Button>
      </Grid>
      {/* REMOVED FOR THE MOMENT, we use only RAW JSON */}
      {/* <Grid container item xs={2} spacing={1} justifyContent={'center'} alignItems={'flex-start'}>

        {advanced && <BodyOpts bodyProps={body}  dispatch={dispatchChange} />}
      </Grid> */}
      <Grid item container xs={11} sx={{ flexWrap: 'nowrap', width: '100%' }} direction={'column'} >
        {advanced && < BodyRaw bodyProps={body} dispatch={dispatchChange} />}
      </Grid>
    </Grid >

  )
}
export default BodyCreation