import { useRecoilState } from "recoil"
import { currentSelectedComponentAtom, selectedServiceAtom } from "../recoil/Atom"
import { deepCopy } from "../helpers/utils"
import { useOnGet } from "./useOnGet"
import { IComponent, IService } from "../common-interfaces/interfaces"
import { useOnSet } from "./useOnSet"

const useService = () => {

    const GET = useOnGet()
    const SET = useOnSet()

    const [selectedComponent, setSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
    const [selectedService, setSelectedService] = useRecoilState(selectedServiceAtom)

    const makeCopyFrom = async (source: IService): Promise<IService> => {
        return {
            ...await deepCopy(source),
            _id: GET.id(),
            editable: true,
            duration: 0, // leggere dove stiamo aggiungendo il servizio
            origin: source._id,
            brand: 'custom'
        }
    }

    const updateServicesWith = async (updatedService: any, type: 'restService' | 'oscService') => {
        const tmpComp: IComponent = await deepCopy(selectedComponent)
        if (!tmpComp.services)
            return
        const service = tmpComp.services.find(s => s._id === selectedService._id)
        if (!service)
            return
        service[type] = updatedService
        setSelectedService(service)
        await SET.comp(tmpComp)
        setSelectedComponent(tmpComp)
    }

    return {
        makeCopyFrom,
        updateServicesWith
    }
}

export default useService