import { Grid } from "@mui/material";
import { FC } from "react";
import { IInstallationRow } from "../../../local-interfaces/local-interfaces";
import InstallationCard from "./InstallationCard";

const InstallationsGrid: FC<{ filteredRows: IInstallationRow[] }> = ({ filteredRows }) => {
  return (
    <Grid
      width={"100%"}
      height={"100%"}
      overflow="auto"
      boxSizing={"border-box"}
      padding={{ xs: "0", sm: "1%" }}
      paddingTop="0 !important"
      container
      justifyContent={{ xs: "center", sm: "flex-start" }}
    >
      {filteredRows.map((row: IInstallationRow) => (
        <Grid
          item
          xs={10}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          key={row.plant.id}
          height="320px"
          color="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          children={<InstallationCard installationRow={row} />}
        />
      ))}
    </Grid>
  );
};

export default InstallationsGrid;
