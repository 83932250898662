import Button from "@mui/material/Button";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import Wizard from "./Wizard";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ControlWhiteDialog } from "../../../../theme";
import { currentSelectedComponentAtom } from "../../../../recoil/Atom";
import { newServiceAtom, restServiceAtom } from "../../../../recoil/CreateServiceAtoms";

const CreateService = () => {
  const [currentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
  const resetAtom = useResetRecoilState(newServiceAtom);
  const resetREST = useResetRecoilState(restServiceAtom);

  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    resetAtom();
    resetREST();
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleDialogOpen} className="addDashboardButton">
        <Typography mr={0.5} fontSize={13}>Service</Typography>
        <AddCircleOutlineIcon />
      </Button>
      {/* DIALOG FOR CREATE */}
      <ControlWhiteDialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Wizard comp={currentSelectedComponent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

export default CreateService