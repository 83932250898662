import { Box, Typography } from "@mui/material";
import PhoneComponentLabel from "./PhoneComponentLabel";

export default function LabelComponent(props: any) {
  const fontSize = Math.min(props.component.height, props.component.width) * 10;
  return (
    <Box
      title={props?.component?.name?.toUpperCase()}
      className="center"
      sx={{
        height: "70%",
        width: "95%",
        border: `var(--primary_border)`,
        m: "2.5%",
        boxSizing: "border-box",
        backgroundColor: props.component.backgroundColor,
        borderRadius: 1,
      }}
    >
      <Typography
        className="text-overflow"
        sx={{
          fontSize: fontSize,
          color: props.component.color,
          maxWidth: "95%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        }}
      >
        {props.component.name.toUpperCase()}
      </Typography>
    </Box>
  );
}
