import { Grid, IconButton, Typography } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import { useRecoilState } from "recoil";
import { EComponentType, IComponent } from "../../../common-interfaces/interfaces";
import { allComponentsAtom } from "../../../recoil/atoms";
import { deepCopy } from "../../../helpers/utils";
import { useOnGet } from "../../../hooks/useOnGet";
import { usePosition } from "../../../hooks/usePosition";
import { useOnSet } from "../../../hooks/useOnSet";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../workspace/dnd/utils/dndUtils";
import { movingComponentIDAtom } from "../../../recoil/workspace";
import { RxSlider } from "react-icons/rx";
import { CgToggleSquareOff } from "react-icons/cg";
import { AiOutlineSliders } from "react-icons/ai";
import { PiSubtitles } from "react-icons/pi";
import { BsBox } from "react-icons/bs";
import { enqueueSnackbar } from "notistack";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";

const containerStyle = { cursor: "grabbing" }
const iconsStyle = { fontSize: 29 }
const iconButtonStyle = { padding: 0 }

const DndComponent = (props: any) => {
  const GET = useOnGet();
  const SET = useOnSet();
  const position = usePosition();
  const apiWrapper = useApiWrapper();

  const [allComponents, setAllComponents] = useRecoilState<IComponent[]>(allComponentsAtom);
  const [_, setDraggingID] = useRecoilState(movingComponentIDAtom);

  const component: IComponent = props.component;

  const handleComponentDoubleClick = async (ev: any) => {
    let selectedDashboard = await deepCopy(GET.dash());
    let tmpComp: IComponent = await deepCopy(component);
    if (!tmpComp) return console.error("was not possibile to move comp");
    let coord = position.position(tmpComp.height, tmpComp.width);
    tmpComp.origin = tmpComp._id;
    tmpComp._id = GET.id();
    tmpComp.top = coord.top;
    tmpComp.left = coord.left;
    tmpComp.editable = true;
    if (tmpComp.origin === tmpComp._id) {
      return enqueueSnackbar("was not possible to create component", {
        variant: "error",
      });
    }
    if (tmpComp.services) {
      tmpComp.services = tmpComp.services.map(service => {
        service.origin = service._id;
        service._id = GET.id();
        service.editable = true;
        return service;
      });
    }
    selectedDashboard.components.push(tmpComp);
    SET.dash(selectedDashboard);
  };

  const deleteComponents = async (compIDs: string[]) => {
    if (!compIDs) return;
    await Promise.all(
      compIDs.map(async id => {
        const url = `api/component/${id}`;
        return apiWrapper.deleteWithAuth(url, {});
      })
    );
  };

  const handleDeleteCustom = async () => {
    if (!allComponents) return;
    let tmpComps = allComponents.filter(c => c._id !== component._id);
    setAllComponents(tmpComps);
    await deleteComponents([component._id]);
  };

  const DndIcon = () => {
    switch (component.type) {
      case EComponentType.Button:
        return <SlideshowOutlinedIcon sx={iconsStyle} />;
      case EComponentType.Switch:
        return <ToggleOnIcon sx={iconsStyle} />
      case EComponentType.Slider:
        return <AiOutlineSliders fontSize={29} />
      case EComponentType.SliderLinear:
        return <RxSlider fontSize={29} />
      case EComponentType.Label:
        return <PiSubtitles fontSize={29} />
      case EComponentType.Container:
        return <BsBox fontSize={29} />
      case EComponentType.ButtonsArray:
        return <CgToggleSquareOff fontSize={29} />
      default:
        return <BrowserNotSupportedIcon sx={iconsStyle} />
    }
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.PREVIEW,
    item: { ID: component._id },
    previewOptions: {
      anchorX: 0.5,
      anchorY: 0.5,
    },
    canDrag: (item) => {
      if (item) {
        setDraggingID(component._id);
      }
      return true;
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => { },
  }));

  return (
    <>
      <Grid ref={drag} key={component._id} width={"100%"} container justifyContent="space-between" alignItems="center" borderBottom="1px solid" boxSizing="border-box" padding="5px 15px" marginBottom="5px" sx={containerStyle} onDoubleClick={handleComponentDoubleClick}>
        <Grid item xs={9} container>
          {component.brand === "custom" && (
            <Grid item container xs={2}>
              <IconButton onClick={handleDeleteCustom} color="error" sx={iconButtonStyle}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item container xs={10}>
            <TooltipOverflow
              tooltipChildren={
                <Typography
                  color="var(--light_grey-color)"
                  fontSize="14px"
                  textTransform="uppercase"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  children={component.name}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid ref={preview} item xs={3} boxSizing={'border-box'} padding="5px" border="1px solid #6b6868" borderRadius={1} zIndex={2} color="var(--light_grey-color)" bgcolor="var(--primary_background-color)" className="center">
          <DndIcon />
        </Grid>
      </Grid>
    </>
  );
}

export default DndComponent