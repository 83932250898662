import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { requestsFromRemoteAtom } from "../utils/requestAtoms";
import { URLS } from "../../../helpers/URLS";
import { useApiWrapper } from "../../../hooks/useApiWrapper";

const POLLING_INTERVAL = 30000;

export default function useRequestNotification() {
  const [, setRequestFromRemote] = useRecoilState(requestsFromRemoteAtom);
  const { getWithAuth } = useApiWrapper();
  const previousRequestsRef = useRef(null);

  const getRequests = async () => {
    try {
      const res = await getWithAuth(URLS.request, {});
      if (res.data) {
        const previousRequests = previousRequestsRef.current;
        const isDifferent = JSON.stringify(previousRequests) !== JSON.stringify(res.data);
        if (isDifferent) {
          setRequestFromRemote(res.data)
          previousRequestsRef.current = res.data;
        }
      }
    } catch (e) {
      console.error('Error fetching requests:', e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(getRequests, POLLING_INTERVAL);
    getRequests();
    return () => clearInterval(intervalId);
  }, []);
  return null;
}
