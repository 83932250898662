import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { IQuery } from '../../../../common-interfaces/interfaces'
import { MenuItem, Select } from '@mui/material'

const QueryValueSelect: FC<{
  query: IQuery,
  cb: Dispatch<SetStateAction<IQuery>>
}> = ({ query, cb }) => {

  const [tmpQuery, setTmpQuery] = useState<IQuery>(query)

  useEffect(() => {
    setTmpQuery(query)
  }, [query]);

  if (!tmpQuery) return <></>

  const handleValueChange = async (value: any) => {
    cb({ ...query, value: value })
  }

  return (
    <>
      <Select
        variant="standard"
        value={tmpQuery?.value}
        onChange={(e) => handleValueChange(e.target.value)}
        disabled={!query?.options || query.options.length === 0}
        sx={{
          width: '95%',
          fill: 'white',
          textAlign: 'center',
          color: '#ccc !important',
          letterSpacing: 1,
          mx: 'auto',
          textTransform: 'uppercase',
          '& .MuiSelect-icon': {
            fill: '#ccc'
          }
        }
        }
      >
        {tmpQuery.options && tmpQuery.options.map((opt, index) =>
          <MenuItem
            key={`${opt}_${index}`}
            value={opt}
            selected={opt === tmpQuery.value}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#222',
                color: 'var(--secondary-color)'
              },
              '&.MuiMenuItem-root:hover ': {
                color: '#ccc !important',
                backgroundColor: '#444',
              },
            }}
          >
            {opt}
          </MenuItem>
        )}
      </Select>
    </>
  )
}
export default QueryValueSelect