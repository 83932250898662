import { atom } from "recoil";
import { IComponent, IDashboard, IQuery, IRestService, IService } from "../common-interfaces/interfaces";
import { IUniquesDuration } from "../local-interfaces/local-interfaces";


export const uniquesDurationAtom = atom<IUniquesDuration>({
  key: "uniquesDuration",
  default: { durations: [0], selectedDurationIndex: 0 },
  effects: [
    ({ setSelf, onSet }) => {
      onSet(newValue => {
        const durations = newValue.durations
        // check if valid value before sort
        if (newValue !== undefined && Array.isArray(durations)) {
          // Update the atom with sorted values 
          const arrangedValues = [...durations].sort((a, b) => a - b);
          setSelf({ durations: arrangedValues, selectedDurationIndex: newValue.selectedDurationIndex });
        }
      });
    }
  ]
});

export const currentSelectedComponentAtom = atom<IComponent>({
  key: "curentSelectedComponent",
  default: undefined
})
export const selectedServiceAtom = atom<IService>({
  key: "selectedServiceAtom",
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        //console.log('setting restServiceATOM from', oldValue, ' to -->', newValue)
      });
    }
  ]
});
export const uneditableServiceAtom = atom<IService[]>({
  key: 'uneditableService',
  default: []
})
export const uneditableDashboardAtom = atom<IDashboard[]>({
  key: 'uneditableDashboard',
  default: []
})

export const serviceParamsAtom = atom<IQuery[]>({
  key: 'serviceParams',
  default: []
})

export const restServiceAtom = atom<IRestService>({
  key: 'restService',
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        //console.log('setting restServiceATOM from', oldValue, ' to -->', newValue)
      });
    }
  ]
})

export const isBodyAdvancedModeOpenAtom = atom<boolean>({
  key: 'isBodyAdvancedModeOpen',
  default: false
})