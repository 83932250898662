import { Box, Button, Typography } from "@mui/material";
import { useDrag } from "react-dnd";
import { useRecoilState } from "recoil";
import {
  GRIDCOL_Atom,
  GRIDROW_Atom,
  cellDimensionAtom,
  containersArrayAtom,
  movingComponentIDAtom,
  resizingCompAtom,
  selectedContainerAtom,
} from "../../../../recoil/workspace";
import { HANDLER_HEIGHT, ItemTypes } from "./utils/dndUtils";
import { CSSProperties, useEffect, useState } from "react";
import Loading from "react-loading";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { EComponentType, IComponent } from "../../../../common-interfaces/interfaces";
import {
  selectedComponentIDsAtom,
  selectedDashboardAtom,
} from "../../../../recoil/atoms";
import { ResizableBox } from "react-resizable";
import "../../../../styles/Component.css";
import { useOnDND } from "../../../../hooks/useOnDND";
import { Print, deepCopy } from "../../../../helpers/utils";
import { useOnSet } from "../../../../hooks/useOnSet";
import HandleComponentType from "./common/HandleComponentType";
import { useOnGet } from "../../../../hooks/useOnGet";

export default function Component(props: any) {
  const [cellDimension] = useRecoilState(cellDimensionAtom);
  const [over, setIsOver] = useState(false);
  const [comp, setComp] = useState<IComponent | undefined>();
  const [w, setW] = useState<number>();
  const [h, setH] = useState<number>();
  const [_, setDraggingID] = useRecoilState(movingComponentIDAtom);
  const [selectedComponentIDs, setSelectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [selectedContainer, setSelectedContainer] = useRecoilState(selectedContainerAtom);
  const [__, setResizingComp] = useRecoilState(resizingCompAtom);
  const [C, setGRIDCOL] = useRecoilState(GRIDCOL_Atom);
  const [R, setGRIDROW] = useRecoilState(GRIDROW_Atom);
  const [containersArray, setContainersArray] = useRecoilState<IComponent[]>(containersArrayAtom);
  const [maxH, setMaxH] = useState<number>();
  const [maxW, setMaxW] = useState<number>();
  const GET = useOnGet();
  const SET = useOnSet();
  const DND = useOnDND();

  useEffect(() => {
    const comp = props.comp;
    if (!comp) return;
    setW(((comp.width / 100) * 98.4) * cellDimension.width);
    setH(comp.height * cellDimension.height); // TODO INDAGARE
    setComp(comp);
  }, [props.comp, cellDimension]);

  useEffect(() => {
    if (
      !comp ||
      (selectedContainer &&
        (!selectedContainer?.components || // ts
          selectedContainer?.components?.length === 0)) // DO NOT REMOVE. FIX TO GET MAX HEIGHT LOOP ON DOUBLE CLICK ON EMPTY CONTAINER
    ) {
      return;
    }
    let MAX_H = DND.getComponentMaxHeight(comp);
    let MAX_W = DND.getComponentMaxWidth(comp);
    setMaxH(Math.round(MAX_H * cellDimension.height));
    setMaxW(Math.round(MAX_W * cellDimension.width));
  }, [comp, selectedDashboard, selectedContainer]);

  useEffect(() => {
    setIsOver(props.isOver);
  }, [props.isOver]);

  const [{ isDraggin }, drag, preview] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: { ID: `${props.comp._id}` },
    canDrag: (item) => {
      if (item) {
        setDraggingID(props.comp._id);
      }
      return true;
    },
    collect: (monitor) => ({
      isDraggin: !!monitor.isDragging(),
      canDrag: monitor,
    }),
    connectDropTarget: (connect: any) => {
      connect.dropTarget();
    },
    end: () => { },
  }));

  const handleClickOnComponent = (ev: any) => {
    ev.stopPropagation();
    Print([
      "%c > Selected component: ",
      "background: black; color: white",
      props.comp,
    ]);
    if (props.comp.type === EComponentType.None) return;
    //se il click avviene con ctrl o cmnd premuto aggiungo o rimuovo dalla lista dei selezionati
    if (ev.ctrlKey || ev.metaKey) {
      if (selectedComponentIDs.includes(props.comp._id)) {
        return setSelectedComponentIDs(
          selectedComponentIDs.filter((x) => x !== props.comp._id)
        );
      }
      setSelectedComponentIDs([...selectedComponentIDs, props.comp._id]);
    } else {
      //altrimenti seleziono soltanto quel componente
      setSelectedComponentIDs([props.comp._id]);
    }
  };

  const handleStyle: CSSProperties = {
    backgroundColor: "#ddd",
    width: "0.75rem",
    maxWidth: '1rem',
    borderRight: '1px solid black',
    height: HANDLER_HEIGHT,
    display: "flex",
    cursor: "grab",
    borderRadius: 0,
    position: "absolute",
    padding: 0,
    top: 0,
    left: 0,
    zIndex: 2,
    alignItems: "center",
  };

  const onResizeStop = async (event: any, { size }: any) => {
    event.stopPropagation();
    if (!comp) {
      return console.error("no component");
    }
    setW(size.width);
    setH(size.height);
    let tmpComp: IComponent = DND.getWorkspaceComponent(comp._id);
    if (!tmpComp) {
      return console.error("no component");
    }
    const W = Math.round(size.width / cellDimension.width);
    const H = Math.round(size.height / cellDimension.height);
    tmpComp.width = W;
    tmpComp.height = H;
    if (selectedContainer) {
      tmpComp = handleResizeInsideContainer(tmpComp) ?? tmpComp;
    }
    SET.comp(tmpComp);
    SET.syncAllComp(tmpComp);
    setResizingComp("");
  };

  const handleResizeInsideContainer = (modifyComp: IComponent) => {
    const tmpContainer: IComponent = JSON.parse(
      JSON.stringify(selectedContainer)
    );
    if (!tmpContainer?.components) {
      return console.error("no components");
    }
    const compInside = tmpContainer.components.find(
      (c) => c._id === modifyComp?._id
    );
    if (!compInside) {
      return console.error("no inside");
    }
    const modifiedComp = JSON.parse(JSON.stringify(modifyComp));
    DND.getBusyCells(modifiedComp);
    const updatedComponents = tmpContainer.components.map((c) =>
      c._id === modifiedComp._id ? modifiedComp : c
    );
    tmpContainer.components = updatedComponents;
    setSelectedContainer(tmpContainer);
    return tmpContainer;
  };

  const onResize = async (event: any, { size }: any) => {
    event.stopPropagation();
    let tmpComp: IComponent = JSON.parse(JSON.stringify(comp));

    const W = Math.floor(size.width / cellDimension.width);
    const H = Math.floor(size.height / cellDimension.height);
    tmpComp.width = W - 2;
    tmpComp.height = H - 2;
    if (selectedContainer) {
      tmpComp = handleResizeInsideContainer(tmpComp) ?? tmpComp;
    } else {
      DND.getBusyCells(tmpComp);
    }
    setResizingComp(tmpComp._id);
    setW(size.width);
    setH(size.height);
  };

  const handleDoubleClickOnComponent = async () => {
    if (comp?.type !== EComponentType.Container) {
      return;
    }
    if (!containersArray?.find((c) => c._id === comp?._id)) {
      let tempContainer = await deepCopy([...(containersArray ?? [])]);
      tempContainer = tempContainer.push(comp);
      setContainersArray([...(containersArray ?? []), comp]);
    }
    handleNavigateToContainer(comp);
  };
  const handleNavigateToContainer = (comp: IComponent) => {
    if (!comp) {
      return;
    }
    setGRIDCOL(comp.width);
    setGRIDROW(comp.height);
    setSelectedContainer(comp);
  };

  const noDragging = {
    opacity: "1",
    transition: "all 0.25s",
  };
  const dragging = {
    opacity: "1",
  };
  const handleDraggin = isDraggin ? dragging : noDragging;

  return (
    <Box
      position={"relative"}
      sx={{
        zIndex: isDraggin ? -1 : 1,
        width: "100%",
      }}
      style={handleDraggin}
    >
      {!h || !w || !maxH || !maxW || !comp ? (
        <Loading></Loading>
      ) : (
        <ResizableBox
          height={h}
          width={w}
          onResize={onResize}
          onResizeStop={onResizeStop}
          minConstraints={[cellDimension.width, cellDimension.height]}
          maxConstraints={[maxW, maxH]}
          className="box"
        >
          <>
            <Box
              height={`${HANDLER_HEIGHT}px`}
              width={w}
              sx={{
                display: "flex",
                alignItems: "center",
                background: selectedComponentIDs.includes(props.comp._id) ? " var(--secondary-color)"
                  : selectedComponentIDs.some((x) => {
                    const comp = GET.comp(x);
                    return comp?.slaves?.includes(props.comp._id);
                  })
                    ? "#756b5a"
                    : comp.color,
              }}
            >
              <Button style={handleStyle} ref={drag}>
                <DragIndicatorIcon
                  sx={{ fontSize: 14, p: 0 }}
                ></DragIndicatorIcon>
              </Button>
              <Typography fontSize={11} m={"auto"} color={comp.backgroundColor}>
                {comp.name?.toUpperCase()}
              </Typography>
            </Box>
            <Box
              ref={preview}
              onClick={handleClickOnComponent}
              onDoubleClick={handleDoubleClickOnComponent}
              sx={{
                width: w,
                height: h - HANDLER_HEIGHT,
                m: "1px",
                position: "absolute",
              }}
            >
              <HandleComponentType over={over} comp={comp}></HandleComponentType>
            </Box>
          </>
        </ResizableBox>
      )
      }
    </Box >
  );
}
