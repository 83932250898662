import { useState, useCallback } from 'react'

export default function usePopover() {

  const [anchor, setAnchor] = useState<any>(null)
  const isOpen = Boolean(anchor)

  const open = useCallback((target: any) => setAnchor(target), [])
  const close = useCallback(() => setAnchor(null), [])

  return { isOpen, anchor, open, close }
}