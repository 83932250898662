import { Box, Button } from "@mui/material";
import PhoneComponentLabel from "./PhoneComponentLabel";
import { useOnIcons } from "../../../../hooks/useOnIcons";

export default function ButtonComponent(props: any) {
  const GET_ICONS = useOnIcons();

  return (
    <Box
      className="center"
      sx={{ width: "95%", height: "95%", flexDirection: "column" }}
      title={props.component.name.toUpperCase()}
      >
      <PhoneComponentLabel component={props.component}></PhoneComponentLabel>
      <Button
        variant="contained"
        size="small"
        sx={[
          { height: "70%", minWidth: "100%", textAlign: "center" },
          {
            "& .MuiButton-root": {
              color: props.component.color,
              border: `1px solid ${props.component.color}`,
              backgroundColor: props.component.backgroundColor,
              borderRadius: "5px",
              minWidth: 0,
            },
          },
        ]}
      >
        {props.component.icon && GET_ICONS.images(props.component.icon, 15)}
      </Button>
    </Box>
  );
}
