import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { IInstallation } from "../../../common-interfaces/interfaces";
import { deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";
import { IInstallationRow } from "../../../local-interfaces/local-interfaces";
import { isLoadingAtom, allPlantsFullAtom } from "../../../recoil/atoms";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import HandleDeleteInstallation from "../modifyInstallation/HandleDeleteInstallation";
import HandleEditInstallation from "../modifyInstallation/HandleEditInstallation";


const firstBoxStyle = { ":hover": { opacity: 0.8 } }
const titleBoxStyle = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, cursor: "pointer", ":hover": { color: "var(--secondary-color)" } }
const radius = 3;
const buttonBoxStyle = { borderBottomLeftRadius: radius + 6, borderBottomRightRadius: radius + 6 }

const InstallationCard: FC<{ installationRow: IInstallationRow }> = ({ installationRow }) => {
  const apiWrapper = useApiWrapper();
  const navigate = useNavigate();
  const SET = useOnSet();

  const [_, setIsLoading] = useRecoilState(isLoadingAtom);
  const [allPlantsFull] = useRecoilState(allPlantsFullAtom);

  const handleForward = async () => {
    setIsLoading(true);
    const id = installationRow.plant.id
    const plantFull = allPlantsFull?.find(pf => pf.plant.id === id);
    if (!plantFull)
      return setIsLoading(false);
    const url = `api/installation/plant2installation`;
    const newInstallation: IInstallation | undefined = (await apiWrapper.postWithAuth(url, plantFull)).data;
    if (!newInstallation)
      return setIsLoading(false);
    SET.installation(await deepCopy(newInstallation));
    navigate(`/installation/${newInstallation._id}`);
    setIsLoading(false);
  };

  return (
    <Box
      height={"95%"}
      width={"95%"}
      bgcolor="var(--secondary_background-color)"
      sx={firstBoxStyle}
      m="auto"
      className="center"
      borderRadius={radius}
      flexDirection={"column"}
    >
      {/* title */}
      <Box
        onClick={handleForward}
        borderRadius={radius}
        className={"center"}
        width="100%"
        height="25%"
        sx={titleBoxStyle}
        bgcolor={"var(--primary_background-color)"}
      >
        <TooltipOverflow
          tooltipChildren={
            <Typography
              padding={1}
              fontSize={20}
              fontWeight={600}
              letterSpacing={1}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textAlign={"center"}
              textTransform={"none"}
              children={installationRow.installationName}
            />
          }
        />
      </Box>
      {/* body */}
      <Box
        height={"75%"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
      >
        {/* clients */}
        <Box
          height={"calc(40% - 8px)"}
          boxSizing={"border-box"}
          paddingTop={1}
          paddingLeft={1}
          borderBottom={"1px solid var(--primary_background-color)"}
          overflow={"auto"}
        >
          {
            installationRow.clients.map((client, index) =>
              <TooltipOverflow
                key={index}
                tooltipChildren={
                  <Typography
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    key={client}
                    children={client}
                  />
                }
              />
            )
          }
        </Box >
        {/* info */}
        < Box
          width={"100%"}
          height={"calc(35% + 8px)"}
          boxSizing={"border-box"}
          paddingTop={1}
          paddingLeft={1}
          overflow={"auto"}
        >
          <TooltipOverflow
            tooltipTitle={installationRow.location}
            tooltipChildren={
              <Typography
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
              >
                Location : {installationRow.location}
              </Typography>
            }
          />
          <TooltipOverflow
            tooltipTitle={installationRow.lastEdit}
            tooltipChildren={
              <Typography
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
              >
                Last edited: {installationRow.lastEdit}
              </Typography>
            }
          />
          <TooltipOverflow
            tooltipTitle={installationRow.creationDate}
            tooltipChildren={
              <Typography
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
              >
                Created: {installationRow.creationDate}
              </Typography>
            }
          />
        </Box >
        {/* buttons */}
        < Box
          width={"100%"}
          height={"25%"}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
          boxSizing={"border-box"}
          padding={1}
          sx={buttonBoxStyle}
        >
          <Box
            width={"60px"}
            height={"40px"}
            bgcolor={"var(--primary_background-color)"}
            borderRadius={2}
            children={<HandleEditInstallation plantID={installationRow.plant.id} />}
          />
          <Box
            width={"60px"}
            height={"40px"}
            bgcolor={"var(--primary_background-color)"}
            borderRadius={2}
            children={<HandleDeleteInstallation installation={installationRow} />}
          />
        </Box >
      </Box >
    </Box >
  );
};

export default InstallationCard;
