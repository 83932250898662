import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IInstallation, IPlantFull } from "../../../common-interfaces/interfaces";
import { deepCopy } from "../../../helpers/utils";
import { IInstallationRow } from "../../../local-interfaces/local-interfaces";
import { allPlantsFullAtom } from "../../../recoil/atoms";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { ControlWhiteDialog } from "../../../theme";

const HandleDeleteInstallation: FC<{ installation: IInstallationRow }> = ({
  installation,
}) => {
  const [allPlantsFull, setAllPlantFull] = useRecoilState(allPlantsFullAtom);
  const [selectedInstallation, setSelectedInstallation] =
    useState<IInstallation>();
  const [open, setOpen] = useState(false);

  const apiWrapper = useApiWrapper();

  const getInstallation = async (PL: IPlantFull) => {
    setSelectedInstallation(undefined);
    const url = `api/installation/plant2installation`;
    const res = await apiWrapper.postWithAuth(url, PL);
    setSelectedInstallation(res.data);
  };

  const init = async () => {
    let plantFull = allPlantsFull.find(
      (pf) => pf.plant.id === installation.plant.id
    );
    if (plantFull) getInstallation(plantFull);
  };

  const handleDialogOpen = () => {
    setOpen(true);
    init();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const deleteInstallation = async () => {
    if (!selectedInstallation) return;
    const url = `api/installation/${selectedInstallation?._id}`;
    apiWrapper.deleteWithAuth(url, {});
    let tmpAllPlant: IPlantFull[] = await deepCopy(allPlantsFull);
    tmpAllPlant = tmpAllPlant.map((pf) => {
      if (pf.installationName === selectedInstallation.name) {
        delete pf.installationName;
      }
      return pf;
    });
    setAllPlantFull(tmpAllPlant);
    handleDialogClose();
  };

  return (
    <>
      <Tooltip
        title={"DELETE INSTALLATION"}
        PopperProps={{ sx: { pointerEvents: "none" } }}
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <Button
          onClick={handleDialogOpen}
          color="steelBlue"
          className="primaryButton"
          sx={{
            color: "white",
            padding: 0,
            minWidth: "100%",
            minHeight: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        >
          <DeleteForeverIcon fontSize="small"></DeleteForeverIcon>
        </Button>
      </Tooltip>
      <ControlWhiteDialog
        open={open}
        maxWidth={"sm"}
        onClose={handleDialogClose}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText mt={2}>
            Please be aware that you are about to delete{" "}
            {/* <Typography display={'inline'}  sx={{textDecoration: 'underline'}} fontWeight={600}>{selectedPlantFull?.plant.name} Plant</Typography>
            , its relative installation {" "} */}
            <Typography
              display={"inline"}
              sx={{ textDecoration: "underline" }}
              fontWeight={600}
            >
              {installation.installationName} Installation
            </Typography>
            , its relative{" "}
            <Typography
              display={"inline"}
              sx={{ textDecoration: "underline" }}
              fontWeight={600}
            >
              {" "}
              components
            </Typography>{" "}
            and all related{" "}
            <Typography
              display={"inline"}
              sx={{ textDecoration: "underline" }}
              fontWeight={600}
            >
              {" "}
              services
            </Typography>
            .
            <Typography
              my={2}
              sx={{ textDecoration: "underline" }}
              fontWeight={200}
            >
              The process is irreversible.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={!selectedInstallation}
            color="secondary"
            onClick={deleteInstallation}
          >
            Delete Installation
          </Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
};

export default HandleDeleteInstallation;
