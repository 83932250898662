import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import { ControlWhiteDialog } from "../../../theme";
import CreateInstallationWizard from "./CreateInstallationWizard";

const firstButtonStyle = { color: "white", border: "var(--primary_border)", background: "var(--primary_background-color)", minHeight: "56px", width: { xs: "50px", sm: "200px" }, display: "flex" }
const typographyStyle = { xs: "none", sm: "initial" }
const addCircleOutlineIconStyle = { display: "flex", mb: 0.25 }

const CreateInstallationDialog = (props: any) => {
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        sx={firstButtonStyle}
      >
        <Typography
          display={typographyStyle}
          mr={0.5}
          fontSize={13}
        >
          Installation
        </Typography>
        <AddCircleOutlineIcon
          sx={addCircleOutlineIconStyle}
          fontSize="small"
        />
      </Button>
      {/* DIALOG FOR DELETE */}
      <ControlWhiteDialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create Installation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <CreateInstallationWizard></CreateInstallationWizard>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

export default CreateInstallationDialog