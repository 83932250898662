import { OS } from "../local-interfaces/local-enum"
import { IShortCut } from "../local-interfaces/local-interfaces"

const COPY = 'c'
const PASTE = 'v'
const SAVE = 's'
const DELETE_MAC = "Backspace"
const DELETE_WIN = "Delete"

export const SHOW_LOGS: IShortCut = {
	key: "showlogs",
	localStorageKey: "isVerbose",
	value: true,
};

export const HIDE_LOGS: IShortCut = {
	key: "hidelogs",
	localStorageKey: "isVerbose",
	value: false,
};


export const PASTE_SHORTCUT = (event: any) => {
	return checkKeys(PASTE, event)
}
export const COPY_SHORTCUT = (event: any) => {
	return checkKeys(COPY, event)
}
export const SAVE_SHORTCUT = (event: any) => {
	return checkKeys(SAVE, event)
}
export const DELETE_SHORTCUT = (event: any) => {
	return event.key === (getOperatingSystem() === OS.MAC_OS ? DELETE_MAC : DELETE_WIN)
}


const getOperatingSystem = (): string => {
	const { userAgent } = navigator;
	let operatingSystem = OS.UNKNOW;
	if (userAgent.indexOf('Win') !== -1) {
		operatingSystem = OS.WINDOWS;
	} else if (userAgent.indexOf('Mac') !== -1) {
		operatingSystem = OS.MAC_OS;
	} else if (userAgent.indexOf('X11') !== -1 || userAgent.indexOf('Linux') !== -1) {
		operatingSystem = OS.LINUX;
	} else if (userAgent.indexOf('Android') !== -1) {
		operatingSystem = OS.ANDROID;
	} else if (userAgent.indexOf('iOS') !== -1) {
		operatingSystem = OS.IOS;
	}

	return operatingSystem;
};


const isCtrlOrCmndPressed = (event: any) => {
	return getOperatingSystem() === OS.MAC_OS ? event.metaKey === true : event.ctrlKey === true
}


const checkKeys = (comand: string, event: any) => {
	return event.key === comand && isCtrlOrCmndPressed(event)
}

