import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "../../styles/PhonePreview.css"
import { IComponent, IDashboard } from "../../common-interfaces/interfaces";
import { useRecoilState } from "recoil";
import { phoneActionBarWidthAtom, phoneHAtom, phoneWAtom } from "../../recoil/atoms";
import Loading from "../Loading/Loading";
import { PhonePreviewComponentRender } from "./renderingComponents/PhonePreviewComponentRender";
import PhoneActionBar from "./layout/PhoneActionBar";

import PhoneStatusBar from "./layout/PhoneStatusBar";
import PhoneImg from "./layout/PhoneImage";
import { BASE_URL } from "../../hooks/useApiWrapper";
import {
  PHONE_PADDING,
  PHONE_WindowBarWidth,
} from "../../helpers/utils";
import { useOnGet } from "../../hooks/useOnGet";
import { useParams } from "react-router-dom";

const fetchTimeout = 5000

export default function PhonePreview(props: any) {
  const [selectedDashboard, setSelectedDashboard] = useState<
    IDashboard | undefined
  >(undefined);
  const [dashImgUrl, setDashImgUrl] = useState<string>("");
  const [phoneW] = useRecoilState<number>(phoneWAtom);
  const [phoneH] = useRecoilState<number>(phoneHAtom);
  const [phoneActionBarWidth] = useRecoilState(phoneActionBarWidthAtom)

  const GET = useOnGet();
  let { dashboardId } = useParams();

  useEffect(() => {
    // possiamo spostare definizione di initDash fuori da useEffect
    const initDash = async () => {
      if (dashboardId) {
        const tempDash = await GET.remoteDash(dashboardId);
        if (JSON.stringify(tempDash) !== JSON.stringify(selectedDashboard)) {
          setSelectedDashboard(tempDash);
          tempDash?.img &&
            setDashImgUrl(
              `${BASE_URL}/${tempDash.img.name}.${tempDash.img.ext}`
            );
        }
      }
    };

    const intervalId = setInterval(initDash, fetchTimeout);

    return () => clearInterval(intervalId);
  }, [dashboardId, GET, selectedDashboard]);

  if (!selectedDashboard) return <Loading></Loading>;

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"row"}
      overflow={"auto"}
      bgcolor={"#444"}
    >
      <Box height={"100%"} width={phoneActionBarWidth}>
        <PhoneActionBar></PhoneActionBar>
      </Box>
      <Box
        className="phone-container"
        sx={{
          width: phoneW,
          height: phoneH,
          borderRadius: "10px !important",
          backgroundColor: "#000",
          padding: PHONE_PADDING,
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            w: "100%",
            height: phoneW > phoneH ? "20%" : "30%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: phoneW > phoneH ? "40%" : "10%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              color: "#fff",
            }}
          >
            <PhoneStatusBar></PhoneStatusBar>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: phoneW > phoneH ? "60%" : "15%",
              background: "var(--primary-color)",
              color: "var(--white-color)",
            }}
            className={"center"}
          >
            {selectedDashboard.name}
          </Box>

          {phoneW > phoneH ? (
            <></>
          ) : (
            <Box height={'75%'}>
              <PhoneImg img={dashImgUrl} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height:
              phoneW > phoneH
                ? `calc(100% - ${PHONE_WindowBarWidth}px)`
                : "70%",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: selectedDashboard
              ? selectedDashboard.backgroundColor
              : "#fff",
            backgroundImage:
              selectedDashboard?.img && phoneW > phoneH
                ? `url(${dashImgUrl})`
                : "",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {selectedDashboard?.components.map(
            (item: IComponent, index: number) => {
              return (
                <PhonePreviewComponentRender
                  comp={item}
                  key={index}
                ></PhonePreviewComponentRender>
              );
            }
          )}
        </Box>
      </Box>
    </Box>
  );
}
