import { FC } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

const DeleteRequestButton: FC<{ handleDelete: () => void, requestID: string }> = ({ handleDelete, requestID: string }) => {

  return <Button
    sx={{paddingY: '5px',}}
    variant="contained"
    onClick={handleDelete}
    title="Delete"
  >
    <DeleteIcon sx={{ fontSize: 18, color: '#ccc' }} />
  </Button>
}

export default DeleteRequestButton