import { useRecoilState } from "recoil"
import { loggedUserAtom } from "../../recoil/atoms"
import { ILoggedUser } from "../../local-interfaces/local-interfaces"
import { Box, Divider, Grid, Typography } from "@mui/material"
import AdminToolTable from "./AdminToolTable"


const AdminToolScreen = () => {
  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom)

  return <Box
    sx={{ height: '100%', width: '100%' }
    }
  >
    {(!loggedUser || loggedUser.role !== 'admin') ? (
      <Grid
        sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}

      >
        <Typography color={'red'} fontSize={50} fontFamily={'monospace'} >ACCESS DENIED</Typography>
        <Divider sx={{
          borderColor: "#666 !important",
          width: "15%",
          mx: "auto",
        }} />
        <Typography color={'#ccc'} fontFamily={'monospace'} mt={'2%'}>You don't have permission to view this page.</Typography>
        <Typography color={'#ccc'} fontFamily={'monospace'}>Please log in as an Admin.</Typography>
      </Grid>
    )
      :
      <AdminToolTable />
    }

  </Box >
}
export default AdminToolScreen