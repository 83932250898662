import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function PhoneComponentLabel(props: any) {
  const [fontSize, setFontSize] = useState<string>("");

  useEffect(() => {
    const value = Math.min(props.component.width, props.component.height) * 4;
    setFontSize(`${value}px`);
  }, [props.component]);

  return (
    <Typography
      sx={{
        fontSize: fontSize,
        color: props.component.color,
        maxWidth: "95%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
      }}
    >
      {props.component.name.toUpperCase()}
    </Typography>
  );
}
