import Typography from "@mui/material/Typography";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip
} from "@mui/material";
import { deepCopy, isValidEmail } from "../../helpers/utils";
import { ERequestSubtype, ERequestType, IComponent, IDashboard, IService } from "../../common-interfaces/interfaces";
import ShareIcon from '@mui/icons-material/Share';
import { FC, useState } from "react";
import ReactLoading from "react-loading";
import { ControlWhiteDialog } from "../../theme";
import useRequestNotificationUtils from "../../screens/Requests/hooks/useRequestNotificationUtils";
import { enqueueSnackbar } from "notistack";

const ShareButton: FC<{
  argument: ERequestSubtype,
  body: IDashboard | IComponent | IService,
  bgColor?: any,
  styleProps?: any
}> = ({ argument, body, bgColor, styleProps }) => {

  const [emailTo, setEmailTo] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const { shareNewRequest } = useRequestNotificationUtils();

  const handleNewEmailTo = (ev: any) => {
    setErr("");
    setEmailTo(ev.target.value);
  };

  const handleShareDashboard = async () => {
    setIsLoading(true)
    try {
      await shareNewRequest(emailTo, ERequestType.SHARE, argument, body._id, body.name);
      enqueueSnackbar(`Successfully shared ${argument} with ${emailTo}`, { variant: 'success' });
      setEmailTo('');
      setOpen(false); // todo: close dialog after share?
    } catch (e) {
      enqueueSnackbar('It was not possible to share the component', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tooltip title={`Share ${argument}`}>
        <Button
          size="small"
          color={bgColor ?? "steelBlue"}
          variant="contained"
          className="primaryButton"
          disabled={isLoading}
          sx={{ ...styleProps, padding: 0, width: '20px' }}
          onClick={() => setOpen(true)}
        >
          <ShareIcon sx={{ color: '#ccc' }} />
        </Button>
      </Tooltip>
      <ControlWhiteDialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>SHARE {argument.toUpperCase()}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the installer's email to share {argument}
          </DialogContentText>
          {!isLoading && (
            <TextField
              className="searchInput"
              id="installationName"
              autoFocus={true}
              autoCapitalize="none"
              sx={[
                { mt: 1, maxWidth: "400px", minWidth: "400px" },
                {
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "#d32f2f !important",
                  },
                },
              ]}
              label="Installer Email"
              type="email"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              variant="standard"
              error={err !== ""}
              onBlur={() => {
                setValidEmail(isValidEmail(emailTo));
              }}
              value={emailTo}
              onChange={handleNewEmailTo}
              helperText={
                !validEmail ? "Please enter a valid email address" : err ?? ""
              }
            />
          )}
          {isLoading && (
            <ReactLoading type={"bars"} color={"#fff"} width={"60px"} />
          )}
        </DialogContent>
        <DialogActions>
          {!(!emailTo || !validEmail || err !== "") && <Button
            onClick={handleShareDashboard}
          >
            Share
          </Button>}
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

export default ShareButton;
