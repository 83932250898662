import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { IComponent } from "../../../../common-interfaces/interfaces";
import PhoneComponentLabel from "./PhoneComponentLabel";

export default function ButtonsArray(props: any) {
  const [comp] = useState(props.component);
  const [status, setStatus] = useState<number>(0);

  const SIZE = `${100 / (comp.components.length > 0 ? comp.components.length : 3)
    }%`;

  const unselectedColor = `${comp.backgroundColor}aa`;

  return (
    <Box
      className="center"
      sx={{ width: "100%", height: "100%" }}
      title={props?.component?.name?.toUpperCase()}
    >
      <ButtonGroup
        variant="contained"
        sx={{ width: "98%", height: "80%", border: "var(--primary_border)" }}
      >
        {comp.component ? (
          comp.components.map((c: IComponent, index: number) => {
            return (
              <Button
                sx={{
                  height: 95,
                  maxWidth: SIZE,
                  width: SIZE,
                  background:
                    status === index ? comp.backgroundColor : unselectedColor,
                }}
                onClick={() => setStatus(index)}
              >
                {c.name}
              </Button>
            );
          })
        ) : (
          <>
            <Button
              sx={{
                height: "100%",
                maxWidth: SIZE,
                width: SIZE,
                background:
                  status === 0 ? "var(--secondary-color)" : unselectedColor,
              }}
              onClick={() => setStatus(0)}
            >
              <Typography fontSize={10}>
                {" "}
                Source <Typography>A</Typography>
              </Typography>
            </Button>
            <Button
              sx={{
                height: "100%",
                maxWidth: SIZE,
                width: SIZE,
                background:
                  status === 1 ? "var(--secondary-color)" : unselectedColor,
              }}
              onClick={() => setStatus(1)}
            >
              <Typography fontSize={10}>
                {" "}
                Source <Typography>B</Typography>
              </Typography>
            </Button>
            <Button
              sx={{
                height: "100%",
                maxWidth: SIZE,
                width: SIZE,
                background:
                  status === 2 ? "var(--secondary-color)" : unselectedColor,
              }}
              onClick={() => setStatus(2)}
            >
              <Typography fontSize={10}>
                {" "}
                Source <Typography>C</Typography>
              </Typography>
            </Button>
          </>
        )}
      </ButtonGroup>
    </Box>
  );
}
