import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { cellDimensionAtom } from "../../../../../recoil/workspace";
import { useEffect, useState } from "react";
import Loading from "react-loading";
import { IComponent } from "../../../../../common-interfaces/interfaces";
import "../../../../../styles/Component.css";
import HandleComponentType from "../common/HandleComponentType";
import { HANDLER_HEIGHT } from "./dndUtils";

export default function ComponentInside(props: any) {
  const [cellDimension] = useRecoilState(cellDimensionAtom);
  const [comp, setComp] = useState<IComponent | undefined>();
  const [w, setW] = useState<number>();
  const [h, setH] = useState<number>();
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();

  useEffect(() => {
    const comp = props.compInside;
    if (!comp) return;
    const X = cellDimension.width * (comp.left - props.containerX);
    let Y = cellDimension.height * (comp.top - props.containerY);
    let H = cellDimension.height * comp.height;
    const W = cellDimension.width * comp.width;
    H = Y === 0 ? H - HANDLER_HEIGHT : H - 2; // AGGIUSTAMENTI PER IL L'HANDLER
    Y = Y === 0 ? Y - 2 : Y - HANDLER_HEIGHT; //
    setX(X);
    setY(Y);
    setH(H);
    setW(W);
    setComp(comp);
  }, [props.compInside, cellDimension]);

  return (
    // TODO NEED TO FIX THIS
    <>
      {!h || !w || !comp ? (
        <>
          <Loading></Loading>
        </>
      ) : (
        <Box
          sx={{
            width: w - 4,
            height: h - 4,
            left: x,
            top: y,
            position: "absolute",
            mt: 0.5,
            border: "0.5px solid #fff7",
          }}
        >
          <HandleComponentType comp={comp}></HandleComponentType>
        </Box>
      )}
    </>
  );
}
