import React, { useEffect, useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { IDevice, IInstallation } from "../../../common-interfaces/interfaces";
import { selectedDevicesAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import "../../../styles/InstallationSideBar.css"
import { deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";
import InstallationDeviceModal from "../../../common-components/devices/InstallationDeviceModal";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { MdEdit, MdDelete } from "react-icons/md";
import HandleAddDevice from "../../../common-components/devices/HandleAddDevice";

const typographyStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const buttonStyle = {
  minWidth: "15px",
  width: "18.5px",
  height: "18.5px",
  minHeight: "15px",
  p: 0,
};


const InstallationDevices = () => {
  const SET = useOnSet();
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [editingDeviceID, setEditingDeviceID] = useState<string>();
  const [selectedDevices, setSelectedDevices] = useRecoilState(selectedDevicesAtom)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedDevices([])
  }, []);

  const handleDeleteDevice = async (id: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    const tmpInstallation: IInstallation = await deepCopy(selectedInstallation);
    tmpInstallation.devices = tmpInstallation.devices.filter(device => device._id !== id);
    SET.installation(tmpInstallation);
    if (selectedDevices.find(d => d._id === id)) {
      setSelectedDevices(prev => prev.filter(d => d._id !== id))
    }
  };

  const handleEditDevice = (id: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setEditingDeviceID(id);
    setOpen(true);
  };

  const handleClickOnDevice = (dev: IDevice) => {
     setSelectedDevices(prev => prev.includes(dev) ? prev.filter(d => d.hostname !== dev.hostname) :
      // AL MOMENTO GESTIAMO UN SOLO DEVICE [...prev, dev]
      [dev]
    )
  }

  if (!selectedInstallation?.devices) return null;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
        overflow="auto"
      >
        {selectedInstallation.devices.map((device) => (
          <Box
            key={device._id}
            mb={0.5}
            minWidth="49%"
            maxWidth="49%"
            textAlign="left"
            marginBottom={1}
            border="0.5px solid #333"
            borderRadius={1.25}
            boxSizing="border-box"
            sx={{ cursor: 'pointer' }}
            p={1}
            position="relative"
            bgcolor={selectedDevices.includes(device) ? '#444' : "#191919"}
            onClick={() => handleClickOnDevice(device)}
          >
            <TooltipOverflow
              tooltipChildren={
                <Typography
                  width="calc(100% - 40px)"
                  variant="subtitle2"
                  fontWeight="500"
                  mb={0.5}
                  color="#eee"
                  sx={typographyStyle}
                >
                  {device.hostname}
                </Typography>
              }
            />
            <TooltipOverflow
              tooltipTitle={device.product_model}
              tooltipChildren={
                <Typography variant="body2" color="#ccc" sx={typographyStyle}>
                  Model: {device.product_model}
                </Typography>
              }
            />
            <TooltipOverflow
              tooltipTitle={device.local_ip}
              tooltipChildren={
                <Typography variant="body2" color="#ccc" sx={typographyStyle}>
                  IP: {device.local_ip}
                </Typography>
              }
            />
            <Box
              position="absolute"
              top={4}
              right={4}
              gap={0.5}
              display="flex"
            >
              <Button
                sx={buttonStyle}
                onClick={(e) => handleEditDevice(device._id, e)}
              >
                <MdEdit color="#ccc" fontSize="22px" />
              </Button>
              <Button
                sx={buttonStyle}
                onClick={(e) => handleDeleteDevice(device._id, e)}
              >
                <MdDelete color="#bcbcbc" fontSize="20px" />
              </Button>
            </Box>
          </Box>
        ))}
        <Box width="100%" mt={2}>
          <Box width="50%" mx="auto" mt={2}>
            <HandleAddDevice hideLabel />
          </Box>
        </Box>
      </Box>
      <InstallationDeviceModal open={open} setOpen={setOpen} selectedDeviceId={editingDeviceID} />
    </Box>
  );
};

export default InstallationDevices;
