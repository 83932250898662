import { Box, TextField, Typography } from "@mui/material";
import '../../../styles/InstallationSideBar.css'

export default function SearchDashboard(props: any) {

  const handleSearch = (event: any) => {
    event.preventDefault();
    props.setFilter(event);
  };

  return (
    <Box width={'85%'} mx='auto' className={'SearchDashboard'}>
      <Box>
        <Typography my={3} className="underline-title center" fontSize={20}>SEARCH DASHBOARD</Typography>
      </Box>
      <Box>
        <Box sx={{ my: 2, mx: 'auto' }}>
          <TextField
            className="searchInput"
            label="Dashboard Name"
            type="text"
            fullWidth
            onChange={(e) => handleSearch(e)}
          />
        </Box>
      </Box>
    </Box>
  );
}
