import { Box, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useRecoilState } from "recoil"
import { IInstallation, IPlantFull } from "../../../common-interfaces/interfaces"
import { allPlantsFullAtom, selectedInstallationAtom } from "../../../recoil/atoms"
import { useOnSet } from "../../../hooks/useOnSet"
import { deepCopy } from "../../../helpers/utils"
import '../../../styles/InstallationSideBar.css'

export default function InstallationInfo(props: any) {
  const [allPlantsFull, setAllPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom)
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

  const [tmpInstallationName, setTmpInstallationName] = useState<string | undefined>(selectedInstallation?.name);
  const [tmpInstallationDescription, setTmpInstallationDescription] = useState<string | undefined>(selectedInstallation?.description);
  const SET = useOnSet();

  /*** HANDLE NEW INSTALLATION NAME ***/
  const handleNewInstallationName = (e: any) => {
    setTmpInstallationName(e.target.value);
  };

  const changeInstallationName = async () => {
    let tmpSelectedInstallation: IInstallation = await deepCopy(selectedInstallation);
    if (
      !tmpSelectedInstallation.name ||
      !tmpInstallationName ||
      tmpSelectedInstallation.name === tmpInstallationName
    )
      return;
    let tmpPlant: IPlantFull[] = await deepCopy(allPlantsFull)
    let index = tmpPlant.findIndex(plant => plant.installationName === tmpSelectedInstallation.name)
    if (index === -1)
      return
    tmpPlant[index].installationName = tmpInstallationName
    setAllPlantsFull(tmpPlant)
    tmpSelectedInstallation.name = tmpInstallationName;
    SET.installation(tmpSelectedInstallation);
  };

  /*** HANDLE NEW DESCRIPTION NAME ***/
  const handleNewInstallationDescription = (e: any) => {
    setTmpInstallationDescription(e.target.value);
  };
  const changeInstallationDescription = async () => {
    let tmpSelectedInstallation: IInstallation = await deepCopy(
      selectedInstallation
    );
    if (
      !tmpSelectedInstallation.name ||
      !tmpInstallationName ||
      tmpSelectedInstallation.description === tmpInstallationDescription
    )
      return;
    tmpSelectedInstallation.description = tmpInstallationDescription;
    SET.installation(tmpSelectedInstallation);
  };

  return (
    <Box width={'99%'} mx='auto'>
      <Typography className="underline-title center" fontSize={20}>
        current installation
      </Typography>
      <Box className="plantInfo">
        <TextField
          variant="standard"
          className="input"
          label="Name"
          type="text"
          value={tmpInstallationName}
          fullWidth
          onChange={handleNewInstallationName}
          onBlur={changeInstallationName}
        />
        <TextField
          className="input"
          variant="standard"
          label="Description"
          multiline
          value={tmpInstallationDescription}
          type="text"
          onChange={handleNewInstallationDescription}
          onBlur={changeInstallationDescription}
          fullWidth
        />
        {selectedInstallation?.clients && selectedInstallation.clients.map((client, index) =>
          <TextField
            key={client._id}
            className="searchInput"
            variant="standard"
            label={index === 0 ? "Client" : ''}
            disabled
            InputProps={{
              readOnly: true,
            }}
            value={client.username}
            type="text"
            fullWidth
          />
        )}
      </Box>
    </Box>
  );
}
