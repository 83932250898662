import { FC, useEffect, useState } from "react"
import { FormControl, InputLabel, Select, MenuItem, Typography, Box } from "@mui/material"
import { useRecoilState } from "recoil"
import { IService, IComponent, IQuery, EServiceType } from "../../../common-interfaces/interfaces"
import { deepCopy } from "../../../helpers/utils"
import { useOnSet } from "../../../hooks/useOnSet"
import { selectedServiceAtom } from "../../../recoil/Atom"

const formControlStyle = { "& .MuiFormLabel-root, .Mui-focused, .MuiInputBase-root": { color: "var(--light_grey-color) !important" } }
const SelectStyle = { "& .MuiSvgIcon-root": { color: "var(--light_grey-color) !important" } }

const SelectServiceParams: FC<{ service: IService, component: IComponent, label?: string }> = ({ label, service, component }) => {
    const SET = useOnSet()
    const [_, setSelectedService] = useRecoilState<IService>(selectedServiceAtom)
    const [commonServiceParams, setCommonServiceParams] = useState<IQuery[]>()

    useEffect(() => {
        let tmpServiceParams: IQuery[] = []
        switch (service.protocol) {
            case EServiceType.Rest:
                tmpServiceParams = service.restService?.requestSet.params?.filter(param => service.restService?.requestState.params?.find(p => param.key === p.key)) ?? []
                break
            case EServiceType.Osc:
                tmpServiceParams = service.oscService?.messageIn.params?.filter(param => service.oscService?.messageOut.params?.find(p => param.key === p.key)) ?? []
                break
            default:
                break
        }
        setCommonServiceParams(tmpServiceParams)
    }, [])

    const applyRestChanges = async (service: IService, param: IQuery, value: string) => {
        let comp: IComponent = await deepCopy(component)
        let tmpRestService = comp.services?.find(x => x._id === service._id)?.restService
        if (!tmpRestService) return console.error("no service");
        let stateParam = tmpRestService.requestState.params?.find(p => p.key === param.key)
        if (stateParam)
            stateParam.value = value;
        let setParam = tmpRestService.requestSet.params?.find(p => p.key === param.key)
        if (setParam)
            setParam.value = value;
        setSelectedService(prev => {
            return { ...prev, restService: tmpRestService }
        })
        SET.comp(comp);
    };

    const handleChange = async (param: IQuery, value: string) => {
        switch (service.protocol) {
            case EServiceType.Rest:
                await applyRestChanges(service, param, value);
                break;
            case EServiceType.Mqtt:
                break;
            case EServiceType.Osc:
                break;
            case EServiceType.Websocket:
                break;
            default:
                break;
        }
        let tmpCommonsServiceParams: IQuery[] = await deepCopy(commonServiceParams)
        let found = tmpCommonsServiceParams.find(p => param.key === p.key)
        if (found)
            found.value = value
        setCommonServiceParams(tmpCommonsServiceParams)
    };


    if (!commonServiceParams || !commonServiceParams.some(param => param.options && param.options.length > 0))
        return null
    return (
        <Box display={'flex'} alignItems={'baseline'} flexDirection='row'>
            {label && <Typography marginRight={'20px'}>{label}</Typography>}
            <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                {commonServiceParams?.map(param => !param.options?.length ?
                    <></> :
                    <FormControl key={param.key} size='small' variant="standard" fullWidth sx={formControlStyle}>
                        <InputLabel>{`${param.key}s`}</InputLabel>
                        <Select onChange={(e) => handleChange(param, e.target.value)} value={param.value} sx={SelectStyle}>
                            {param.options.map(opt => opt !== "" && <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
                        </Select >
                    </FormControl>
                )}
            </Box>
        </Box>
    )
}

export default SelectServiceParams