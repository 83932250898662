import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PauseIcon from "@mui/icons-material/Pause";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RepeatIcon from "@mui/icons-material/Repeat";
import StopIcon from "@mui/icons-material/Stop";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { MdBluetooth } from "react-icons/md";
import { GiUsbKey } from "react-icons/gi";
import RadioIcon from '@mui/icons-material/Radio';
import StreamIcon from '@mui/icons-material/Stream'; import { EButtonIcons } from "../local-interfaces/local-enum";
;

export function useOnIcons() {
  return {
    images: getImage(),
  };

  function getImage(): (iconName: string, fontSizei: number) => any {
    return (iconName: string, fontSize) => {
      switch (iconName) {
        case EButtonIcons.play:
          return <PlayArrowIcon style={{ fontSize }} />;

        case EButtonIcons.stop:
          return <StopIcon style={{ fontSize }} />;

        case EButtonIcons.pause:
          return <PauseIcon style={{ fontSize }} />;

        case EButtonIcons.backward:
          return <FastRewindIcon style={{ fontSize }} />;

        case EButtonIcons.mute:
          return <VolumeOffIcon style={{ fontSize }} />;

        case EButtonIcons.forward:
          return <FastForwardIcon style={{ fontSize }} />;

        case EButtonIcons.light:
          return <LightbulbIcon style={{ fontSize }} />;

        case EButtonIcons.reboot:
          return <PowerSettingsNewIcon style={{ fontSize }} />;

        case EButtonIcons.repeat:
          return <RepeatIcon style={{ fontSize }} />;

        case 'media-bluetooth-on':
        case EButtonIcons.bluetooth:
          return <MdBluetooth style={{ fontSize }} />;

        case EButtonIcons.usb:
          return <GiUsbKey style={{ fontSize }} />;
        case EButtonIcons.radio:
          return <RadioIcon style={{ fontSize }} />
        case 'view-stream':
        case EButtonIcons.stream:
          return <StreamIcon style={{ fontSize }} />

        default:
          return <DangerousIcon style={{ fontSize }} />;
      }
    };
  }
}
