import { DialogTitle, DialogContent, TextField, DialogActions, Button, Autocomplete, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { ControlWhiteDialog } from "../../theme";
import { useRecoilState } from "recoil";
import { deepCopy } from "../../helpers/utils";
import { useOnGet } from "../../hooks/useOnGet";
import { useOnSet } from "../../hooks/useOnSet";
import { IInstallation, IDevice } from "../../common-interfaces/interfaces";
import { allModelsAtom, selectedInstallationAtom } from "../../recoil/atoms";
import { IProduct } from "../../common-interfaces/indexInterfaces";
import { IAutocompleteOPT } from "../../local-interfaces/local-interfaces";
import { AUTOCOMPLETE_OPT_other } from "../../config/config";

const textfieldStyle = { my: 0.25 }
const dialogActionsStyle = { display: "flex", justifyContent: "flex-end" }

const InstallationDeviceModal = ({ open, setOpen, selectedDeviceId }: { open: boolean, setOpen: (open: boolean) => void, selectedDeviceId?: string | undefined }) => {
  const [allModels] = useRecoilState<IProduct[]>(allModelsAtom)

  const SET = useOnSet();
  const GET = useOnGet();

  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const emptyDevice = { _id: GET.id(), hostname: '', local_ip: '', product_model: '' }
  const [device, setDevice] = useState<IDevice>(emptyDevice);

  const options: IAutocompleteOPT[] = useMemo(() => {
    const tmpModels = [...allModels].sort((option1, option2) => option1["model-id"] < option2["model-id"] ? 1 : -1)
    return [AUTOCOMPLETE_OPT_other, ...tmpModels.map(model => {
      return {
        value: model,
        label: model.name,
        family: model.family.name.toUpperCase(),
      }
    }).sort((op1, op2) => op1.family >= op2.family ? 1 : -1)]
  }, [allModels])

  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDevice({ ...emptyDevice })
    if (!selectedInstallation?.devices?.length)
      return;
    if (selectedDeviceId) {
      const dev = selectedInstallation?.devices.find(device => device._id === selectedDeviceId);
      dev && setDevice(dev);
    }
  }, [open])

  const addOrEditDevice = async () => {
    if (!(device.hostname || device.local_ip || device.product_model))
      return
    let tmpSelectedInstallation: IInstallation = await deepCopy(selectedInstallation);
    if (selectedDeviceId) {
      const index = tmpSelectedInstallation?.devices.findIndex(device => device._id === selectedDeviceId)
      if (index >= 0)
        tmpSelectedInstallation.devices[index] = device
    }
    else
      tmpSelectedInstallation.devices.push(device)
    try {
      await SET.installation(tmpSelectedInstallation);
    } catch (error) {
      console.error(error)
    } finally {
      handleDialogClose()
    }
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation()
  }

  const setDeviceHostname = (e: any) => {
    setDevice(prev => {
      return {
        ...prev,
        hostname: e.target.value
      }
    })
  }

  const setDeviceProductModel = (model: string) => {
    setDevice(prev => {
      return {
        ...prev,
        product_model: model
      }
    })
  }

  const setDeviceLocalIp = (e: any) => {
    setDevice(prev => {
      return {
        ...prev,
        local_ip: e.target.value
      }
    })
  }

  return (
    <ControlWhiteDialog
      open={open}
      maxWidth={"sm"}
      onClose={handleDialogClose}
    >
      <DialogTitle>{selectedDeviceId ? 'Edit' : 'Add'} Device</DialogTitle>
      <DialogContent>
        <Autocomplete
          onChange={(_, option) => {
            setDeviceProductModel(option ? option.label : '');
          }}
          options={options}
          groupBy={option => option.family ?? ''}
          getOptionLabel={option => option.label}
          sx={{ width: "100%" }}
          value={options.find(opt => opt.label === device.product_model)}
          noOptionsText={noOptionsText}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              className="input"
              variant="standard"
              label="Model"
            />
          )}
        />
        <TextField
          className="input"
          variant="standard"
          label="Hostname"
          multiline
          value={device.hostname}
          type="text"
          onKeyDown={stopPropagation}
          onChange={setDeviceHostname}
          fullWidth
          sx={textfieldStyle}
        />
        <TextField
          className="input"
          variant="standard"
          label="IP"
          multiline
          value={device.local_ip}
          type="text"
          onKeyDown={stopPropagation}
          onChange={setDeviceLocalIp}
          fullWidth
          sx={textfieldStyle}
        />
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button
          color="secondary"
          disabled={!device.hostname || !device.local_ip || !device.product_model}
          onClick={addOrEditDevice}
        >
          {selectedDeviceId ? 'Edit' : 'Add'}
        </Button>
      </DialogActions>
    </ControlWhiteDialog>
  )
}

export default InstallationDeviceModal;

const noOptionsText = (
  <Typography sx={{ color: '#ccc' }}>
    Model not found
  </Typography>
);
//render the bg of select
const CustomPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      backgroundColor: "#222",
      border: "var(--primary_border)",
      color: "var(--grey_background-color)",
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          color: "var(--secondary-color)",
          "&.Mui-focused": {
            color: "var(--secondary-color)",
          },
        },
        "& .MuiAutocomplete-groupLabel": {
          bgcolor: '#444',
          color: '#ccc'
        }
      },
      "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
        bgcolor: "#333",
      },
    }}
  />
);
