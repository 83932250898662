import { IImage } from "../common-interfaces/interfaces"

export const deepCopy = async (item: any) => {
  return await JSON.parse(JSON.stringify(item))
}
export const no_image: IImage = {
  _id: 'no_img',
  alt: 'no_img',
  ext: 'png',
  name: 'no_image',
  src: 'no_image'
}

export const filterUniqueFromArray = (array: any[]) => {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}
export const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const Print = (log: any[]) => {
  const isVerbose = localStorage.getItem('isVerbose')
  if (`${isVerbose}` === 'true') { console.log(...log) }
}
export const convertImageToBase64 = async (image: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(image);
  });
};
export function isValidEmail(emailTo: string) {
  return /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i.test(emailTo);
}

// *** Phone PREVIEW
export const PHONE_pt = 20
export const PHONE_pl = 14
export const PHONE_pr = 14
export const PHONE_pb = 18

export const PHONE_PADDING = `${PHONE_pt}px ${PHONE_pr}px ${PHONE_pb}px ${PHONE_pl}px `
export const PHONE_ActionBarWidth = 60;
export const PHONE_WindowBarWidth = 65


// wizard config brands
export const wizardBrands = ['k-array', 'custom']


export const setCookie = (cname: string, cvalue: any, exdays: number = 2) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + "domain=k-array.com;" + "secure=true";
}

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}