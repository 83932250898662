import { Box, ButtonGroup, Button, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  phoneWAtom,
  phoneHAtom,
  phoneActionBarWidthAtom,
} from "../../../recoil/atoms";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  PHONE_ActionBarWidth,
  PHONE_WindowBarWidth,
} from "../../../helpers/utils";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

export default function PhoneActionBar(props: any) {
  const [phoneW, setPhoneW] = useRecoilState<number>(phoneWAtom);
  const [phoneH, setPhoneH] = useRecoilState<number>(phoneHAtom);
  const [isLandscape, setIsLandscape] = useState(phoneW > phoneH);
  const [phoneActionBarWidth, setPhoneActionBarWidth] = useRecoilState(
    phoneActionBarWidthAtom
  );
  const [isStatusBarOpen, setisStatusBarOpen] = useState<boolean>(true);
  const [tmpphoneW, setTmpPhoneW] = useState<number>(phoneW);
  const [tmpphoneH, setTmpPhoneH] = useState<number>(phoneH);

  const changeW = (value: number) => {
    setPhoneW(value);
  };

  const changeH = (value: number) => {
    setPhoneH(value);
  };

  const changeOrientation = () => {
    setPhoneW(phoneH);
    setPhoneH(phoneW);
    resizeWindow(phoneH + phoneActionBarWidth, phoneW);
    setIsLandscape(isLandscape);
  };

  const resizeWindow = (w: number, h: number) => {
    window.resizeTo(w, h + PHONE_WindowBarWidth);
  };

  const handleStatusBar = () => {
    const test = isStatusBarOpen ? 10 : PHONE_ActionBarWidth;
    setisStatusBarOpen(!isStatusBarOpen);
    setPhoneActionBarWidth(test);
    resizeWindow(phoneW + test, phoneH);
  };

  return isStatusBarOpen ? (
    <Box
      height={"100%"}
      id={"action-bar"}
      width={phoneActionBarWidth}
      boxSizing={"border-box"}
      sx={{ display: "flex", flexDirection: "column", fill: "#fff" }}
    >
      <ButtonGroup
        variant={"outlined"}
        orientation={"vertical"}
        sx={{ m: 0.5 }}
      >
        <Button
          sx={{ p: 1, background: "var(--primary-color)", border: 'var(--primary_border)' }}
          className={"center"}
          onClick={handleStatusBar}
        >
          <ArrowLeftIcon color="info" />
        </Button>
        <Button
          sx={{ p: 1, background: "var(--primary-color)", border: 'var(--primary_border)' }}
          onClick={() => resizeWindow(phoneW + phoneActionBarWidth, phoneH)}
        >
          <AspectRatioIcon color="info"></AspectRatioIcon>
        </Button>

        <Button
          sx={{ p: 1, background: "var(--primary-color)", border: 'var(--primary_border)' }}
          onClick={changeOrientation}
        >
          <ScreenRotationIcon color="info"></ScreenRotationIcon>
        </Button>
      </ButtonGroup>
      <TextField
        label="Width"
        type="text"
        variant="standard"
        color="info"
        sx={{
          padding: 0,
          mt: 3,
          mx: "auto",
          width: "80%",
          textAlign: "center",
          input: { color: "#fff" },
          label: { color: "#fff" },
        }}
        value={tmpphoneW}
        onChange={(ev) => setTmpPhoneW(+ev.target.value)}
        onBlur={(ev) => changeW(+ev.target.value)}
      />
      <TextField
        label="height"
        type="text"
        variant="standard"
        color="info"
        sx={{
          padding: 0,
          my: 1,
          mx: "auto",
          width: "80%",
          textAlign: "center",
          input: { color: "#fff" },
          label: { color: "#fff" },
        }}
        value={tmpphoneH}
        onChange={(ev) => setTmpPhoneH(+ev.target.value)}
        onBlur={(ev) => changeH(+ev.target.value)}
      />
    </Box>
  ) : (
    <Button
      sx={{
        p: 0,
        height: phoneH,
        minWidth: "10px",
        maxWidth: "10px",
        background: "var(--primary-color)",
        borderRadius: 0,
        borderRight: 'var(--primary_border)'
      }}
      onClick={handleStatusBar}
    >
      <ArrowRightIcon sx={{ color: "#fff" }} fontSize="medium"></ArrowRightIcon>
    </Button>
  );
}
