import { atom } from "recoil";
import { INotificationRequest } from "../../../common-interfaces/interfaces";

export const allRequestsAtom = atom<INotificationRequest[]>({
  key: 'allRequest',
  default: []
})
export const sortDirectionAtom = atom<'asc' | 'desc'>({
  key: 'sortDirection',
  default: 'asc',
});

export const sortParamAtom = atom<keyof INotificationRequest>({
  key: 'sortParam',
  default: 'status',
});
export const filterParamAtom = atom<'from' | 'to' | 'all'>({
  key: 'filterParam',
  default: 'all',
});
export const requestsFromRemoteAtom =  atom<INotificationRequest[]>({
  key: 'requestsFromRemote',
  default: []
})