
import { DocsTreeNode } from "../local-interfaces/docs";

export const DOCS_HOME: DocsTreeNode = {
    id: "0",
    label: "",
    title: "Docs",
    content: `<p> Welcome to the <em>K-Control</em> documentation!</p>`,
}

export const DOCUMENTAZIONE: DocsTreeNode[] = [
    {
        id: "1",
        label: "Installations",
        title: "Installations",
        content: "<p>Hello!</p>",
        subcontent: [
            {
                id: "2",
                label: "Dashboards",
                title: "Dashboards",
                content: "<p>World!</p>",
                subcontent: [
                    {
                        id: "5",
                        label: "Components",
                        title: "Components",
                        content: "",
                        subcontent: [
                            {
                                id: "6",
                                label: "K-ARRAY",
                                title: "K-ARRAY components",
                                content: "",
                                subcontent: [
                                    {
                                        id: "7",
                                        label: "MUTE",
                                        title: "MUTE component",
                                        content: ""
                                    },
                                    {
                                        id: "8",
                                        label: "LED",
                                        title: "LED component",
                                        content: ""
                                    },
                                    {
                                        id: "9",
                                        label: "VOLUME",
                                        title: "VOLUME component",
                                        content: ""
                                    },
                                    {
                                        id: "10",
                                        label: "SOURCE",
                                        title: "SOURCE component",
                                        content: ""
                                    },
                                    {
                                        id: "11",
                                        label: "REBOOT",
                                        title: "REBOOT component",
                                        content: ""
                                    },
                                    {
                                        id: "12",
                                        label: "STANDBY",
                                        title: "STANDBY component",
                                        content: ""
                                    }
                                ]
                            },
                            {
                                id: "13",
                                label: "CUSTOM",
                                title: "CUSTOM components",
                                content: "",
                                subcontent: [
                                    {
                                        id: "14",
                                        label: "VOLUME",
                                        title: "VOLUME custom component",
                                        content: ""
                                    },
                                    {
                                        id: "15",
                                        label: "SIMPLE SLIDER",
                                        title: "SIMPLE SLIDER custom component",
                                        content: ""
                                    },
                                    {
                                        id: "16",
                                        label: "SWITCH",
                                        title: "SWITCH custom component",
                                        content: ""
                                    },
                                    {
                                        id: "17",
                                        label: "SHUFFLE",
                                        title: "SHUFFLE custom component",
                                        content: ""
                                    }
                                ]
                            },
                            {
                                id: "18",
                                label: "SIMPLE",
                                title: "SIMPLE components",
                                content: "",
                                subcontent: [
                                    {
                                        id: "19",
                                        label: "LABEL",
                                        title: "LABEL simple component",
                                        content: ""
                                    },
                                    {
                                        id: "20",
                                        label: "BUTTON",
                                        title: "BUTTON simple component",
                                        content: ""
                                    },
                                    {
                                        id: "21",
                                        label: "SWITCH",
                                        title: "SWITCH simple component",
                                        content: ""
                                    },
                                    {
                                        id: "22",
                                        label: "SIMPLE VOLUME",
                                        title: "SIMPLE VOLUME simple component",
                                        content: ""
                                    },
                                    {
                                        id: "23",
                                        label: "CONTAINER",
                                        title: "CONTAINER simple component",
                                        content: ""
                                    },
                                    {
                                        id: "24",
                                        label: "BUTTON ARRAY",
                                        title: "BUTTON ARRAY simple component",
                                        content: ""
                                    },
                                    {
                                        id: "25",
                                        label: "SIMPLE SLIDER",
                                        title: "SIMPLE SLIDER simple component",
                                        content: ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: "26",
                        label: "Workspace",
                        title: "Dashboard Workspace",
                        content: ""
                    },
                    {
                        id: "27",
                        label: "Edit",
                        title: "Edit Dashboard component",
                        content: ""
                    }
                ]
            }
        ]
    }
];