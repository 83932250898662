import { atom } from "recoil";
import { IPlantFull, IPlant } from "../common-interfaces/interfaces";

export const creatingPlantAtom = atom<boolean>({
  key: "creatingPlant",
  default: false,
});
export const wizardSelectedPlantAtom = atom<IPlantFull | undefined>({
  key: "wizardSelectedPlant",
  default: undefined,
});

export const createInstallationActiveStepAtom = atom<number>({
  key: "createInstallationActiveStep",
  default: 0,
});
export const tempPlantAtom = atom<IPlant>({
  key: "tempPlant",
  default: {
    address: "default",
    area: "default",
    city: "default",
    description: "default",
    id: 1,
    latitude: 1,
    longitude: 1,
    name: "",
    state: "default",
  },
});
