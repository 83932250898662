import { Paper, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RequestActions from "./actions/RequestActions";
import { allRequestsAtom, sortDirectionAtom, sortParamAtom } from "./utils/requestAtoms";
import { useRecoilState } from "recoil";
import useRequestNotificationUtils from "./hooks/useRequestNotificationUtils";
import { INotificationRequest } from "../../common-interfaces/interfaces";
import { statusColorMap } from "./utils/requestsConfig";

const tableContainerStyle = {
  width: "98%",
  margin: '1% 0',
  borderRadius: "7px",
  backgroundColor: "black",
};

const tableHeadStyle = {
  "& .MuiTableCell-root": {
    textAlign: "center",
    bgcolor: "var(--primary_background-color)",
  }
};

const tableBodyStyle = {
  backgroundColor: "var(--secondary_background-color)",
  "& .MuiTableRow-root": {
    ":hover": { bgcolor: "var(--selected-color)" },
  },
  "& .MuiTableCell-root": {
    color: "white",
    textAlign: "center"
  }
};

const RequestWorkspace = () => {
  const borderRight = { borderRight: '.5px solid #555' }
  const [allRequests] = useRecoilState(allRequestsAtom)
  const [sortDirection] = useRecoilState(sortDirectionAtom);
  const [sortParam] = useRecoilState(sortParamAtom);

  const { sortRequests, installerEmail } = useRequestNotificationUtils()

  const smallCells: (keyof INotificationRequest)[] = ['creationDate', 'lastEdit', 'status',  /*REMOVE FOR MESSAGE 'type',*/]
  const tableRow: (keyof INotificationRequest)[] = ['label', 'subtype', 'from', 'to']
  
  return (
    <Grid item flexGrow={1} bgcolor={'#111'} sx={{ overflow: 'auto', height: '100%' }}>
      <Grid container maxHeight={'100%'} overflow={'auto'} className="center">
        <TableContainer component={Paper} sx={tableContainerStyle}>
          <Table>
            <TableHead sx={tableHeadStyle}>
              <TableRow>
                {[...smallCells, ...tableRow].map(cell => {
                  return <TableCell
                    key={cell}
                    sx={{
                      padding: 0,
                      cursor: 'pointer',
                      width: smallCells.includes(cell) ? '5%' : '20%',
                      backgroundColor: sortParam === cell ? 'var(--header-highlight-color)' : 'inherit',
                      ...borderRight
                    }}
                  >
                    <Button sx={{ color: sortParam === cell ? 'var(--secondary-color)' : '#fff', width: '100%', height: '100%', borderRadius: 0 }}
                      onClick={() => sortRequests(cell)}>
                      {cell.toUpperCase().replace('DATE', '')}
                      {sortParam === cell && (sortDirection === 'asc' ?
                        <ArrowDownwardIcon sx={{ ml: 1, fontSize: 20, padding: 0, verticalAlign: 'sub' }} /> :
                        <ArrowUpwardIcon sx={{ ml: 1, fontSize: 20, padding: 0, verticalAlign: 'sub' }} />)}
                    </Button>
                  </TableCell>
                })}
                <TableCell sx={{
                  color: '#fff',
                }}>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={tableBodyStyle}>
              {allRequests && allRequests.map((request) => (
                <TableRow sx={{ height: '10px' }} key={request._id} >
                  {installerEmail ? <>
                    <TableCell sx={borderRight} style={{ background: statusColorMap[request.status] + 'aa' }}>{request.creationDate ? new Date(+request.creationDate * 1000).toLocaleDateString() : ""}</TableCell>
                    <TableCell sx={borderRight} style={{ background: statusColorMap[request.status] + 'aa' }}>{request.lastEdit ? new Date(+request.lastEdit * 1000).toLocaleDateString() : ""}</TableCell>
                    <TableCell sx={borderRight} style={{ background: statusColorMap[request.status] + 'aa' }}>{request.status.toUpperCase()}</TableCell>
                    {/* 
                    REMOVE FOR MESSAGES
                    <TableCell sx={borderRight}>{request.type.toUpperCase()}</TableCell> 
                    */}
                    <TableCell sx={borderRight}>{request.label.toUpperCase()}</TableCell>
                    <TableCell sx={borderRight}>{request.subtype.toUpperCase()}</TableCell>
                    <TableCell sx={borderRight}>{request.from}</TableCell>
                    <TableCell sx={borderRight}>{request.to}</TableCell>
                    <TableCell>
                      <RequestActions request={request} />
                    </TableCell>
                  </> : <Skeleton />}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
export default RequestWorkspace;

