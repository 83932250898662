import { Button, Grid, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { IQuery } from '../../../../common-interfaces/interfaces'
import QueryValueSelect from './QueryValueSelect'
import DeleteSegmentHandler from './DeleteSegmentHandler';

const HandleQuerySegment: FC<{
  handleDoubleClick: (query: IQuery) => void
  dispatchSetQuery: (param: IQuery) => void
  handleDelete: (key: string) => void
  segment: IQuery
  divider: string
}> = ({
  handleDoubleClick,
  dispatchSetQuery,
  handleDelete,
  segment,
  divider
}) => {
    const [query, setQuery] = useState(segment)

    useEffect(() => {
      setQuery(segment)
    }, [segment]);

    useEffect(() => {
      if (query) dispatchSetQuery(query)
    }, [query]);

    const handleClickOnDelete = () => {
      handleDelete(query.key)
    }

    return (
      <Grid container item xs={3} height={'100%'} justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={10}>{(!query?.options || query.options.length === 0) ?
          <Button onDoubleClick={() => handleDoubleClick(query)} sx={{ p: 0, mx: 'auto', width: '100%' }}>
            <Typography
              sx={{ width: '100%', color: 'var(--white-color)' }}
            >{`@@${query.key}@@`}</Typography>
          </Button>
          :
          <Button onDoubleClick={() => handleDoubleClick(query)} sx={{ p: 0, mx: 'auto', width: '100%', }}>
            <QueryValueSelect query={query} cb={setQuery} />
          </Button>
        }</Grid>
        <Grid item xs={1}>
          <DeleteSegmentHandler handleDelete={handleClickOnDelete} />
        </Grid>
        <Grid item xs={1}><Typography mx={0.25}>{divider}</Typography></Grid>
      </Grid>
    )
  }

export default HandleQuerySegment