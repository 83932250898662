import { Box } from "@mui/material";
import ReactLoading from "react-loading";
import "../../../../styles/Loading_overlay.css";

const Loading_overlay = () => {
  return (
    <Box className="loading_overlay_container" >
      <Box m="auto" width={"100%"} height={"100%"} className={'center loading-overlay'}>
        <ReactLoading type={"bars"} color={"#ccc"} width={"5vw"} />
      </Box>
    </Box>
  );
};
export default Loading_overlay;
