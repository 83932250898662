import { Button, ButtonGroup } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useRecoilState } from "recoil";
import { workspaceWidthAtom } from "../../../recoil/atoms";
import HandleOpenPreview from "./HandleOpenPreview";

export default function WorkspaceButtons() {
  const [workspaceWidth, setWorkspaceWidth] =
    useRecoilState(workspaceWidthAtom);
  return (
    <>
      <ButtonGroup
        sx={{
          position: "absolute",
          top: 2.5,
          right: 30,
          background: "#121212",
          border: "1px solid",
        }}
      >
        <HandleOpenPreview />
        <Button
          color="steelBlue"
          variant="outlined"
          onClick={() =>
            setWorkspaceWidth(
              workspaceWidth > 450 ? workspaceWidth - 150 : workspaceWidth
            )
          }
          sx={{ color: "#fff !important", ml: "auto", display: "flex" }}
        >
          <ZoomOutIcon fontSize="small"></ZoomOutIcon>
        </Button>
        <Button
          variant="outlined"
          color="steelBlue"
          onClick={() =>
            setWorkspaceWidth(
              workspaceWidth < 1800 ? workspaceWidth + 150 : workspaceWidth
            )
          }
          sx={{ color: "#fff !important", ml: "auto", display: "flex" }}
        >
          <ZoomInIcon fontSize="small"></ZoomInIcon>
        </Button>
      </ButtonGroup>
    </>
  );
}
