import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import ReactLoading from "react-loading";
import { IPlantUser } from "../../../common-interfaces/interfaces";
import { isValidEmail } from "../../../helpers/utils";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { ILoggedUser } from "../../../local-interfaces/local-interfaces";
import { allUsersAtom, loggedUserAtom } from "../../../recoil/atoms";
import { ControlWhiteDialog } from "../../../theme";

export default function CreatePartnership(props: any) {
  const [emailTo, setEmailTo] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useRecoilState<IPlantUser[]>(allUsersAtom);

  const [loggedUser, _] = useRecoilState<ILoggedUser | undefined>(
    loggedUserAtom
  );

  const apiWrapper = useApiWrapper();

  const handleDialogOpen = () => {
    setEmailTo("");
    setErr("");
    setValidEmail(true);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleNewEmailTo = (ev: any) => {
    setErr("");
    setEmailTo(ev.target.value);
  };
  const handleCreatePartnership = async (event: any) => {
    setIsLoading(true);
    const body = {
      email_from: loggedUser?.username && encodeURIComponent(loggedUser.username),
      email_to: encodeURIComponent(emailTo),
    };
    try {
      let res = await createNewPartneship(body);
      if (res) {
        handleDialogClose();
        return;
      }
    } catch (e) {
      console.error(e);
      setErr(`EMAIL ${e}`);
    }
    setIsLoading(false);
  };
  const createNewPartneship = async (body: any) => {
    //dashboard creation
    const url = "api/friendship";
    let newPartneship = await apiWrapper.postWithAuth(url, body);
    return !newPartneship.data ? undefined : newPartneship.data;
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleDialogOpen}
        sx={{
          minWidth: "200px",
          maxWidth: "200px",
          maxHeight: "25px",
          display: "flex",
          flexDirection: "row",
          border: "var(--primary_border)",
          borderBottomLeftRadius: "0 !important",
          borderBottomRightRadius: "0 !important",
        }}
      >
        Add Partneship
        <GroupAddIcon fontSize={"small"} sx={{ ml: "auto" }} />
      </Button>
      <ControlWhiteDialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create Partneship</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the client email to send request
          </DialogContentText>
          {!isLoading && (
            <TextField
              className="searchInput"
              id="installationName"
              sx={[
                { mt: 1, maxWidth: "400px", minWidth: "400px" },
                {
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "#d32f2f !important",
                  },
                },
              ]}
              label="Client Email"
              type="email"
              variant="standard"
              error={err !== ""}
              onBlur={() => {
                setValidEmail(isValidEmail(emailTo));
              }}
              value={emailTo}
              onChange={(ev) => handleNewEmailTo(ev)}
              helperText={
                !validEmail ? "Please enter a valid email address" : err ?? ""
              }
            />
          )}
          {isLoading && (
            <ReactLoading type={"bars"} color={"#fff"} width={"60px"} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!emailTo || !validEmail || err !== ""}
            onClick={handleCreatePartnership}
          >
            Request
          </Button>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}
