import Box from "@mui/material/Box";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { IService, IRestService } from "../../../../common-interfaces/interfaces";
import { deepCopy } from "../../../../helpers/utils";
import { restServiceAtom } from "../../../../recoil/Atom";
import { newServiceAtom } from "../../../../recoil/CreateServiceAtoms";

const StepZero = (props: any) => {
  const [newService, setNewService] = useRecoilState<IService>(newServiceAtom);
  const [restService, setRestService] =
    useRecoilState<IRestService>(restServiceAtom);
  const allService: IService[] = props.allServices;

  const handleChange = async (_: any, service: IService | null) => {
    if (!service) return
    let tempServ: IService = await deepCopy(service)
    tempServ._id = "newService";
    if (tempServ.restService) setRestService(tempServ.restService);
    setNewService(tempServ);
  };

  return (
    <Box width="100%" marginTop={4}>
      <Box minWidth={120}>
        <Autocomplete
          onChange={handleChange}
          options={allService}
          groupBy={option => option.brand?.toUpperCase() ?? ''}
          getOptionLabel={option => option.name}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Service" />}
          PaperComponent={CustomPaper}
        />
        {/* {newService.description && (
          <Box mt={3}>
            <Typography>Description:</Typography>
            <Typography mt={1} pl={1}>
              {newService?.description}
            </Typography>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}

const CustomPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      backgroundColor: "#282828",
      border: "var(--primary_border)",
      color: "var(--grey_background-color)",
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          color: "var(--secondary-color)",
          "&.Mui-focused": {
            color: "var(--secondary-color)",
          },
        },
      },
      "& .MuiAutocomplete-listbox .MuiListSubheader-root": {
        bgcolor: '#222',
        color: "var(--secondary-color)",
      },
      ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
        bgcolor: "#333",
        color: "var(--secondary-color)",
      },
    }}
  />
);


export default StepZero