import { IQuery, EHttpBodyType, EHttpLanguageType, EComponentType, IService, EServiceType, EMethod, EHttpReturnType } from "../common-interfaces/interfaces";
import { IRequestBody, IBodyRow, IQueryField } from "../local-interfaces/local-interfaces";
import { IAutocompleteOPT } from "../local-interfaces/local-interfaces";

export const emptyQuery: IQuery = {
  key: "",
  type: "",
  value: "",
  options: [],
  min: undefined,
  max: undefined,
  step: undefined,
  unitOfMeasure: undefined,
};

export const emptyBody: IRequestBody = {
  mode: EHttpBodyType.RAW,
  raw: "",
  options: {
    raw: {
      lang: EHttpLanguageType.JSON,
    },
  },
}
export const emptyBodyRow: IBodyRow = {
  hasParam: false,
  key: '',
  query: undefined,
  value: ''
}

export const queryFields: IQueryField[] = [
  { name: "key", type: "text", mandatory: true },
  { name: "step", type: "number", mandatory: false },
  { name: "min", type: "number", mandatory: false },
  { name: "max", type: "number", mandatory: false },
];

export const componentFields = [
  { key: "brand", inputType: "text", isDisabled: true },
  { key: "name", inputType: "text" },
  { key: "description", inputType: "text" },
  { key: "backgroundColor", inputType: "color" },
  { key: "color", inputType: "color" },
  { key: "min", inputType: "number", componentType: EComponentType.Slider },
  { key: "max", inputType: "number", componentType: EComponentType.Slider },
  { key: "polling", inputType: "number" },
];

export const editableRestService: IService = {
  _id: undefined,
  name: "EDITABLE HTTP REQUEST (REST)",
  description: "EDITABLE REST HTTP REQUEST",
  brand: "simple",
  editable: false,
  protocol: EServiceType.Rest,
  host: '',
  restService: {
    requestState: {
      endpoint: '',
      method: EMethod.GET,
      response: {
        languageType: EHttpLanguageType.JSON,
        fieldToReturn: '',
        type: EHttpReturnType.BOOLEAN
      }
    },
    requestSet: {
      endpoint: '',
      method: EMethod.PUT,
      response: {
        languageType: EHttpLanguageType.JSON,
        fieldToReturn: '',
        type: EHttpReturnType.OBJECT
      }
    }
  },
  serviceType: "IRestService",
}

export const editableOscService: IService = {
  _id: undefined,
  name: "EDITABLE OSC REQUEST",
  description: "EDITABLE OSC REQUEST",
  brand: "simple",
  editable: false,
  protocol: EServiceType.Osc,
  host: '',
  oscService: {
    messageOut: {
      endpoint: '',
      port: 10007,
      message: '',
      params: []
    },
    messageIn: {
      endpoint: '',
      port: 10009,
      message: '',
      params: []
    }
  },
  serviceType: "IOscService",
}

export const AUTOCOMPLETE_OPT_other: IAutocompleteOPT = {
  value: 'other',
  label: 'Other',
  family: 'GENERIC'
}

// key: string;
// type: string;
// value?: any;
// options?: string[]
// min?: number | boolean;
// max?: number | boolean;
// step?: number;
// unitOfMeasure?: string;
