import { FC } from "react";
import { DoDisturb } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ERequestStatus, ERequestStatusColor } from "../../../../common-interfaces/interfaces";

const DeclineButton: FC<{ handleAction: (value: ERequestStatus) => void, requestID:string }> = ({ handleAction }) => {
  return <Button
    sx={{paddingY: '5px',}}
    onClick={() => handleAction(ERequestStatus.REFUSED)}
    title="Refuse"
  >
    <DoDisturb sx={{ fontSize: 18, color: ERequestStatusColor.REFUSED, }} />
  </Button>
}

export default DeclineButton