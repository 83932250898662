import { Box } from "@mui/material";
import { BASE_URL } from "../../../hooks/useApiWrapper";
import { no_image } from "../../../helpers/utils";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function PhoneImg(props: any) {

  return (
    <Box height={"100%"} width={"100%"}>
      <Box
        sx={{
          height: `90%`,
          width: "100%",
          maxWidth: "100%",
          backgroundImage: props?.img
            ? `url(${props.img})`
            : `${BASE_URL}/${no_image.name}.${no_image.ext}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "10%",
          mb: "auto",
          color: "var(--white-color)",
          borderBottom: "var(--primary_border)",
          background: "var(--primary-color)",
          boxSizing: "border-box",
        }}
        className="center"
      >
        <KeyboardArrowUpIcon fontSize="small" />
      </Box>
    </Box>
  );
}
