import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, Stack } from "@mui/material";
import { FC, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import HandleDeleteInstallation from "../modifyInstallation/HandleDeleteInstallation";
import HandleEditInstallation from "../modifyInstallation/HandleEditInstallation";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { IInstallation } from "../../../common-interfaces/interfaces";
import { deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";
import { IInstallationRow } from "../../../local-interfaces/local-interfaces";
import { isLoadingAtom, allPlantsFullAtom } from "../../../recoil/atoms";
import { useApiWrapper } from "../../../hooks/useApiWrapper";

const tableContainerStyle = { width: "98%", height: "100%", margin: '1% 0', borderRadius: "7px", overflow: "auto", backgroundColor: "black" }
const tableHeadStyle = { "& .MuiTableCell-root": { textAlign: "center", bgcolor: "var(--primary_background-color)", color: "white" } }
const tableBodyStyle = {
  backgroundColor: "var(--secondary_background-color)",
  "& .MuiTableRow-root": { ":hover": { bgcolor: "var(--selected-color)" }, cursor: "pointer" },
  "& .MuiTableCell-root": { minWidth: "50px", maxWidth: "150px", color: "white", textAlign: "center" }
}
const actionsStyle = { cursor: "default" }

const InstallationsTable: FC<{ filteredRows: IInstallationRow[] }> = ({ filteredRows }) => {
  const navigate = useNavigate();
  const SET = useOnSet();
  const apiWrapper = useApiWrapper();

  const [_, setIsLoading] = useRecoilState(isLoadingAtom);
  const [allPlantsFull] = useRecoilState(allPlantsFullAtom);

  const handleForward = async (_: any, id: any) => {
    setIsLoading(true);
    let plantFull = allPlantsFull.find(pf => pf.plant.id === id);
    if (!plantFull)
      return setIsLoading(false);
    const newInstallation: IInstallation | undefined = (await apiWrapper.postWithAuth("api/installation/plant2installation", plantFull)).data;
    if (!newInstallation)
      return setIsLoading(false);
    SET.installation(await deepCopy(newInstallation))
    navigate(`/installation/${newInstallation._id}`);
    setIsLoading(false);
  };

  const stopPropagation: MouseEventHandler<HTMLTableCellElement> = (event: any) => event.stopPropagation()

  return (
    // table
    <TableContainer component={Paper} sx={tableContainerStyle}>
      <Table stickyHeader className="darkTable">
        <TableHead sx={tableHeadStyle}>
          <TableRow>
            <TableCell>INSTALLATION NAME</TableCell>
            <TableCell>CLIENTS</TableCell>
            <TableCell>LOCATION</TableCell>
            <TableCell>LAST EDITED</TableCell>
            <TableCell>CREATED</TableCell>
            <TableCell>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={tableBodyStyle}>
          {filteredRows.length &&
            filteredRows.map(row =>
              <TableRow key={row.plant.id} onClick={async (event) => await handleForward(event, row.plant.id)}>
                {/* installation name */}
                <TableCell
                  children={
                    <TooltipOverflow
                      tooltipChildren={
                        <Typography
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          children={row.installationName}
                        />}
                    />}
                />
                {/* clients */}
                <TableCell
                  children={
                    <TooltipOverflow
                      tooltipChildren={
                        <Typography
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          children={row.clients.join(", ")}
                        />}
                    />}
                />
                {/* location */}
                <TableCell
                  children={
                    <TooltipOverflow
                      tooltipChildren={
                        <Typography
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          children={row.location}
                        />}
                    />}
                />
                {/* last edited */}
                <TableCell
                  children={
                    <TooltipOverflow
                      tooltipChildren={
                        <Typography
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          children={row.lastEdit}
                        />}
                    />}
                />
                {/* created */}
                <TableCell
                  children={
                    <TooltipOverflow
                      tooltipChildren={
                        <Typography
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          children={row.creationDate}
                        />}
                    />}
                />
                <TableCell sx={actionsStyle} onClick={stopPropagation}>
                  <Stack
                    direction='row'
                    justifyContent={'center'}
                    gap={2}
                  >
                    <Box height="100%">
                      <HandleEditInstallation plantID={row.plant.id} />
                    </Box>
                    <Box height="100%">
                      <HandleDeleteInstallation installation={row} />
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstallationsTable;
