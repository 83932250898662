import React, { useCallback, useEffect, useState } from 'react';
import { List, Popover, Typography, DialogTitle, DialogContent, PopoverProps, DialogActions, Box, IconButton, InputBase, Paper } from '@mui/material';
import { useRecoilState } from 'recoil';
import { deepCopy } from '../../../../helpers/utils';
import { allRequestsAtom } from '../../utils/requestAtoms';
import Close from '@mui/icons-material/Close';
import Send from '@mui/icons-material/Send';
import { INotificationRequest, ERequestType, ERequestStatus, ERequestSubtype } from '../../../../common-interfaces/interfaces';

interface messagePopoverProps extends PopoverProps { requestID: string, userEmail: string }

const popoverStyle = { "& .MuiPaper-root": { backgroundColor: 'var(--primary-color)', color: 'white', borderColor: 'white', width: '30vw' } }

const MessagePopover: React.FC<messagePopoverProps> = ({ requestID, userEmail, open, anchorEl, onClose }) => {
  const [allRequests, setAllRequests] = useRecoilState(allRequestsAtom);
  const [newMessage, setNewmessage] = useState<string>('')
  const [messages, setMessages] = useState<INotificationRequest[]>([]);
  const [mail, setMail] = useState<string>()

  useEffect(() => {
    const messages = getMessageQueue()
    setMessages(messages)
  }, [allRequests]);

  useEffect(() => {
    userEmail && setMail(userEmail)
  }, [userEmail]);

  const getMessageQueue = (): INotificationRequest[] => {
    const req = allRequests.find(r => r._id === requestID);
    let tmpMessages: INotificationRequest[] = [];
    const countPendingMessages = (request: INotificationRequest) => {
      if (request.type === ERequestType.MESSAGE) {
        tmpMessages.push(request)
      }
      request.subrequests?.forEach(subrequest => countPendingMessages(subrequest));
    };
    if (req) {
      countPendingMessages(req);
    }
    return tmpMessages;
  };

  const close = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };


  const isSend = (message: INotificationRequest): boolean => {
    return message.from === mail
  }

  const hasPreviousSameSender = (index: number): boolean => {
    if (!index) return false
    return messages[index].from === messages[index - 1].from
  }
  const hasNextSameSender = (index: number): boolean => {
    if (!messages[index + 1]) return false
    return messages[index].from === messages[index + 1].from
  }

  const handleBorderRadius = useCallback((index: number) => {
    const borderTopLeftRadius = hasPreviousSameSender(index) ? 0 : 4
    const borderTopRightRadius = hasPreviousSameSender(index) ? 0 : 4
    const borderBottomLeftRadius = hasNextSameSender(index) ? isSend(messages[index]) ? 4 : 0 : 4
    const borderBottomRightRadius = (hasNextSameSender(index) && isSend(messages[index])) ? 0 : 4
    return {
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius
    }
  }, [messages])


  const handleCancelNewMessage = () => {
    setNewmessage('')
  }

  const sendNewMessage = async () => {
    const req: INotificationRequest = await deepCopy(allRequests.find(r => r._id === requestID))
    const newRequest: INotificationRequest = {
      _id: Date.now().toString(), //REMOVE FOR PRODUCTION
      status: ERequestStatus.PENDING,
      from: userEmail,
      to: req.from === userEmail ? req.to : req.from,
      type: ERequestType.MESSAGE,
      subtype: ERequestSubtype.DASHBOARD,
      idObject: '',
      label: newMessage,
      subrequests: []
    }
    req.subrequests.push(newRequest)
    setAllRequests(allRequests.map((request) => req._id === request._id ? req : request))
    handleCancelNewMessage()
  }




  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={popoverStyle}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === "Enter" && newMessage) {
          sendNewMessage();
        }
      }}
    >
      <DialogTitle display={'flex'} justifyContent={'space-between'}>
        <Typography>Messages:</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ background: '#333' }}>
        <List sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
          {messages.map((el, index) => (
            <Box
              key={index + el._id}
              bgcolor={isSend(el) ? '#000' : '#555'}
              width={'fit-content'}
              p={.25}
              mt={hasPreviousSameSender(index) ? 0 : 1}
              sx={handleBorderRadius(index)}
              paddingX={.75}
              display={'flex'} alignItems={'center'} ml={isSend(el) ? 'auto' : 0}>
              <Typography>{el.label}</Typography>
            </Box>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, color: '#ccc', borderColor: '#ccc' }}
            value={newMessage}
            onChange={(e) => setNewmessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            placeholder="Enter new message..."
          />
          <IconButton type="button" sx={{ p: '10px' }} disabled={!newMessage} onClick={handleCancelNewMessage}>
            <Close sx={{ color: !newMessage ? '#333' : '#ccc' }} />
          </IconButton>
          <IconButton color="primary" sx={{ p: '10px' }} disabled={!newMessage} onClick={sendNewMessage}>
            <Send sx={{ color: !newMessage ? '#333' : '#ccc' }} />
          </IconButton>
        </Paper>
      </DialogActions>
    </Popover>
  );
}

export default MessagePopover;
