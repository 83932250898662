import { Grid, Tab, Tabs, } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { IQuery } from '../../../../common-interfaces/interfaces'
import AddStringSegment from './AddStringSegment'
import AddQuerySegment from './AddQuerySegment'
import { useRecoilState } from 'recoil'
import { TabPanel } from '../../workspace/EditComponentWorkspace'
import { emptyQuery } from '../../../../config/config'
import { serviceParamsAtom } from '../../../../recoil/Atom'

const HandleAddSegment: FC<{
  cb: (value: string) => void,
  editingQuery?: IQuery,
  handleDelete?: (key: string) => void
}> = ({ cb, editingQuery = emptyQuery, handleDelete }) => {
  const [tmpNewString, setTmpNewString] = useState('')
  const [query, setQuery] = useState<IQuery>({ ...editingQuery })
  const [serviceParams, setServiceParams] = useRecoilState<IQuery[]>(serviceParamsAtom)
  const [value, setValue] = React.useState(0);
  const [hasDeleteButton, setHasDeleteButton] = useState(false)

  const handleButtonChange = () => {
    setHasDeleteButton(query.key !== emptyQuery.key && query.key === editingQuery.key)
  };

  useEffect(() => {
    handleButtonChange()
  }, [editingQuery, query]);

  useEffect(() => {
    editingQuery.key !== "" && setValue(1);
    setQuery({ ...editingQuery })
  }, [editingQuery]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setQuery({ ...emptyQuery })
    setValue(newValue);
  };

  const handleAddString = () => {
    if (!tmpNewString) { return }
    cb(tmpNewString.toString())
    setTmpNewString('')
  }

  const handleAddQuery = () => {
    if (!query.key || !query.options) { return }
    cb(`@@${query.key}@@`)
    setServiceParams([...(serviceParams ?? []), query])
    setQuery({ ...emptyQuery })
  }

  const handleEditQuery = () => {
    const copiedParams: IQuery[] = [...serviceParams]
    const editedParams = copiedParams.map(p => {
      if (p.key === query.key) { return query }
      return p
    })
    setServiceParams(editedParams)
    setQuery({ ...emptyQuery })
  }

  const handleDeleteQuery = () => {
    handleDelete && handleDelete(query.key)
  }

  const handleQueryConfirm = () => {
    if (!serviceParams || !serviceParams?.find(q => q.key === query.key)) {
      handleAddQuery()
    } else {
      handleEditQuery()
    }
  }



  return (
    <Grid container width={'100%'} height={'100%'} boxSizing={'border-box'} pt={2} direction={'column'} overflow={'auto'} flexWrap={'nowrap'}>
      <Tabs value={value} onChange={handleChange}
        indicatorColor='secondary'
        textColor='secondary'
        variant='standard'
        sx={{ width: '100%', bgcolor: '#292929' }}
      >
        <Tab sx={{ minWidth: '50%', borderRight: '1px solid', borderColor: '#333 !important' }} label="Add static value" id={'add-string-0'} />
        <Tab sx={{ minWidth: '50%' }} label="Add Query" id={'add-query-1'} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AddStringSegment value={tmpNewString} handleChange={setTmpNewString} handleConfirm={handleAddString} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddQuerySegment value={query} handleChange={setQuery} handleConfirm={handleQueryConfirm} hasDeleteButton={hasDeleteButton} handleDelete={handleDeleteQuery} />
      </TabPanel>

    </Grid>
  )
}


export default HandleAddSegment