import { Button } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
} from "../../../../common-interfaces/interfaces";
import {
  selectedComponentIDsAtom,
  selectedInstallationAtom,
} from "../../../../recoil/atoms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useOnGet } from "../../../../hooks/useOnGet";
import { deepCopy } from "../../../../helpers/utils";
import { copyingComponentAtom } from "../../../../recoil/workspace";
import { COPY_SHORTCUT } from "../../../../config/shortcut";
import { enqueueSnackbar } from "notistack";

export default function HandleCopy() {
  const [selectedComponentIDs] = useRecoilState<string[]>(
    selectedComponentIDsAtom
  );
  const [selectedInstallation] = useRecoilState<IInstallation>(
    selectedInstallationAtom
  );
  const [_, setCopyingComponent] =
    useRecoilState<IComponent>(copyingComponentAtom);

  const GET = useOnGet();
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const handleKeyPress = (event: any) => {
    if (COPY_SHORTCUT(event)) {
      handleCopy();
    }
  };

  const handleCopy = async () => {
    if (selectedComponentIDs.length > 1 || !selectedInstallation)
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    let dash: IDashboard = await deepCopy(GET.dash());
    let tmpComp: IComponent = await deepCopy(
      dash.components.find((x) => x._id === selectedComponentIDs[0])
    );
    if (!tmpComp) {
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    }
    tmpComp = handleCopyComp(tmpComp);
    if (tmpComp?.components) {
      tmpComp.components = tmpComp.components.map((c) => {
        c = handleCopyComp(c);
        c.top = c.top - tmpComp.top;
        c.left = c.left - tmpComp.left;
        return c;
      });
    }
    setCopyingComponent(tmpComp);
  };

  const handleCopyComp = (comp: IComponent) => {
    //comp._id = comp.origin?._id ?? comp.origin;
    comp.masters = [];
    comp.slaves = [];
    comp = handleCopyService(comp);
    return comp;
  };
  const handleCopyService = (comp: IComponent) => {
    if (comp.services) {
      comp.services = comp.services.map((s) => {
        // s._id = s.origin._id;
        return s;
      });
    }
    return comp;
  };
  return (
    <>
      <Button
        onClick={() => handleCopy()}
        sx={{ color: "white", border: "1px solid #666" }}
        color="steelBlue"
        variant="contained"
        className="primaryButton"
      >
        <ContentCopyIcon></ContentCopyIcon>
      </Button>
    </>
  );
}
