import { useEffect } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EditComponentLeftBar from './leftBar/EditComponentLeftBar';
import EditComponentWorkspace from './workspace/EditComponentWorkspace';
import EditComponentRightBar from './rightBar/EditComponentRightBar';
import BC from '../Layout/Breadcrumbs';
import Header from '../Layout/header/Header';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import useOnInit from '../../hooks/useOnInit';
import { isLoadingAtom, selectedDashboardAtom } from '../../recoil/atoms';
import Loading from '../Loading/Loading';

const EditComponent = () => {
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [isLoading] = useRecoilState<boolean>(isLoadingAtom);

  const { componentId } = useParams();

  const onInit = useOnInit();

  useEffect(() => {
    onInit.all();
  }, []);

  useEffect(() => {
    selectedDashboard && onInit.componentSection(selectedDashboard);
  }, [componentId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box width={'100%'} height={'100%'}>
      <Header />
      <BC />
      <Box height={'var(--workspace-height)'} width={'100%'} boxSizing={'border-box'}>
        <DndProvider backend={HTML5Backend}>
          <Grid container height={'100%'} >
            <EditComponentLeftBar />
            <EditComponentWorkspace />
            <EditComponentRightBar />
          </Grid>
        </DndProvider>
      </Box>
    </Box>
  );
};

export default EditComponent;
