import { useRecoilState, useResetRecoilState } from "recoil";
import { Print, deepCopy } from "../helpers/utils";
import { IWorkspaceCell } from "../screens/EditDashboard/workspace/dnd/utils/dndUtils";
import {
  GRIDCOL_Atom,
  GRIDROW_Atom,
  busyCellsAtom,
  selectedContainerAtom,
} from "../recoil/workspace";
import { useOnGet } from "./useOnGet";
import { useOnSet } from "./useOnSet";
import {
  IComponent,
  IDashboard,
  IService,
} from "../common-interfaces/interfaces";
import { selectedDashboardAtom } from "../recoil/atoms";
import { enqueueSnackbar } from "notistack";

export function useOnDND() {
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [GRIDCOL] = useRecoilState(GRIDCOL_Atom);
  const [GRIDROW] = useRecoilState(GRIDROW_Atom);
  const [_, setBusyCells] = useRecoilState<IWorkspaceCell[]>(busyCellsAtom);
  const resetBusyCells = useResetRecoilState(busyCellsAtom);
  const [selectedContainer, setSelectedContainer] = useRecoilState(
    selectedContainerAtom
  );

  const SET = useOnSet();
  const GET = useOnGet();

  return {
    modifyCompPosition: modifyCompPosition(),
    createComponent: createComponent(),
    hasComponentCollision: hasComponentCollision,
    getBusyCells: getBusyCells,
    getAllBusyCells: getAllBusyCells,
    getComponentMaxHeight: getComponentMaxHeight,
    getComponentMaxWidth: getComponentMaxWidth,
    getWorkspaceComponent: getComp,
  };

  function getBusyCells(component: IComponent): void {
    //returns an array of bussy cells
    const hasCollision = hasComponentCollision(
      component._id,
      component.left,
      component.top
    );
    if (hasCollision) {
      return resetBusyCells();
    }
    const includedCells: IWorkspaceCell[] = getBusyByComp(component);
    setBusyCells(includedCells);
  }

  function getComponentMaxHeight(component: IComponent) {
    const W = component.width;
    let H = 1;
    const X = component.left;
    const Y = component.top;
    do {
      H++;
    } while (!testDimension(H, W, X, Y, component._id));
    return H - 1;
  }

  function getComponentMaxWidth(component: IComponent) {
    let W = 1;
    const H = component.height;
    const X = component.left;
    const Y = component.top;
    do {
      W++;
    } while (!testDimension(H, W, X, Y, component._id));
    return W - 1;
  }

  function testDimension(
    height: number,
    width: number,
    x: number,
    y: number,
    id: string
  ) {
    if (selectedDashboard.components.length === 0) {
      if (x + width > GRIDCOL) return true;
      return y + height > GRIDROW;
    }
    const checkWhere = selectedContainer
      ? selectedContainer?.components
      : selectedDashboard.components;
    return checkWhere?.some((comp) => {
      const compRight = comp.left + comp.width;
      const compBottom = comp.top + comp.height;
      const movingRight = x + width;
      const movingBottom = y + height;
      // Check for overlap in the x-axis
      if (
        x < compRight &&
        movingRight > comp.left &&
        // Check for overlap in the y-axis
        y < compBottom &&
        movingBottom > comp.top &&
        // and it's not the same
        comp._id !== id &&
        //and not inside container
        selectedContainer?._id !== comp._id
      ) {
        return true; // Collision detected
      }
      const X = x - (selectedContainer ? selectedContainer.left : 0);
      const Y = y - (selectedContainer ? selectedContainer.top : 0);
      if (X + width > GRIDCOL) return true;
      return Y + height > GRIDROW; // No collision
    });
  }

  function getBusyByComp(component: IComponent) {
    const includedCells: IWorkspaceCell[] = [];
    if (selectedContainer && component._id === selectedContainer._id) {
      return [];
    }
    for (let x = component.left; x < component.left + component.width; x++) {
      for (let y = component.top; y < component.top + component.height; y++) {
        includedCells.push({ x, y, busyByID: component._id });
      }
    }
    return includedCells;
  }

  function getAllBusyCells() {
    if (!selectedDashboard?.components) {
      return [];
    }
    let allIncludedCells = selectedDashboard?.components?.flatMap((comp) =>
      getBusyByComp(comp)
    );
    if (selectedContainer) {
      let cellInsideContainer = selectedContainer?.components?.flatMap((comp) =>
        getBusyByComp(comp)
      );
      allIncludedCells = [...allIncludedCells, ...(cellInsideContainer ?? [])];
    }
    return allIncludedCells;
  }

  function getComp(compID: string) {
    let comp = selectedDashboard?.components?.find((c) => c._id === compID);
    if (!comp) {
      comp = GET.comp(compID);
    }
    return JSON.parse(JSON.stringify(comp));
  }

  function hasComponentCollision( // return true if comp can not drop
    movingComponentID: string,
    x: number,
    y: number
  ) {
    let movingComponent = getComp(movingComponentID);
    return selectedDashboard?.components?.some((comp) => {
      if (!movingComponent) {
        return false;
      }
      const compRight = comp.left + comp.width;
      const compBottom = comp.top + comp.height;
      const movingRight = x + movingComponent.width;
      const movingBottom = y + movingComponent.height;
      // Check for overlap in the x-axis
      if (
        x < compRight &&
        movingRight > comp.left &&
        // Check for overlap in the y-axis
        y < compBottom &&
        movingBottom > comp.top &&
        // and it's not the same
        comp._id !== movingComponent._id &&
        selectedContainer?._id !== comp._id
      ) {
        return true; // Collision detected
      }
      if (!selectedContainer) {
        return checkOverFlow(movingComponent, x, y);
      }
      return false; // TODO AGGIUNGERE LOGICA
    });
  }

  function checkOverFlow(movingComponent: IComponent, x: number, y: number) {
    if (x + movingComponent.width > GRIDCOL) return true;
    return y + movingComponent.height > GRIDROW; // No collision
  }

  function modifyCompPosition(): (
    //DND handle
    compId: string,
    x: number,
    y: number
  ) => Promise<void> {
    return async (compID, x, y): Promise<void> => {
      if (hasComponentCollision(compID, x, y)) {
        return Print(["collision"]);
      }

      let tmpComp: IComponent = getComp(compID);

      if (!selectedContainer) {
        if (tmpComp.components) {
          tmpComp.components.forEach((comp) => {
            comp.left = comp.left + (x - tmpComp.left);
            comp.top = comp.top + (y - tmpComp.top);
          });
        }
        tmpComp.top = y;
        tmpComp.left = x;
      } else {
        if (!selectedContainer?.components) {
          return;
        } //controllo per ts
        let container: IComponent = await deepCopy(selectedContainer);
        let compInside = container?.components?.find(
          (x) => x._id == tmpComp._id
        );
        if (!compInside) {
          return;
        } //controllo per ts
        compInside.left = x;
        compInside.top = y;
        Print([container.components, "test container"]);
        setSelectedContainer(container);
        tmpComp = container;
      }
      await SET.comp(tmpComp);
      await SET.syncAllComp(tmpComp);
      getBusyCells(tmpComp);
      Print(["moved to -> x:", x, " y:", y, tmpComp]);
    };
  }

  function setAllServiceEditable(services: IService[]) {
    return services.map((s: IService) => {
      s.origin = s._id;
      s._id = GET.id();
      s.editable = true;
      s.brand = 'custom'
      return s;
    });
  }

  function handleDropOnContainer(tmpComp: IComponent) {
    let tempDash: IDashboard = JSON.parse(JSON.stringify(selectedDashboard));
    let containerComponent = tempDash.components.find(
      (comp) => comp._id === selectedContainer._id
    );
    if (!containerComponent) {
      console.error("something went wrong pushing component inside");
      return tempDash;
    }
    tmpComp.left = tmpComp.left;
    tmpComp.top = tmpComp.top;
    containerComponent.components = [
      ...(containerComponent?.components ?? []),
      tmpComp,
    ];
    setSelectedContainer(containerComponent);
    return tempDash;
  }

  function handleComponentCreation(comp: IComponent, isCopying?: boolean) {
    comp.origin = comp._id;
    comp._id = GET.id();
    comp.editable = true;
    if (isCopying) {
      Print([selectedDashboard.components, "test"]);
      const copyNumber = selectedDashboard.components.filter(
        (c) => c.origin === comp?.origin
      ).length;
      comp.name = copyNumber
        ? `${comp.name}_copy_${copyNumber}`
        : `${comp.name}_copy`;
    }
    if (comp.origin === comp._id) {
      enqueueSnackbar("Was not possible to create component", { variant: "error" });
      return comp;
    }
    if (comp?.services) {
      comp.services = setAllServiceEditable(comp.services);
    }
    return comp;
  }

  function createComponent(): (
    //DND handle
    compId: string,
    x: number,
    y: number,
    isCopying?: boolean
  ) => Promise<void> {
    return async (compID, x, y, isCopying): Promise<void> => {
      let tmpComp = GET.comp(compID);
      tmpComp = await deepCopy(tmpComp);
      Print(["testComp", tmpComp]);
      if (!tmpComp) return console.error("was not possibile to move comp");
      tmpComp.top = y;
      tmpComp.left = x;
      tmpComp = handleComponentCreation(tmpComp, isCopying);
      if (tmpComp?.components) {
        tmpComp.components = tmpComp.components.map((c) => {
          c.top = c.top + y;
          c.left = c.left + x;
          SET.syncAllComp(c);
          return handleComponentCreation(c);
        });
      }
      let tempDash: IDashboard;
      if (!selectedContainer) {
        tempDash = await deepCopy(selectedDashboard); /*  */
        tempDash.components.push(tmpComp);
      } else {
        tempDash = handleDropOnContainer(tmpComp);
      }
      SET.dash(tempDash);
      SET.syncAllComp(tmpComp);
      getAllBusyCells();
    };
  }
}
