import { Box, Button, Slider } from "@mui/material";
import PhoneComponentLabel from "./PhoneComponentLabel";
import { ControlSlider } from "../../../../theme";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { phoneWAtom, phoneHAtom } from "../../../../recoil/atoms";
import FaderThumbImage from "./../../../../assets/img/thumb_fader_20x10.png";
import LinearThumbImage from "./../../../../assets/img/thumb_linear_20x10.png";
import { EComponentType } from "../../../../common-interfaces/interfaces";

export default function SliderComponent(props: any) {
  const [phoneW] = useRecoilState<number>(phoneWAtom);
  const [phoneH] = useRecoilState<number>(phoneHAtom);
  const [isLandscape] = useState(phoneW > phoneH);

  const H = props.component.height;
  const W = props.component.width;
  const thumbImg =
    props.component.type === EComponentType.Slider
      ? `url(${FaderThumbImage})`
      : `url(${LinearThumbImage})`;

  const isRotated = H > W;

  return (
    <Box
      sx={{
        height: "95%",
        width: "95%",
        background: "#000",
        borderRadius: 1,
        display: "flex",
        flexDirection: isRotated ? 'column' : "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative'
      }}
      title={props?.component?.name?.toUpperCase()}
    >
      <Box
        position="absolute"
        width={'100%'}
        height="15%"
        color="#ccc"
        sx={{
          left: '15%',
          top : isRotated ? '20%' : '10%',
          transform: isRotated ? 'rotate(90deg)' : 'none',
        }}
      >
        <PhoneComponentLabel component={props.component} />
      </Box>
      <Button
        sx={{
          padding: 0,
          width: isRotated ? '100%' : '8%',
          minWidth: isRotated ? '100%' : '8%',
          height: isRotated ? '8%' : '100%',
          backgroundColor: "#333",
          borderRadius: 0,
          borderBottomLeftRadius: isRotated ? 0 : 2,
          borderTopLeftRadius: 2,
          borderTopRightRadius: isRotated ? 2 : 0,
          color: "#ccc",
        }}
      >-</Button>
      <ControlSlider
        step={1}
        min={0}
        max={100}
        orientation={isRotated ? "vertical" : "horizontal"}
        sx={{
          width: isRotated ? '10%' : '70%',
          height: isRotated ? '70%' : '10%',
          "& .MuiSlider-thumb": {
            backgroundImage: thumbImg,
          },
        }}
      />
      <Button
        sx={{
          padding: 0,
          width: isRotated ? '100%' : '8%',
          minWidth: isRotated ? '100%' : '8%',
          height: isRotated ? '8%' : '100%',
          backgroundColor: "#333",
          right: 0,
          borderRadius: 0,
          borderBottomRightRadius: 2,
          borderBottomLeftRadius: isRotated ? 2 : 0,
          borderTopRightRadius: isRotated ? 0 : 2,
          color: "#ccc",
        }}
      >+</Button>
    </Box>
  );
}
