import { Grid, Typography } from "@mui/material";
import InstallationDevices from "./InstallationDevices";
import { IDashboard } from "../../../common-interfaces/interfaces";
import { useRecoilState } from "recoil";
import { selectedDevicesAtom } from "../../../recoil/atoms";
import DashFastCreate from "./DashboardFastCreate";

const DASH: IDashboard[] = []

export default function DashCreationSideBar(props: any) {
  const [selectedDevices] = useRecoilState(selectedDevicesAtom)


  return (
    <Grid container bgcolor={'#121212'} height={'100%'} width={'100%'} direction={'column'}>
      <Grid xs={.5} item width={'100%'} padding={1} >
        <Typography className="underline-title center" fontSize={20}>
          Devices
        </Typography>
      </Grid>
      <Grid item width={'100%'} padding={2}
        borderBottom={.5} borderColor={'#777'} xs={3}
        sx={{ overflowY: 'auto' }}>
        <InstallationDevices />
      </Grid>
        <DashFastCreate />
    </Grid>
  );
}

