import { FC } from 'react'
import { EComponentType, IComponent } from '../../../common-interfaces/interfaces'
import ButtonComponent from "./components/ButtonComponent";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import MarginIcon from "@mui/icons-material/Margin";
import ButtonsArray from "./components/ButtonsArray";
import LabelComponent from "./components/LabelComponent";
import SliderComponent from "./components/SliderComponent";
import SwitchComponent from "./components/SwitchComponent";

const ComponentPreviewer: FC<{ component: IComponent }> = ({ component }) => {
  switch (component.type) {
    case EComponentType.Button:
      return <ButtonComponent component={component}></ButtonComponent>;
    case EComponentType.Switch:
      return <SwitchComponent component={component}></SwitchComponent>;
    case EComponentType.Slider:
      return <SliderComponent component={component}></SliderComponent>;
    case EComponentType.SliderLinear:
      return <SliderComponent component={component}></SliderComponent>;
    case EComponentType.Label:
      return <LabelComponent component={component}></LabelComponent>;
    case EComponentType.ButtonsArray:
      return <ButtonsArray component={component}></ButtonsArray>;
    case EComponentType.Container:
      return (
        <MarginIcon
          sx={[
            {
              background: component.backgroundColor,
              width: "100%",
              height: "100%",
            },
            {
              "& path": {
                fill: component.color,
              },
            },
          ]}
        ></MarginIcon>
      );
    default:
      return (
        <BrowserNotSupportedIcon
          sx={[
            {
              "& path": {
                fill: component.color,
              },
            },
          ]}
        ></BrowserNotSupportedIcon>
      );
  }
}
export default ComponentPreviewer