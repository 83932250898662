import { Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { PHONE_ActionBarWidth } from "../../../helpers/utils";
import { phoneWAtom, phoneHAtom } from "../../../recoil/atoms";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function HandleOpenPreview() {
  const [phoneW] = useRecoilState<number>(phoneWAtom);
  const [phoneH] = useRecoilState<number>(phoneHAtom);
  const [preview, setPreview] = useState<Window | null>();
  let { dashboardId } = useParams();

  const handleClickOpen = () => {
    if (preview && !preview.closed) {
      return preview.focus();
    }
    const openPreview = window.open(
      `${window.location.origin}/preview/${dashboardId}`,
      "_blank",
      `width=${
        phoneW + PHONE_ActionBarWidth + 20
      },height=${phoneH},top=200px,left=400px`
    );
    setPreview(openPreview);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        color="steelBlue"
        sx={{ color: "#fff !important", ml: "auto", display: "flex" }}
      >
        <SmartphoneIcon fontSize={"small"} sx={{ ml: "auto" }} />
      </Button>
    </>
  );
}
