import { Box, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil';
import { currentSelectedComponentAtom, selectedServiceAtom } from '../../../recoil/Atom';
import { useNavigate, useParams } from 'react-router-dom';
import { IComponent } from '../../../common-interfaces/interfaces';
import { selectedDashboardAtom } from '../../../recoil/atoms';

const selectStyle = { minWidth: '15%', fill: 'white', textAlign: 'center', color: '#ccc !important', fontSize: 22, letterSpacing: 1, textTransform: 'uppercase', '& .MuiSelect-icon': { fill: '#ccc' } }
const menuItemStyle = { '& .Mui-selected': { backgroundColor: '#222', color: 'var(--secondary-color)' }, '& .MuiMenuItem-root:hover': { color: '#ccc !important', backgroundColor: '#444' } }

const EditComponentNavigation = () => {
  const { installationId, dashboardId } = useParams();
  const navigator = useNavigate();

  const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState(currentSelectedComponentAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const resetSelectedService = useResetRecoilState(selectedServiceAtom)

  const [availableComps, setAvailableComps] = useState<IComponent[]>([]);

  useEffect(() => {
    selectedDashboard?.components && setAvailableComps([...selectedDashboard?.components]);
  }, [selectedDashboard]);

  const handleChangeComp = (ev: SelectChangeEvent) => {
    ev.stopPropagation();
    resetSelectedService()
    navigator(`/installation/${installationId}/dashboard/${dashboardId}/component/${ev.target.value}`);
  };

  return (
    <Box flexDirection={'row'} className='center' bgcolor={"var(--primary-color)"} border={'1px solid var(--primary_background-color)'}>
      <Typography
        className='no-underline-title'
        fontSize={22}
        color={'var(--secondary-color)'}
        mr={2}
      >Editing:</Typography>
      {(currentSelectedComponent && availableComps.length > 0) &&
        <Select
          variant="standard"
          disabled={!currentSelectedComponent}
          value={currentSelectedComponent?._id ?? undefined}
          onChange={handleChangeComp}
          sx={selectStyle}
        >
          {availableComps.map(c =>
            <MenuItem
              key={c?._id}
              value={c?._id}
              selected={c._id === currentSelectedComponent?._id}
              sx={menuItemStyle}
            >{c?.name}</MenuItem>
          )}
        </Select>
      }
    </Box >
  )
}
export default EditComponentNavigation