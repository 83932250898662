import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Box, TextField } from "@mui/material";
import { tempPlantAtom } from "../../../../recoil/CreateInstallationAtoms";
import { IPlant } from "../../../../common-interfaces/interfaces";
import { deepCopy } from "../../../../helpers/utils";

export default function ThirdStep_CreatePlant(props: any) {
  const [tempPlant, setTempPlant] = useRecoilState<IPlant>(tempPlantAtom);
  const [address, setAddress] = useState<string>();
  const [city, setCity] = useState<string>();
  const [area, setArea] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [name, setName] = useState<string>();
  const [state, setState] = useState<string>();

  useEffect(() => {
    setCity(tempPlant.city);
    setAddress(tempPlant.address);
    setArea(tempPlant.area);
    setDescription(tempPlant.description);
    setName(tempPlant.name);
    setState(tempPlant.state);
  }, [tempPlant]);

  const handleAtomModification = async (param: string, value: any) => {
    let tempCopy = await deepCopy(tempPlant);
    if (value === "") {
      value = undefined;
    }
    tempCopy[param] = value;
    setTempPlant(tempCopy);
  };

  return (
    <Box>
      <TextField
        error={address === undefined}
        label="address"
        autoComplete="true"
        variant="standard"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        onBlur={(e) => handleAtomModification("address", e.target.value)}
        sx={{
          color: "var(--white-color)",
          ml: "1.5%",
          minWidth: "45%",
          mr: "5%",
        }}
      />
      <TextField
        error={city === undefined}
        label="city"
        autoComplete="true"
        variant="standard"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        onBlur={(e) => handleAtomModification("city", e.target.value)}
        sx={{
          color: "var(--white-color)",
          ml: "1.5%",
          minWidth: "45%",
        }}
      />
      <TextField
        error={state === undefined}
        label="country"
        autoComplete="true"
        variant="standard"
        value={state}
        onChange={(e) => setState(e.target.value)}
        onBlur={(e) => handleAtomModification("state", e.target.value)}
        sx={{
          color: "var(--white-color)",
          ml: "1.5%",
          minWidth: "45%",
          mr: "5%",
        }}
      />
      <TextField
        error={area === undefined}
        label="area"
        variant="standard"
        autoComplete="true"
        value={area}
        onChange={(e) => setArea(e.target.value)}
        onBlur={(e) => handleAtomModification("area", e.target.value)}
        sx={{
          color: "var(--white-color)",
          ml: "1.5%",
          minWidth: "45%",
        }}
      />{" "}
      <TextField
        error={description === undefined}
        label="description"
        variant="standard"
        autoComplete="true"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={(e) => handleAtomModification("description", e.target.value)}
        sx={{
          color: "var(--white-color)",
          ml: "1.5%",
          minWidth: "97%",
        }}
      />
    </Box>
  );
}
