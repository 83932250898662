import { Grid, Typography, TextField, Button, InputLabel, ButtonGroup, Select, MenuItem } from '@mui/material';
import React, { Dispatch, FC, useEffect, useState } from 'react'
import { IQuery } from '../../../../common-interfaces/interfaces';
import { MuiChipsInput } from 'mui-chips-input';
import QueryValueSelect from './QueryValueSelect';
import { queryFields } from '../../../../config/config';
import { EQueryTypes } from '../../../../local-interfaces/local-interfaces';

const AddQuerySegment: FC<{
  value: IQuery,
  handleChange: Dispatch<React.SetStateAction<IQuery>>,
  handleConfirm: () => void,
  handleDelete: () => void,
  hasDeleteButton: boolean
}> = ({ value, handleChange, handleConfirm, hasDeleteButton, handleDelete }) => {

  const [query, setQuery] = useState<IQuery>(value)

  useEffect(() => {
    setQuery(value)
  }, [value]);

  useEffect(() => {
    if (query.options && query.options.length > 0) {
      const tmpQuery = { ...query, type: EQueryTypes.enum }
      handleChange(tmpQuery)
    }
  }, [query.options]);

  const handleTextFieldChange = (param: keyof IQuery, value: any) => {
    const tmpQuery = { ...query, [param]: value }
    handleChange(tmpQuery)
  }

  const handleOptsChange = async (allOpts: any) => {
    handleChange({ ...query, options: allOpts })
  }

  return (
    <Grid container
      direction={'column'}
      flexWrap={'nowrap'}
      bgcolor={'#333'}
      pt={5}
      height={'100%'}
      width={'100%'}
      boxSizing={'border-box'}
    >
      <Grid container item xs={1} width={'100%'} alignItems={'center'} >
        <Typography>Add param segments</Typography>
      </Grid>
      <Grid container item xs={11} width={'100%'} alignItems={'center'} direction={'column'}>
        <Grid xs={3}
          direction={'row'}
          container
          item
          width={'100%'}>
          <Grid container item xs={12} direction={'row'}>
            {queryFields.map(field =>
              <Grid item container xs={6} boxSizing={'border-box'} justifyContent={'center'} flexWrap={'wrap'} key={field.name}>
                <TextField
                  inputProps={{ p: '5%' }}
                  type={field.type}
                  sx={{
                    width: '90%',
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  value={query[field.name as keyof typeof query]}
                  onChange={(ev) => handleTextFieldChange(field.name, ev.target.value)}
                  label={field.name}
                  variant='standard'
                  className='number-input'
                  error={!query[field.name as keyof typeof query] && field.mandatory}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid xs={7}
          container item
          direction={'column'}
          flexWrap={'nowrap'}
          overflow={'auto'}
          boxSizing={'border-box'}
          justifyContent={'space-around'}
        >
          <MuiChipsInput
            disabled={!query.key}
            variant='standard'
            value={query.options ?? []}
            label={'Options:'}
            onChange={(e) => handleOptsChange(e)}
            onKeyDown={(e: any) => {
              e.stopPropagation()
            }}
            sx={{
              "& .MuiInputLabel-root": {
                color: "#ccc"
              }
            }}
          />

          <Grid container item xs={2} mt={1} justifyContent={'space-between'}>
            <Grid item xs={6}>
              <InputLabel id="value-label">Value</InputLabel>
              <Select
                variant="standard"
                value={query.type}
                onChange={(e) => handleTextFieldChange('type', e.target.value)}
                // LA SELECT DEVE ESSERE DI TIPO ENUM SE SOLO SE SONO PRESENTI DELLE OPTIONS
                error={!query.type || (query.options && query.options.length === 0 && query.type === EQueryTypes.enum)}
                disabled={query.options && query.options.length > 0}
                //  onBlur={() => dispatchChange(tmpBody)}
                sx={{
                  fill: '#ccc',
                  width: '95%',
                  textAlign: 'center',
                  color: '#ccc !important',
                  letterSpacing: 1,
                  textTransform: 'uppercase',
                  '& .MuiSelect-icon': {
                    fill: '#ccc'
                  }
                }}
              >
                {Object.keys(EQueryTypes).map(key => (
                  <MenuItem
                    key={key}
                    value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="value-label">Value</InputLabel>
              <QueryValueSelect query={query} cb={handleChange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={2}
          container
          item
          width={'100%'}
          alignItems={'flex-end'}
        >
          <ButtonGroup variant='contained' >
            <Button sx={{ bgcolor: '#222', width: '150px' }} onClick={handleConfirm}>{hasDeleteButton ? 'Edit' : 'Add'}</Button>
            {hasDeleteButton && <Button sx={{ bgcolor: '#222', width: '150px' }} onClick={handleDelete}>Delete</Button>}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AddQuerySegment