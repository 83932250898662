import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isMobileAtom } from "../recoil/atoms";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useIsMobile() {
  const [_, setIsMobile] = useRecoilState(isMobileAtom);

  useEffect(() => {
    function handleResize() {
      const { width, height } = getWindowDimensions();
      setIsMobile(width < 700 || height < 500);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
}
