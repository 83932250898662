import React, { FC, useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import DeleteSegmentHandler from './DeleteSegmentHandler';

const textfieldStyle = { width: '100%', color: 'var(--white-color)' }

const HandleStringSegment: FC<{
  segment: string,
  index: number,
  cb: React.Dispatch<React.SetStateAction<(string)[]>>
  divider: string
}> = ({ segment, index, cb, divider }) => {

  const [epSegment, setEpSegment] = useState<string>(segment);

  useEffect(() => {
    setEpSegment(segment);
  }, [segment]);

  const handleChange = (ev: any) => setEpSegment(ev.target.value)

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleBlur();
    }
  }

  const handleBlur = () => {
    cb((prevValue) => {
      const updatedValue = [...prevValue];
      if (!epSegment)
        updatedValue.splice(index, 1)
      else
        updatedValue[index] = epSegment;
      return updatedValue;
    });
  };

  const handleDelete = () => {
    cb(prevValue => {
      const updatedValue = [...prevValue];
      updatedValue.splice(index, 1)
      return updatedValue;
    });
  }

  const inputPropsStyle = {
    endAdornment: (
      <InputAdornment position="end">
        <DeleteSegmentHandler handleDelete={handleDelete} />
      </InputAdornment>
    ),
  }

  return (
    <Grid container item xs={3} height={'100%'} justifyContent={'start'} alignItems={'center'} flexWrap={'nowrap'}>
      <Grid item xs={11}>
        <TextField
          variant='standard'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          sx={textfieldStyle}
          value={epSegment}
          InputProps={inputPropsStyle}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography mx={0.25}>{divider}</Typography>
      </Grid>
    </Grid>
  );
};

export default HandleStringSegment;
