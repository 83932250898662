import { Box } from "@mui/material";
import ReactLoading from "react-loading";
import "../../styles/Loading.css";

const Loading = () => {
  return (
    <Box className="mainContainer">
      <Box>
        <ReactLoading
          type={"spin"}
          color={"#fff"}
          height={"100%"}
          width={"9vw"}
          className=" loading"
        />
      </Box>
    </Box>
  );
};
export default Loading;
