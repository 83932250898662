import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CentralBox from "./centralBox/CentralBox";
import { IInstallation } from "../../common-interfaces/interfaces";
import { useRecoilState } from "recoil";
import {
  isLoadingAtom,
  selectedInstallationAtom,
} from "../../recoil/atoms";
import useOnInit from "../../hooks/useOnInit";
import useIsMobile from "../../hooks/useIsMobile";
import { useOnUser } from "../../hooks/useOnUser";
import InstallationSideBar from "./installationSideBar/InstallationSideBar";
import DashCreationSideBar from "./dashboardFastCreationSideBar/DashboardFastCreationSideBar";
import Loading from "../Loading/Loading";
import Header from "../Layout/header/Header";

const Configuration = () => {
  const [filteredDashboards, setFilteredDashboards] = useState<any[]>([]);
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [isLoading] = useRecoilState<boolean>(isLoadingAtom);
  const onInit = useOnInit();
  const onUser = useOnUser();

  useIsMobile();

  const handleSearch = (event: any) => {
    if (!selectedInstallation) return;
    event.preventDefault();
    let filter = event.target.value;
    let filtered = selectedInstallation.configuration.dashboards.filter(
      (dash) => {
        return dash.name.toUpperCase().includes(filter.toUpperCase());
      }
    );
    setFilteredDashboards(filtered);
  };

  useEffect(() => {
    if (selectedInstallation && selectedInstallation.configuration)
      setFilteredDashboards(selectedInstallation.configuration?.dashboards);
    else {
      let init = onInit.all();
      if (!init) onUser.logout();
    }
  }, [selectedInstallation]);
  if (isLoading) return <Loading></Loading>;
  return (
    <Box
      className="homeWrapper"
      sx={{ overflow: "auto", background: "#212121" }}
    >
      <Header />
      <Grid
        sx={{ height: "calc(100% - var(--header-heigth)  - 2px)" }} //2px padding
        container
        spacing={0}
      >
        <Grid item xs={12} md={2.5} height={"100%"}>
          <DashCreationSideBar />
        </Grid>
        <Grid
          item
          xs={12}
          md={7.5}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            overflowY: "auto",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          <CentralBox
            dashboards={filteredDashboards}
            setFilteredDashboards={setFilteredDashboards}
          ></CentralBox>
        </Grid>
        <Grid item xs={12} md={2} height={"100%"}>
          <InstallationSideBar setFilter={handleSearch} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Configuration;
