import { atom, DefaultValue } from "recoil";
import {
  IComponent,
  IDashboard,
  IDevice,
  IInstallation,
  IPlantFull,
  IPlantUser,
  IService,
} from "../common-interfaces/interfaces";
import { localStorageEffect } from "./effects";
import {
  IInstallationRow,
  ILoggedUser,
} from "../local-interfaces/local-interfaces";
import { PHONE_ActionBarWidth } from "../helpers/utils";
import { IProduct } from "../common-interfaces/indexInterfaces";

/******** USER-ATOMs ********/

export const loggedUserAtom = atom<ILoggedUser | undefined>({
  key: "loggedUser",
  default: undefined,
  effects: [localStorageEffect("logged_user")],
});
export const isVerboseAtom = atom<boolean>({
  key: "isVerbose",
  default: false,
  effects: [localStorageEffect("isVerbose")],
});

/******** ALL-ATOMs ********/

const logAtomChange = (oldValue: IPlantFull[] | DefaultValue, newValue: IPlantFull[]) => {
  console.log("Old Value: ", oldValue);
  console.log("New Value: ", newValue);
};

export const allPlantsFullAtom = atom<IPlantFull[]>({
  key: 'allPlantsFull',
  default: undefined,
  // effects_UNSTABLE: [
  //   ({ onSet, trigger, setSelf }) => {
  //     onSet((newValue, oldValue) => {
  //       logAtomChange(oldValue, newValue);
  //     });
  //   },
  // ],
});
export const allUsersAtom = atom<IPlantUser[]>({
  key: "allUsers",
  default: undefined,
});

export const allComponentsAtom = atom<IComponent[]>({
  key: "allComponents",
  default: undefined,
});
export const allModelsAtom = atom<IProduct[]>({
  key: "allModels",
  default: undefined,
});

/******** SELECTED-ATOMs ********/

export const selectedInstallationAtom = atom<IInstallation>({
  key: "selectedPlant",
  default: undefined,
});
export const selectedDashboardAtom = atom<IDashboard>({
  key: "selectedDashboard",
  default: undefined,
});
export const selectedComponentIDsAtom = atom<string[]>({
  key: "selectedComponentIDs",
  default: [],
});
export const selectedDevicesAtom = atom<IDevice[]>({
  key: 'selectedDevices',
  default: []
})

/******** UTILS-ATOMs ********/

export const isLoadingAtom = atom<boolean>({
  key: "isLoading",
  default: true,
});
export const isLinkingAtom = atom<boolean>({
  key: "isLinking",
  default: false,
});
export const isMobileAtom = atom<boolean>({
  key: "isMobile",
  default: false,
});
export const zoomAtom = atom<number>({
  key: "zoom",
  default: 1,
});
export const workspaceWidthAtom = atom<number>({
  key: "workspaceWidth",
  default: 900,
});
export const leftWidthAtom = atom<number>({
  key: "leftWidth",
  default: 220,
});
export const rightWidthAtom = atom<number>({
  key: "rightWidth",
  default: 500,
});
export const phoneWAtom = atom<number>({
  key: "phoneW",
  default: 390,
});
export const phoneHAtom = atom<number>({
  key: "phoneH",
  default: 844,
});

export const phoneActionBarWidthAtom = atom<number>({
  key: "phoneActionBarWidth",
  default: PHONE_ActionBarWidth,
});

export const openPreviewAtom = atom<boolean>({
  key: "opernpreview",
  default: false,
});

export const previousRouteAtom = atom<string>({
  key: 'previousRoute',
  default: '/'
})
/******** INSTALLATION-ATOMs ********/

export const filteredInstallationsAtom = atom<IInstallationRow[]>({
  key: "filteredInstallations",
  default: [],
});
