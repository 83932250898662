import { DialogTitle, DialogContent, Grid, DialogActions, Button, Box, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { IQuery } from '../../../../common-interfaces/interfaces';
import { ControlWhiteDialog } from '../../../../theme';
import { serviceParamsAtom } from '../../../../recoil/Atom';
import HandleAddSegment from '../common/HandleAddSegment';
import HandleRowBodyValue from './HandleRowBodyValue';
import { isParamSegment, removeParamIdentifier } from '../common/utils';
import { IBodyRow } from '../../../../local-interfaces/local-interfaces';

const BodyRawDialog: FC<{
  open: boolean,
  editingRow: IBodyRow,
  setEditingRow: Dispatch<React.SetStateAction<IBodyRow>>,
  handleDialogClose: () => void

}> = ({ open, editingRow, setEditingRow, handleDialogClose }) => {
  const [serviceParams] = useRecoilState<IQuery[]>(serviceParamsAtom);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(open)
  const [value, setValue] = useState<string>('')
  const [editingQuery, setEditingQuery] = useState<IQuery>();
  const [row, setRow] = useState<IBodyRow>(editingRow)

  useEffect(() => {
    setRow(editingRow)
    setValue(editingRow.value)
  }, [editingRow]);

  useEffect(() => {
    setIsDialogOpen(open)
  }, [open]);

  useEffect(() => {
    isParamSegment(value) && setEditingQuery(serviceParams.find(v => v.key === removeParamIdentifier(value)))
  }, [value, serviceParams]);


  const handleDispatch = (newValue: string) => {
    setEditingRow(prevValue => {
      return {
        ...prevValue,
        type: 'param',
        value: newValue,
        hasParam: isParamSegment(newValue),
        query: serviceParams.find(d => d.key === removeParamIdentifier(newValue))
      }
    })
    setValue(newValue)
  }

  const handleDoubleClickOnQuery = (query: IQuery) => {
    setEditingQuery({ ...query });
  };


  const handleChange = (param: keyof IBodyRow, newValue: string) => {
    setEditingRow(prevValue => {
      return {
        ...prevValue,
        [param]: newValue
      }
    })
  }

  return (
    <ControlWhiteDialog
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      onClose={handleDialogClose}
      PaperProps={{ sx: { height: '850px' } }}
    >
      <DialogTitle>ADD BODY ROW</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'} sx={{ height: '100%', width: '100%' }} overflow="auto" px={1} borderRadius={2} boxSizing="border-box">
          <Grid item sx={{ bgcolor: '#383838' }} alignItems={'center'} display={'flex'} xs={2} px={2} boxSizing="border-box">
            <Box display={'flex'} alignItems={'flex-end'} width={'100%'}>
              <Typography fontSize={25}>"</Typography>
              <TextField
                inputProps={{ min: 0, style: { textAlign: 'center', fontSize: 25 } }}
                sx={{ mx: 1, width: '45%' }}
                variant='standard'
                placeholder={'Add row key'}
                value={row.key}
                onChange={(ev) => handleChange('key', ev.target.value)}
              />
              <Typography fontSize={25}>"</Typography>
              <Typography fontSize={25} mx={2}>:</Typography>
              <HandleRowBodyValue dispatchChange={handleDispatch} handleDoubleClickOnQuery={handleDoubleClickOnQuery} propsValue={value} />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <HandleAddSegment cb={handleDispatch} editingQuery={editingQuery} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Close</Button>
      </DialogActions>
    </ControlWhiteDialog>
  )
}

export default BodyRawDialog