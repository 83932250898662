import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import {
  creatingPlantAtom,
  wizardSelectedPlantAtom,
} from "../../../../recoil/CreateInstallationAtoms";
import { IPlantFull } from "../../../../common-interfaces/interfaces";
import { Print, deepCopy } from "../../../../helpers/utils";
import { allPlantsFullAtom } from "../../../../recoil/atoms";

export default function FirstStep_SelectPlant() {
  const [allPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom);
  const [plantsWithoutInstallation, setPlantsWithoutInstallation] =
    useState<IPlantFull[]>();
  const [selectedPlant, setSelectedPlant] = useRecoilState<
    IPlantFull | undefined
  >(wizardSelectedPlantAtom);
  const [creatingPlant, setCreatingPlant] =
    useRecoilState<boolean>(creatingPlantAtom);

  const filterPlant = async () => {
    let tempPlants = await deepCopy(
      allPlantsFull.filter((pf) => !pf.installationName)
    );
    setPlantsWithoutInstallation(tempPlants);
  };

  useEffect(() => {
    filterPlant();
  }, []);

  useEffect(() => {
    Print([selectedPlant]);
  }, [selectedPlant]);
  const handleSelect = (plant: SelectChangeEvent<any>) => {
    const plantName = plant.target.value as string; //aggiunto per ts
    if (plantName === "CREATE") {
      setSelectedPlant(undefined);
      setCreatingPlant(true);
      return;
    }
    const tempSelectedPlant = allPlantsFull.find(
      (pl) => pl.plant.name === plantName
    );
    if (tempSelectedPlant) {
      setCreatingPlant(false);
      setSelectedPlant(tempSelectedPlant);
    }
  };
  return (
    <Box
      sx={{
        w: "100%",
        mt: 4,
      }}
    >
      <Box sx={{ minWidth: 120 }}>
        <FormControl>
          <InputLabel>Select Plant</InputLabel>
          <Select
            label="Select Plant"
            variant="standard"
            sx={{ ml: 3, maxWidth: "400px", minWidth: "400px" }}
            disabled={allPlantsFull?.length === 0}
            onChange={(e) => handleSelect(e)}
            value={selectedPlant?.plant.name ?? null}
          >
            {plantsWithoutInstallation &&
              plantsWithoutInstallation.map((plant) => {
                return (
                  <MenuItem key={plant.plant.id} value={plant.plant.name}>
                    {plant.plant.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
