import { atom } from "recoil";
import { emptyQueries, IAdminToolQuery, IAdminToolFilterBy, IAdminToolStore } from "./adminToolConfig";

export const adminToolSearchQueryAtom = atom<IAdminToolQuery>({
  key: 'adminToolSearchQuery',
  default: undefined
})
export const adminToolFilterByAtom = atom<IAdminToolFilterBy>({
  key: 'adminToolFilterBy',
  default: undefined
})
export const adminToolStoreAtom = atom<IAdminToolStore>({
  key: 'adminToolStore',
  default: undefined
})