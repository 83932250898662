import parse from 'html-react-parser';
import { DocsTreeNode } from '../../local-interfaces/docs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { docsTreeAtom } from './common/DocsAtom';
import { DOCS_HOME } from '../../config/docs';

const DocsContainer = () => {
    const { docsSearchTerm, nodeId } = useParams()
    const [docsTree] = useRecoilState(docsTreeAtom)
    const [page, setPage] = useState(DOCS_HOME)

    const recTreeFind = (tree: DocsTreeNode[]): void => {
        tree.forEach(node => {
            if (node.id === nodeId)
                setPage(node)
            else recTreeFind(node.subcontent ?? [])
        })
    }

    useEffect(() => {
        if (docsSearchTerm !== "home")
            recTreeFind(docsTree)
    }, [docsSearchTerm, nodeId])

    return (
        <>
            {/* first level */}
            <h1>{page.title}</h1>
            {parse(page.content)}
            {/* second level */}
            {/* {
                docsNode.subcontent?.map(child => {
                    return (
                        <>
                            <h2>{child.title}</h2>
                            {parse(child.content)}
                        </>)
                })
            } */}
        </>
    )
};

export default DocsContainer;