import { Box, Grid, Stack } from "@mui/material";
import Header from "../Layout/header/Header";
import RequestSidebar from "./RequestSidebar";
import RequestWorkspace from "./RequestWorkspace";

const Notifications = () => {

  return <Stack height={"100%"} width={'100%'}>
    <Box height={"var(--header-heigth)"}>
      <Header />
    </Box>
    <Grid container width="100%" height={'calc(100% - var(--header-heigth))'} direction={'row'}>
      <RequestSidebar />
      <Grid item xs={11.85} md={10} height={'100%'}>
        <RequestWorkspace />
      </Grid>
    </Grid>
  </Stack>
}
export default Notifications