import { Box, Button, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { EComponentType, IComponent } from "../../../common-interfaces/interfaces";
import "../../../styles/EditdashboardLeftBar.css";
import DndComponent from "./DndComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRecoilState, useResetRecoilState } from "recoil";
import { allComponentsAtom, leftWidthAtom, loggedUserAtom } from "../../../recoil/atoms";
import { useDroppable } from "@dnd-kit/core";
import Loading from "../../Loading/Loading";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Print } from "../../../helpers/utils";

const paperStyle = { borderRadius: "0", border: "none" }
const buttonStyle = { height: "80px", margin: "auto 0", borderRadius: "5px !important", opacity: "0.5" }
const idObject = { id: "leftBar" }

const EditDashboardLeftBar = () => {
  const { setNodeRef } = useDroppable(idObject);

  const [allComponents, setAllComponents] = useRecoilState<IComponent[]>(allComponentsAtom);
  const [leftWidth, setLeftWidth] = useRecoilState(leftWidthAtom);
  const [logged_user] = useRecoilState(loggedUserAtom);

  const [customComps, setCustomComps] = useState<IComponent[]>();
  const [accordionExpanded, setAccordionExpanded] = useState<number>(-1)

  useEffect(() => {
    let tmp = allComponents?.filter(component =>
      component.brand === "custom" &&
      !component.editable &&
      component.creator?.find(creator => creator.username === logged_user?.username)
    );
    setCustomComps(tmp);
  }, [allComponents]);


  const reset = useResetRecoilState(leftWidthAtom);
  const handleLeftBar = () => {
    leftWidth ? setLeftWidth(0) : reset();
    const timeout = setTimeout(() => {
      const resizeEvent = new Event("resize");
      window.dispatchEvent(resizeEvent);
      Print(["resizeEvent", resizeEvent]);
      clearTimeout(timeout)
    }, 10)
  };

  const handleChange = (expanded: boolean, value: number) => {
    setAccordionExpanded(expanded ? value : -1)
  }
  const accordionChanges = [
    (_: any, expanded: boolean) => handleChange(expanded, 0),
    (_: any, expanded: boolean) => handleChange(expanded, 1),
    (_: any, expanded: boolean) => handleChange(expanded, 2)
  ]

  if (!allComponents) return <Loading></Loading>;
  return (
    <Paper elevation={3} className="editDashboardLeftWrapper" ref={setNodeRef} sx={paperStyle}>
      <Box width="100%" height="100%" display="flex">
        {/* components */}
        <Box height="100%" display={leftWidth ? "initial" : "none"} overflow={`${leftWidth ? "auto" : "unset"}`}>
          <Accordion square={true} expanded={accordionExpanded === 0} onChange={accordionChanges[0]}>
            <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>K-ARRAY COMPONENTS</Typography></AccordionSummary>
            <AccordionDetails className="accordionDetails">
              {allComponents.map(component =>
                ((component.brand === "k-array") && (!component.disabled) && (component.editable === false)) && <DndComponent key={component._id} component={component} />)}
            </AccordionDetails>
          </Accordion>
          {customComps?.length && <Accordion square={true} expanded={accordionExpanded === 1} onChange={accordionChanges[1]}>
            <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>CUSTOM COMPONENTS</Typography></AccordionSummary>
            <AccordionDetails className="accordionDetails">
              {customComps.map(component => <DndComponent key={component._id} component={component} setComps={setAllComponents} />)}
            </AccordionDetails>
          </Accordion>
          }
          <Accordion square={true} expanded={accordionExpanded === 2} onChange={accordionChanges[2]}>
            <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>SIMPLE COMPONENTS</Typography></AccordionSummary>
            <AccordionDetails className="accordionDetails">
              {allComponents.map(component =>
                ((component.brand === "simple") && component.type !== EComponentType.Container && (!component.editable)) && <DndComponent key={component._id} component={component} />)}
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* tendina */}
        <Box width={"20px"} height="100%" display={"flex"} borderLeft={leftWidth ? "3px solid var(--grey-color)" : "none"} borderRight={leftWidth ? "none" : "3px solid var(--grey-color)"}>
          <Button className="sidebarHandler" onClick={handleLeftBar} sx={buttonStyle}>
            {leftWidth ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Button>
        </Box>
      </Box>
    </Paper >
  );
}

export default EditDashboardLeftBar