import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
} from "../../../../common-interfaces/interfaces";
import {
  allComponentsAtom,
  selectedComponentIDsAtom,
  selectedInstallationAtom,
} from "../../../../recoil/atoms";
import Draggable from "react-draggable";
import { useOnGet } from "../../../../hooks/useOnGet";
import { Print, deepCopy } from "../../../../helpers/utils";
import { useApiWrapper } from "../../../../hooks/useApiWrapper";
import { ControlWhiteDialog } from "../../../../theme";
import BackupIcon from "@mui/icons-material/Backup";
import { enqueueSnackbar } from "notistack";

export default function HandleAdminSave(props: any) {
  const [selectedComponentIDs] = useRecoilState<string[]>(
    selectedComponentIDsAtom
  );
  const [selectedInstallation] = useRecoilState<IInstallation>(
    selectedInstallationAtom
  );
  const [allComponents, setAllComponents] =
    useRecoilState<IComponent[]>(allComponentsAtom);
  const apiWrapper = useApiWrapper();

  const [open, setOpen] = useState(false);
  const GET = useOnGet();

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const copyComponent = async (component: any) => {
    if (!component) return;
    //component creation
    const url = `api/component`;
    Print(["sending this component to post ", component]);
    let newComponent = await apiWrapper.postWithAuth(url, component);
    Print(["returned this component from post", newComponent]);
    return !newComponent ? undefined : newComponent;
  };

  const handleSaveConfirm = async () => {
    handleDialogClose();
    if (selectedComponentIDs.length > 1 || !selectedInstallation) {
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    }
    let dash: IDashboard = await deepCopy(GET.dash());
    let tmpComp: IComponent = await deepCopy(
      dash.components.find((x) => x._id === selectedComponentIDs[0])
    );
    if (!tmpComp) {
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    }
    tmpComp._id = GET.id();
    tmpComp.brand = "k-array";
    tmpComp.masters = [];
    tmpComp.slaves = [];
    delete tmpComp.origin;
    tmpComp.creator = [selectedInstallation.installer];
    tmpComp.editable = false;
    if (tmpComp.services) {
      tmpComp.services = tmpComp.services.map((s) => {
        s._id = GET.id();
        s.editable = false;
        s.brand = "k-array"
        delete s.origin;
        return s;
      });
    }
    await copyComponent(tmpComp);
    if (allComponents) setAllComponents([...allComponents, tmpComp]);
  };

  return (
    <>
      <Button
        onClick={() => handleDialogOpen()}
        sx={{ color: "white" }}
        color="steelBlue"
        variant="outlined"
        className="primaryButton"
      >
        <BackupIcon sx={{ color: "var(--secondary-color)" }}></BackupIcon>
      </Button>
      {/* DIALOG FOR DELETE */}
      <ControlWhiteDialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        onKeyDown={(e) => {
          // catch enter key for
          Print([e.key]);
          if (e.key === "Enter") {
            handleSaveConfirm();
          }
        }}
      >
        <DialogTitle>ADMIN_SAVE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save selected component as a K-ARRAY
            component?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {selectedComponentIDs.length > 0 ? (
            <Button onClick={handleSaveConfirm}>Save</Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
