import { useDroppable } from "@dnd-kit/core";
import { Grid } from "@mui/material";
import "../../../styles/Workspace.css";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { workspaceWidthAtom, leftWidthAtom, rightWidthAtom, selectedDashboardAtom, selectedComponentIDsAtom } from "../../../recoil/atoms";
import Workspace from "./Workspace";
import { useOnGet } from "../../../hooks/useOnGet";
import WorkspaceSelector from "./WorkspaceSelector";
import { isSelectingAtom } from "../../../recoil/workspace";
import { Print } from "../../../helpers/utils";

const WorkspaceContainer = () => {
  const GET = useOnGet();
  const workSpaceMainContainer = useRef<any>(null);

  const [workspaceWidth] = useRecoilState(workspaceWidthAtom);
  const [leftWidth] = useRecoilState(leftWidthAtom);
  const [rightWidth] = useRecoilState(rightWidthAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [isSelecting] = useRecoilState(isSelectingAtom);
  const unSelectComponents = useResetRecoilState(selectedComponentIDsAtom);

  const [width, setWidth] = useState<number | undefined>();

  const { node } = useDroppable({ id: "workspaceContainer" });

  useEffect(() => {
    setWidth(node.current?.offsetWidth);
  }, [node.current?.offsetWidth]);

  const handleClickOnWorkspace = () => {
    if (isSelecting)
      return;
    unSelectComponents();
    let dash = JSON.parse(JSON.stringify(GET.dash()));
    Print([
      "%c >>> Selected Dashbaord Components:",
      "background: #445FF1; color: #fff",
      selectedDashboard?.components,
      dash.components,
    ]);
  };

  return (
    <Grid
      ref={workSpaceMainContainer}
      width={`calc(100% - ${leftWidth + rightWidth + 40}px)`}
      height="100%"
      container
      alignItems={width && width < workspaceWidth ? "flex-start" : "center"}
      overflow='hidden'
      position={"relative"}
      id="workspace-container"
      onClick={handleClickOnWorkspace}
    >
      <WorkspaceSelector />
      <Workspace />
    </Grid >
  );
}

export default WorkspaceContainer