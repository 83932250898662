import { IComponent } from "../common-interfaces/interfaces";
import { useOnGet } from "./useOnGet";

export function usePosition() {
  const GET = useOnGet();
  const selectedDashboard = GET.dash();

  return {
    position: findMyPlace(),
    checkContainer: checkContainer(),
  };
  //TODO RIMUOVERE
  function checkOverlap(
    left: number,
    top: number,
    height: number,
    width: number
  ) {
    return selectedDashboard?.components.find((comp) => {
      const isOverlappingY =
        top + height > comp.top && top < comp.top + comp.height;
      const isOverlappingX =
        (left + comp.width > comp.left && left < comp.left + comp.width) ||
        left + width > 12;
      return isOverlappingX && isOverlappingY;
    });
  }

  function findMyPlace(): (
    h: number,
    w: number
  ) => { top: number; left: number } {
    return (h, w): { top: number; left: number } => {
      let top = 0;
      let left = 0;

      while (checkOverlap(left, top, h, w)) {
        if (left === 11) {
          left = 0;
          top++;
        } else {
          left++;
        }
      }
      return {
        top,
        left,
      };
    };
  }

  function checkContainerOverlap(
    left: number,
    top: number,
    height: number,
    width: number,
    container: IComponent
  ) {
    if (!container?.components) {
      return;
    }
    return container?.components.find((comp) => {
      const isOverlappingY =
        top + height > comp.top && top < comp.top + comp.height;
      const isOverlappingX =
        left + width > comp.left && left < comp.left + comp.width;
      return isOverlappingX && isOverlappingY;
    });
  }

  function checkContainer(): (
    h: number,
    w: number,
    container: IComponent
  ) => { top: number; left: number } {
    return (h, w, container): { top: number; left: number } => {
      let top = 0;
      let left = 0;

      while (checkContainerOverlap(left, top, h, w, container)) {
        if (left === container.left) {
          left = 0;
          top++;
        } else {
          left++;
        }
      }
      return {
        top,
        left,
      };
    };
  }
}
