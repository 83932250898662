import { IconButton } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import { FC } from "react";

const iconButtonStyle = { p: 0, mx: 'auto', width: '100%', backgroundColor: '#ccc' }
const clearIconStyle = { '& > *': { fill: '#000 !important' }, fontSize: '17px' }

const DeleteSegmentHandler: FC<{
    handleDelete: () => void
}> = ({ handleDelete }) => {
    return (
        <IconButton onClick={handleDelete} sx={iconButtonStyle}>
            <ClearIcon color='primary'
                sx={clearIconStyle} />
        </IconButton>
    )
}

export default DeleteSegmentHandler