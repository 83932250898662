import React, { useEffect, useState } from 'react';
import { List, Popover, Typography, DialogTitle, DialogContent, Stack, Button, PopoverProps, DialogActions, Box, Grid, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import { previousRouteAtom } from '../../../recoil/atoms';
import { allRequestsAtom } from '../utils/requestAtoms';
import RequestActions from '../actions/RequestActions';
import { deepCopy } from '../../../helpers/utils';
import { INotificationRequest, ERequestStatus } from '../../../common-interfaces/interfaces';
import useRequestNotificationUtils from '../hooks/useRequestNotificationUtils';

const popoverStyle = { "& .MuiPaper-root": { backgroundColor: 'var(--primary-color)', color: 'white', borderColor: 'white', width: '550px' } }

const NotificationsPopover: React.FC<PopoverProps> = ({ open, anchorEl, onClose }) => {
  const [, setPreviousRoute] = useRecoilState(previousRouteAtom);
  const [allRequests, setAllRequests] = useRecoilState(allRequestsAtom);
  const [pendingReq, setPendingReq] = useState<INotificationRequest[]>([]);

  const { getPendingRequestRecived, installerEmail } = useRequestNotificationUtils()
  const navigate = useNavigate();
  const currentRoute = useLocation();

  useEffect(() => {
    if (allRequests && installerEmail) {
      const pendinRequests = getPendingRequestRecived()
      setPendingReq(pendinRequests);
    }
  }, [allRequests, installerEmail]);


  const handleRequestScreenNavigation = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setPreviousRoute(currentRoute.pathname);
    navigate("/requests");
  };
  const close = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };
  const handleAll = async (val: ERequestStatus) => {
    const tmpReq: INotificationRequest[] = await deepCopy(allRequests)
    setAllRequests(tmpReq.map(req => {
      if (pendingReq.find(r => req._id === r._id)) req.status = val
      return req
    }))
    close()
  }
  const declineAll = () => {
    handleAll(ERequestStatus.REFUSED)
  }
  const acceptALL = () => {
    handleAll(ERequestStatus.ACCEPTED)
  }


  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={popoverStyle}
    >
      <DialogTitle display={'flex'} justifyContent={'space-between'}>
        <Typography>Pending Requests ({pendingReq.length})</Typography>
        <Button color='secondary' size='small' variant='text' onClick={handleRequestScreenNavigation}>VIEW ALL REQUEST</Button>
      </DialogTitle>
      <DialogContent dividers sx={{ background: '#333' }}>
        {pendingReq.length > 0 ? (
          <List sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {pendingReq.map((el, index) => (
              <Grid
                container
                width={'95%'}
                height={'40px'}
                padding={0}
                borderBottom={'1px solid #ccc'}
                alignItems={'center'}
                key={index}
              >
                <Tooltip title={`from ${el.from}`}>
                  <Grid item xs={9.25} style={{ cursor: 'context-menu' }}><Typography key={index}>{el.label}</Typography></Grid>
                </Tooltip>
                <Grid item xs={2}><RequestActions request={el} /></Grid>
              </Grid>
            )
            )}
          </List>
        ) : (
          <Stack direction='row' columnGap={2} alignItems='center' alignContent='center'>
            <NotificationsIcon color='secondary' fontSize='large' />
            <Stack direction='column'>
              <Typography variant='h6' color={'secondary'}>There are no notifications</Typography>
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        {pendingReq.length > 0 && (
          <>
            <Button color='success' size='small' variant='text' onClick={acceptALL}>ACCEPT ALL</Button>
            <Button color='error' size='small' variant='text' onClick={declineAll}>DECLINE ALL</Button>
          </>
        )}
      </DialogActions>
    </Popover>
  );
}

export default NotificationsPopover;
