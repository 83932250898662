import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, PaperProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { IComponent, IDashboard } from "../../../../common-interfaces/interfaces";
import { isLoadingAtom, selectedComponentIDsAtom } from "../../../../recoil/atoms";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Draggable from "react-draggable";
import { useOnGet } from "../../../../hooks/useOnGet";
import { deepCopy } from "../../../../helpers/utils";
import { useOnSet } from "../../../../hooks/useOnSet";
import { ControlWhiteDialog } from "../../../../theme";
import { useOnDND } from "../../../../hooks/useOnDND";
import { DELETE_SHORTCUT } from "../../../../config/shortcut";

const HandleDelete = () => {
  const GET = useOnGet();
  const SET = useOnSet();
  const DND = useOnDND();

  const [selectedComponentIDs, setSelectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [_, setIsLoading] = useRecoilState(isLoadingAtom);
  // const [containersArray, setContainersArray] = useRecoilState<IComponent[]>(containersArrayAtom);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event: any) => {
    if (DELETE_SHORTCUT(event))
      handleDialogOpen();
  };

  const handleDeleteConfirm = async () => {
    setIsLoading(true);
    handleDialogClose();
    let selectedDashboard = GET.dash();
    if (!selectedDashboard) return;
    let dashboard: IDashboard = await deepCopy(selectedDashboard);
    let components: IComponent[] = await deepCopy(dashboard.components)
    let tmp: IComponent | undefined
    components.forEach(c => {
      if (!selectedComponentIDs.includes(c._id))
        return
      // se è slave di qualcuno, elimalo dagli slaves dei suoi master
      // se masters e slaves fossero stati IComponent[] invece di string[]
      // c.masters?.forEach(master =>
      //   master.slaves = master.slaves.filter(slave => slave._id !== c._id)
      // )
      c?.masters?.forEach(idMaster => {
        tmp = components.find(master => master._id === idMaster)
        if (tmp)
          tmp.slaves = tmp.slaves?.filter(slave => slave !== c._id)
        // TODO maybe: if (tmp.slaves.length <= 1) delete tmp
      })
      // se possiede slaves, eliminalo dai masters dei suoi slaves
      // se masters e slaves fossero stati IComponent[] invece di string[]
      // c.slaves?.forEach(slave =>
      //   slave.masters = slave.masters.filter(master => master._id !== c._id)
      // )
      c.slaves?.forEach(idSlave => {
        tmp = components.find(slave => slave._id === idSlave)
        if (tmp)
          tmp.masters = tmp.masters?.filter(master => master !== c._id)
      })
    })
    dashboard.components = components.filter(c => !selectedComponentIDs.includes(c._id))

    // CONTAINERS
    // if (!selectedContainer) {
    //   let tempComponentsArray = await deepCopy(selectedDashboard?.components);
    //   tempComponentsArray = tempComponentsArray.filter(
    //     (component: any) => !selectedComponentIDs.includes(component._id)
    //   );
    //   if (containersArray) {
    //     let tempContainerArray: IComponent[] = await deepCopy(containersArray);
    //     tempComponentsArray = tempContainerArray.filter(
    //       (c) => !selectedComponentIDs.includes(c._id)
    //     );
    //     setContainersArray(tempComponentsArray);
    //   }
    //   tempDashboard.components = [...(tempComponentsArray ?? [])];
    // } else {
    //   let container = tempDashboard.components.find(
    //     (comp) => comp._id === selectedContainer._id
    //   );
    //   if (!container?.components) {
    //     return; //ts
    //   }
    //   container.components = container.components.filter((component: any) => {
    //     if (!selectedComponentIDs.includes(component._id)) return component;
    //   });
    //   setSelectedContainer(container);
    // }
    await SET.dash(dashboard);
    setSelectedComponentIDs([]);
    DND.getAllBusyCells();
    setIsLoading(false);
  };

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        sx={{ color: "white", border: "1px solid #666" }}
        color="steelBlue"
        className="primaryButton"
        variant="contained"
      >
        <DeleteForeverIcon />
      </Button>
      {/* DIALOG FOR DELETE */}
      <ControlWhiteDialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        onKeyDown={(e) => {
          // catch enter key for
          if (e.key === "Enter") {
            handleDeleteConfirm();
            handleDialogClose();
          }
        }}
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all selected components?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {selectedComponentIDs.length > 0 && <Button onClick={handleDeleteConfirm}>Delete</Button>}
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default HandleDelete