import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AlbumIcon from "@mui/icons-material/Album";

const PhoneStatusBar = () => {
  const [time, setTime] = useState("");
  // Get the current time

  const getTime = () => {
    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setTime(currentTime);
  };

  useEffect(() => {
    getTime();
    const intervalId = setInterval(getTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      position={"relative"}
    >
      <Box ml={"2.50%"}>{time}</Box>
      <Box display={"flex"} mr={"2.50%"}>
        <Box mr={"2.50%"}>
          <NetworkCellIcon fontSize={'small'}></NetworkCellIcon>
        </Box>
        <Box
          position={"absolute"}
          top={-12.5}
          left={0}
          width={"100%"}
          className={"center"}
        >
          <AlbumIcon fontSize="small" sx={{ opacity: .7 }} />
        </Box>
        <Box display={"flex"}>
          <Battery5BarIcon fontSize={'small'}></Battery5BarIcon>
          <Typography>80%</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneStatusBar;
