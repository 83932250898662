import { Dialog, Slider, TextField } from "@mui/material";
import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const ControlSwitch = styled(Switch)(({ theme }) => ({
  padding: '7px 6px',
  opacity: 1,
  "& .MuiSwitch-root": {
    color: "var(--white-color)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 3,
    border: "var(--primary_border)",
    background: "var(--grey-color)",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
    },
  },
  "& .MuiButtonBase": {
    zIndex: 5
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    borderRadius: 3,
    border: "var(--primary_border)",
    background: "#121212",
    zIndex: 2,
  },
}));

export const ControlSlider = styled(Slider)(({ orientation }) => ({
  "& .MuiSlider-rail": {
    backgroundColor: "#fff",
  },
  "& .MuiSlider-track": {
    backgroundColor: "#fff",
  },
  "& .MuiSlider-thumb": {
    width: 25,
    height: 12.5,
    cursor: 'grab',
    borderRadius: 0,
    transform: orientation == 'vertical' ? 'rotate(90deg) translateY(100%)' : '',
  },
}));

export const ControlWhiteDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "#333",
  },
  "& .MuiPaper-root *": {
    color: "#d2d2d2",
    borderColor: "#d2d2d2",
    fill: "#d2d2d2",
  },
  "& .MuiTypography-root, & .MuiButton-root, & .MuiStepLabel-root span": {
    color: "#d2d2d2",
  },
  "& .MuiInputBase-root fieldset legend span": {
    color: "#d2d2d2",
  },
  "& .Mui-error:before": {
    borderBottomColor: "#d32f2f !important",
  },
  "& .MuiStepIcon-text": {
    fill: "var(--primary-color)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.info,
  },
}));

export const ControlWhiteTextField = styled(TextField)(({ theme }) => ({
  "& *": {
    color: "#d2d2d2",
  },
  "& *::hover": {
    borderColor: "#d2d2d2 !important",
  },
  "& .Mui-error:before, .MuiFormHelperText-root.Mui-error": {
    color: "#d32f2f !important",
    borderBottomColor: "#d32f2f !important",
  },
}));

declare module "@mui/material/styles" {
  interface CustomPalette {
    anger: PaletteColorOptions;
    apple: PaletteColorOptions;
    steelBlue: PaletteColorOptions;
    violet: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    anger: true;
    apple: true;
    steelBlue: true;
    violet: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
export const theme = createTheme({
  palette: {
    primary: createColor("#141414"),
    secondary: createColor("#b8955a"),
    anger: createColor("#F40B27"),
    apple: createColor("#1c1c1c"),
    steelBlue: createColor("#333333"),
    violet: createColor("#5DBA40"),
    info: createColor("#f1f1f1"),
  },
});
