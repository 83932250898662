import { Box, ButtonGroup, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOnSet } from "../../../../hooks/useOnSet";
import { useOnGet } from "../../../../hooks/useOnGet";
import { IComponent, IInstallation, IService } from "../../../../common-interfaces/interfaces";
import { deepCopy } from "../../../../helpers/utils";
import HandleDelete from "../common/HandleDelete";
import HandleLink from "./HandleLink";
import { useRecoilState } from "recoil";
import { selectedInstallationAtom } from "../../../../recoil/atoms";
import ServiceRow from "../../../../common-components/editComponents/ServiceRow";

const EditMoreComponents = () => {
  const GET = useOnGet();
  const SET = useOnSet();
  const comps = GET.selectedComponents();

  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

  const [selectedService, setSelectedService] = useState<IService[] | undefined>(undefined);
  const [tmpGlobalHost, setTmpGlobalHost] = useState<string>("");

  useEffect(() => {
    if (!comps)
      return;
    setSelectedService(comps.reduce((acc: IService[], curr) => [...acc, ...(curr.services ?? [])], []));
  }, []);

  useEffect(() => {
    if (!selectedInstallation?.devices || !comps || comps.length === 0) { return }
    const commonHost = comps[0]?.services?.[0]?.host;
    const areAllHostsSame = comps.every((x) => {
      return x.services?.every((s) => s.host === commonHost);
    });
    if (areAllHostsSame && commonHost) {
      setTmpGlobalHost(commonHost)
    }
  }, [selectedInstallation]);

  const areEqualType = (): boolean => {
    if (!comps)
      return false;
    const firstComponentType = comps[0].type
    return comps.every(comp => {
      return comp.type === firstComponentType
    });
  };

  const haveAlreadyAMaster = () => {
    if (!comps)
      return;
    return comps.some(comp => comp.masters?.length);
  };

  const areAllSimple = (): boolean => {
    if (!comps || !comps[0]) return false;
    return comps.every(comp => !comp.slaves || comp.slaves.length < 1);
  };

  const handleChangeGlobalHost = async (e: any) => {
    if (!selectedService) return;

    const host: string = e.target.value;

    const updateServiceHost = async (service: IService) => {
      if (selectedService.find((cs) => cs._id === service._id)) {
        const updatedService = await deepCopy(service);
        updatedService.host = host;
        return updatedService;
      }
      return service;
    };

    const updateComponentServices = async (component: IComponent) => {
      if (!component.services) return component;

      const updatedServices = await Promise.all(component.services.map(updateServiceHost));
      const updatedComponent = await deepCopy(component);
      updatedComponent.services = updatedServices;
      return updatedComponent;
    };

    const updatedComponents = comps ? await Promise.all(comps.map(updateComponentServices)) : [];

    const dash = await deepCopy(GET.dash());
    dash.components = dash.components.map(
      (c: IComponent) => updatedComponents.find((uc) => uc._id === c._id) ?? c
    );

    SET.dash(dash);
  };


  const getSelectedNumber = () => {
    return !comps ? 0 : comps.length;
  };

  return (
    <Box>
      {areAllSimple() ? (
        <>
          <Typography fontSize={25} className="underline-title center">Edit More</Typography>
          <Box className="center" flexDirection={'column'}>
            <Typography fontSize={15} mt={2}>{`You have selected ${getSelectedNumber()} ${!areEqualType() ? "different" : "alike"} components`}</Typography>
            <ButtonGroup variant="text" sx={{ mt: 2 }}>
              {areEqualType() && !haveAlreadyAMaster() && <HandleLink />}
              <HandleDelete />
            </ButtonGroup>
          </Box>
          <Box mt="5%" display={'flex'} flexDirection={'column'}>
            <Box py={1} mb={4} borderRadius="5px" display="flex" flexDirection={"column"} sx={{ background: "transparent" }}>
              <Typography fontSize={15} className="underline-title center" mb={3}>Change All Components Host</Typography>
              <Box className="center">
                {(selectedInstallation?.devices || selectedInstallation?.devices?.length > 0) ?
                  <Select
                    variant="standard"
                    disabled={!selectedInstallation?.devices}
                    onChange={handleChangeGlobalHost}
                    value={tmpGlobalHost}
                    sx={{ width: '50%', fill: 'white', textAlign: 'center', color: '#fff !important', }}
                  >
                    {selectedInstallation?.devices.map(dev => <MenuItem key={dev._id} value={dev.local_ip}>{dev.hostname}</MenuItem>)}
                  </Select> :
                  <Typography>Please Create a Device</Typography>}
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography fontSize={15} className="underline-title center" mb={3}>Services</Typography>
              {comps?.map(comp =>
                <Box key={comp._id} marginBottom={'2.5%'}>
                  <Typography fontSize={25} textAlign={'center'} textTransform={'uppercase'}>{comp.name}</Typography>
                  {/* TODO: REFACTOR: maybe can use ServicesTable */}
                  {comp.services && <Box display={'flex'} flexDirection={'column'}>
                    {comp.services.map(serv =>
                      <Box key={serv._id} display={"flex"} alignItems={"center"} width={'95%'}>
                        <ServiceRow service={serv} component={comp} />
                      </Box>
                    )}
                  </Box>
                  }
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <HandleDelete />
        </Box>
      )
      }
    </Box >
  );
}

export default EditMoreComponents