import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { IService } from '../../../common-interfaces/interfaces';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import '../../../styles/EditComponentLeftBar.css'
import DndService from './DndService';
import { useRecoilState } from 'recoil';
import { uneditableServiceAtom } from '../../../recoil/Atom';
import { editableOscService, editableRestService } from '../../../config/config';

const EditComponentLeftBar = () => {
  const [allServices] = useRecoilState<IService[]>(uneditableServiceAtom);
  const [KServices, setKServices] = useState<IService[]>()
  const [customServices, setCustomServices] = useState<IService[]>()
  const [accordionExpanded, setAccordionExpanded] = useState<number>(-1)

  useEffect(() => {
    setServices()
  }, [allServices]);

  const setServices = () => {
    setKServices(allServices.filter(s => s.brand === "k-array"))
    setCustomServices(allServices.filter(s => s.brand === "custom"))
  }

  const handleChange = (expanded: boolean, value: number) => {
    setAccordionExpanded(expanded ? value : -1)
  }
  const accordionChanges = [
    (_: any, expanded: boolean) => handleChange(expanded, 0),
    (_: any, expanded: boolean) => handleChange(expanded, 1),
    (_: any, expanded: boolean) => handleChange(expanded, 2)
  ]

  return (
    <Grid item xs={2} height={'100%'} className='scrollable' bgcolor='#131313'>
      <Accordion square={true} expanded={accordionExpanded === 0} onChange={accordionChanges[0]}>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>K-ARRAY SERVICES</Typography></AccordionSummary>
        <AccordionDetails className="accordionDetails">
          {KServices?.map(service => <DndService key={service._id} service={service} />)}
        </AccordionDetails>
      </Accordion>
      {customServices?.length && (
        <Accordion square={true} expanded={accordionExpanded === 1} onChange={accordionChanges[1]}>
          <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>CUSTOM SERVICES</Typography></AccordionSummary>
          <AccordionDetails className="accordionDetails">
            {customServices.map(service => <DndService key={service._id} service={service} />)}
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion square={true} expanded={accordionExpanded === 2} onChange={accordionChanges[2]}>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}><Typography>EDITABLE SERVICES</Typography></AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <DndService service={editableRestService} />
          <DndService service={editableOscService} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
export default EditComponentLeftBar
