import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useRecoilState } from "recoil";
import { phoneWAtom, phoneHAtom } from "../../../recoil/atoms";

import { PHONE_pl, PHONE_pr } from "../../../helpers/utils";
import ComponentPreviewer from "./ComponentPreviewer";

export const PhonePreviewComponentRender = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [comp, setComp] = useState(props.comp);
  const [phoneW] = useRecoilState<number>(phoneWAtom);
  const [phoneH] = useRecoilState<number>(phoneHAtom);

  const [h, setH] = useState<number>();
  const [w, setW] = useState<number>();
  const [t, setT] = useState<number>();
  const [l, setL] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    setComp(props.comp);
    const workspaceH = (phoneH / 100) * 65;
    const workspaceW = phoneW - PHONE_pl - PHONE_pr;
    const pieceH = workspaceH / 14;
    const pieceW = workspaceW / 12;
    setH(props.comp.height * pieceH);
    setT(props.comp.top * pieceH);
    setW(props.comp.width * pieceW);
    setL(props.comp.left * pieceW);
    setIsLoading(false);
  }, [props.comp, phoneH, phoneW]);



  return isLoading ? (
    <>
      {/* TODO */}
      {/* potremmo centrare il loading o utilizzare skeleton */}
      {/* magari togliere questa box e il componente all'interno dato che non viene visualizzato */}
      <Box display={"none"}>
        <ComponentPreviewer component={comp}></ComponentPreviewer>
      </Box>
      <ReactLoading
        type={"spin"}
        color={"#fff"}
        height={"100%"}
        width={"9vw"}
        className=" loading"
      />
    </>
  ) : (
    <Box
      sx={{
        height: h,
        width: w,
        position: "absolute",
        left: l,
        top: t,
      }}
    >
      <ComponentPreviewer component={comp}></ComponentPreviewer>
    </Box>
  );
};
