import { selectedServiceAtom } from '../../../recoil/Atom';
import { Box, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { EServiceType, IService } from '../../../common-interfaces/interfaces';
import EditRestService from './EditRestService';
import EditOscService from './EditOscService';
import { useCallback } from 'react';

const stackStyle = { width: "100%", height: "100%" }
const titleBoxStyle = { height: "8%", minHeight: '40px', backgroundColor: 'var(--primary-color)', borderTop: "1px solid var(--primary_background-color)" }
const editBoxStyle = { width: '100%', borderTop: '2px solid var(--primary-color)' }

const ServiceInfo = () => {
  const [selectedService] = useRecoilState<IService>(selectedServiceAtom)

  const HandleServiceType = useCallback(() => {
    switch (selectedService.protocol) {
      case EServiceType.Rest:
        return selectedService.restService ? <EditRestService restService={selectedService.restService} /> : null
      case EServiceType.Osc:
        return selectedService.oscService ? <EditOscService oscService={selectedService.oscService} /> : null
      default:
        return <></>
    }
  }, [selectedService])

  return (
    <Stack sx={stackStyle}>
      <Box className='center' sx={titleBoxStyle}>
        <Typography className="underline-title" width={'100%'} fontSize={20}>{selectedService.name}</Typography>
      </Box>
      <Box sx={editBoxStyle}>
        {HandleServiceType()}
      </Box>
    </Stack>
  )
}

export default ServiceInfo