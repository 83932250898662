import { Grid, Typography, TextField } from '@mui/material';
import React, { Dispatch, FC, useEffect, useState } from 'react'

const AddStringSegment: FC<{
  value: string,
  handleChange: Dispatch<React.SetStateAction<string>>,
  handleConfirm: () => void
}> = ({ value, handleChange, handleConfirm }) => {

  const [tmpValue, setValue] = useState(value)

  useEffect(() => {
    setValue(value)
  }, [value]);

  return (
    <Grid
      container
      boxSizing={'border-box'}
      direction={'row'}
      pt={5}
    >
      <Grid container item xs={4} width={'100%'} alignItems={'center'}>
        <Typography>Add static value</Typography>
      </Grid>
      <Grid container item xs={8} width={'100%'} alignItems={'center'}>
        <TextField fullWidth variant='standard'
          value={tmpValue}
          onChange={(ev) => handleChange(ev.target.value)}
          onBlur={handleConfirm}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter") {
              handleConfirm();
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
export default AddStringSegment