import { Box, Paper } from "@mui/material";
import '../../../styles/InstallationSideBar.css'
import SearchDashboard from "./SearchDashboard";
import InstallationInfo from "./InstallationInfo";

export default function InstallationSideBar(props: any) {

  const handleSearch = (event: any) => {
    props.setFilter(event)
  }

  return (
    <Paper elevation={0} className="sidebar InstallationSidebar">
      <Box sx={{ pt: 5 }}>
        <InstallationInfo />
      </Box>
      <Box sx={{ mt: 6 }}>
        <SearchDashboard setFilter={handleSearch} />
      </Box>
    </Paper>
  );
}
