import { atom } from "recoil";
import {
  ICellDimension,
  IWorkspaceCell,
  IWorkspaceLocation,
} from "../screens/EditDashboard/workspace/dnd/utils/dndUtils";
import { IComponent } from "../common-interfaces/interfaces";

export const cellDimensionAtom = atom<ICellDimension>({
  key: "cellDimensionAtom",
  default: { width: 0, height: 0 },
});

export const isResizingAtom = atom<boolean>({
  key: "isResizing",
  default: false,
});

export const isSelectingAtom = atom<boolean>({
  key: "isSelecting",
  default: false,
});

export const movingComponentIDAtom = atom<string>({
  key: "movingComponentID",
  default: undefined,
});

export const resizingCompAtom = atom<string>({
  key: "isResizingComp",
  default: "",
});

export const busyCellsAtom = atom<IWorkspaceCell[]>({
  key: "busyCells",
  default: undefined,
});

export const selectedContainerAtom = atom<IComponent>({
  key: "selectedContainer",
  default: undefined,
});

export const copyingComponentAtom = atom<IComponent>({
  key: "copyingComponent",
  default: undefined,
});

export const hoveredCellAtom = atom<IWorkspaceLocation>({
  key: "hoveredCell",
  default: undefined,
});

export const containersArrayAtom = atom<IComponent[]>({
  key: "containersArray",
  default: undefined,
});

export const GRIDCOL_Atom = atom<number>({
  key: "GRIDCOL",
  default: 12,
});

export const GRIDROW_Atom = atom<number>({
  key: "GRIDROW",
  default: 14,
});
