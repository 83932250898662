import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { allRequestsAtom, filterParamAtom, requestsFromRemoteAtom, sortDirectionAtom, sortParamAtom } from "../utils/requestAtoms";
import { ERequestSubtype, ERequestStatus, ERequestType, INotificationRequest } from "../../../common-interfaces/interfaces";
import { useOnGet } from "../../../hooks/useOnGet";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { URLS } from "../../../helpers/URLS";
import { deepCopy } from "../../../helpers/utils";

export default function useRequestNotificationUtils() {
  const [requestFromRemote, setAllRemoteRequests] = useRecoilState<INotificationRequest[]>(requestsFromRemoteAtom)
  const [allRequests, setAllRequests] = useRecoilState(allRequestsAtom)
  const [sortDirection, setSortDirection] = useRecoilState(sortDirectionAtom);
  const [sortParam, setSortParam] = useRecoilState(sortParamAtom);
  const [filterParam, setFilterParam] = useRecoilState(filterParamAtom)
  const [installerEmail, setInstallerEmail] = useState('')
  const GET = useOnGet()
  const { postWithAuth, deleteWithAuth, putWithAuth } = useApiWrapper()
  const resetsortParam = useResetRecoilState(sortParamAtom)
  const resetFilterParam = useResetRecoilState(filterParamAtom)
  const resetDirection = useResetRecoilState(sortDirectionAtom)

  const reset = () => {
    resetDirection()
    resetFilterParam()
    resetsortParam()
  }

  const sortRequests = (column: keyof INotificationRequest = sortParam) => {
    if (!allRequests || allRequests.length === 0) return
    const direction = sortParam === column && sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedRequests = [...allRequests].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortDirection(direction);
    sortParam !== column && setSortParam(column);
    setAllRequests(sortedRequests);
  };

  const filterRequest = async (filterPar: 'from' | 'to' | 'all' = filterParam) => {
    setFilterParam(filterPar)
    if (filterPar === 'all') {
      setAllRequests(requestFromRemote)
      return
    }
    const filteredReqs = requestFromRemote.filter(req => req[filterPar] === installerEmail)
    setAllRequests(filteredReqs)
    resetsortParam()
  }

  const getInstallerEmail = async () => {
    const email = ((await GET.user()).username)
    setInstallerEmail(email)
  }
  const getPendingRequestRecived = useCallback(() => {
    if (!installerEmail) return []
    return requestFromRemote.filter(req => req.from !== installerEmail && req.status === ERequestStatus.PENDING)
  }, [requestFromRemote, installerEmail])

  const getPendingRequestSent = useCallback(() => {
    if (!installerEmail) return []
    return requestFromRemote.filter(req => req.from === installerEmail && req.status === ERequestStatus.PENDING)
  }, [requestFromRemote, installerEmail])

  const getPendingRequest = useCallback(() => {
    if (!installerEmail) return []
    return requestFromRemote.filter(req => req.status === ERequestStatus.PENDING)
  }, [requestFromRemote, installerEmail])

  const shareNewRequest = async (to: string, type: ERequestType, subtype: ERequestSubtype, idObject: string, label: string) => {
    const newRequest = {
      status: ERequestStatus.PENDING,
      from: installerEmail,
      to,
      type,
      subtype,
      idObject,
      label,
      subrequests: []
    }
    await postWithAuth(URLS.request, newRequest)
  }

  const handleChangeFromRemote = async () => {
    const isDifferent = JSON.stringify(requestFromRemote) !== JSON.stringify(allRequests);
    if (isDifferent) {
      const data = await deepCopy(requestFromRemote)
      setAllRequests(data)
    }
  }

  const deleteRequest = async (reqID: string) => {
    try {
      await deleteWithAuth(`${URLS.request}/${reqID}`, {})
      setAllRequests(allRequests.filter(req => req._id !== reqID));
      setAllRemoteRequests(allRequests.filter(req => req._id !== reqID));
    } catch (e) {
      console.log(e)
    }
  }
  const handleChangeReqValue = async (req: INotificationRequest, value: ERequestStatus) => {
    const tmpReq = { ...req, status: value }
    try {
      await putWithAuth(`${URLS.request}/${req._id}`, tmpReq)
      setAllRemoteRequests(requestFromRemote.map(r => r._id === tmpReq._id ? tmpReq : r))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleChangeFromRemote()
    filterRequest()
    sortRequests()
  }, [requestFromRemote]);

  useEffect(() => {
    reset()
    !installerEmail && getInstallerEmail()
  }, []);

  return { sortRequests, filterRequest, installerEmail, getPendingRequestRecived, getPendingRequestSent, getPendingRequest, shareNewRequest, deleteRequest, handleChangeReqValue }
}