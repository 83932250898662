import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isVerboseAtom } from "../recoil/atoms";
import { SHOW_LOGS, HIDE_LOGS } from "../config/shortcut";

export function useOnShortcuts() {
  const [, setIsVerbose] = useRecoilState(isVerboseAtom);
  const keyWordToCheck = [SHOW_LOGS, HIDE_LOGS];

  useEffect(() => {
    let currentInput = "";
    const handleKeyDown = (event: any) => {
      const key = event.key?.toLowerCase();
      currentInput += key;
      keyWordToCheck.forEach((keyWord) => {
        if (currentInput.includes(keyWord.key)) {
          alert(
            `KEYWORD < ${keyWord.key} > typed: setting ${keyWord.localStorageKey} to "${keyWord.value}"`
          );
      
          localStorage.setItem(
            keyWord.localStorageKey,
            JSON.stringify(keyWord.value)
          );
          currentInput = "";
        }
      });
      if (
        currentInput.length >
        Math.max(...keyWordToCheck.map((kw) => kw.key.length * 10))
      ) {
        currentInput = "";
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsVerbose]);
}
