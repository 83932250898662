import { Box } from "@mui/material";
import WorkspaceSquare from "./dnd/WorkspaceSquare";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { cellDimensionAtom, selectedContainerAtom } from "../../../recoil/workspace";
import { selectedDashboardAtom } from "../../../recoil/atoms";

export default function SquareRender(props: any) {
  const i = props.i;
  const [cellDimension] = useRecoilState(cellDimensionAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [w, setW] = useState(cellDimension?.width);
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();
  const [h, setH] = useState(cellDimension?.height);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);

  useEffect(() => {
    if (!cellDimension) {
      return;
    }
    setW(cellDimension.width);
    setH(cellDimension.height);
  }, [cellDimension]);

  useEffect(() => {
    if (!selectedContainer) {
      setY(props.y);
      setX(props.x);
    } else {
      const activeContainer = selectedDashboard?.components.find(
        (comp) => comp._id == selectedContainer._id
      );
      if (!activeContainer) {
        return console.error("smth went wrong");
      }
      setY(activeContainer.top + props.y);
      setX(activeContainer.left + props.x);
    }
  }, [selectedContainer, selectedDashboard]);

  return (
    <Box
      key={i}
      id={i}
      style={{
        width: `${w}px`, //border-left
        height: `${h}px`,
      }}
      position={"relative"}
    >
      <WorkspaceSquare x={x} y={y}></WorkspaceSquare>
    </Box>
  );
}
