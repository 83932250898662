import { Box } from "@mui/material";
import EditComponentHandler from "./EditComponentHandler";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loggedUserAtom, selectedComponentIDsAtom } from "../../../recoil/atoms";
import { useOnGet } from "../../../hooks/useOnGet";
import { EComponentType, IComponent } from "../../../common-interfaces/interfaces";
// import HandleIconUpdate from "./HandleIconUpdate";
import { IAutocompleteOPT } from "../../../local-interfaces/local-interfaces";

export default function EditComponentInfo() {
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [logged_user] = useRecoilState(loggedUserAtom);
  const [comp, setComp] = useState<IComponent>();
  const [ICONS, setIcons] = useState<IAutocompleteOPT[]>([]);
  const GET = useOnGet();

  useEffect(() => {
    const editComp: IComponent | undefined = GET.comp(selectedComponentIDs[0]);
    if (!editComp) {
      return;
    }
    setComp(editComp);
  }, [selectedComponentIDs]);

  const getAllImages = async () => {
    const tmpIcons = await GET.images();
    setIcons(tmpIcons);
  };

  useEffect(() => {
    getAllImages();
  }, [logged_user?.images]);

  return (
    <Box className="inputWrapper">
      <EditComponentHandler
        type={"input"}
        disabled={true}
        edit={"brand"}
        borderTop={"1px solid var(--grey-color)"}
      />
      <EditComponentHandler
        type={"input"}
        disabled={false}
        edit={"name"}
        borderTop={"1px solid var(--grey-color)"}
      />
      <EditComponentHandler
        type={"input"}
        disabled={false}
        edit={"description"}
      />
      <Box
        width={"45.5%"}
        display={"flex"}
        borderTop={"1px solid var(--grey-color)"}
      >
        <EditComponentHandler
          w={"50%"}
          type={"colorpicker"}
          disabled={false}
          edit={"backgroundColor"}
        />
        <EditComponentHandler
          type={"colorpicker"}
          w={"50%"}
          disabled={false}
          edit={"color"}
        />
      </Box>
      <EditComponentHandler
        type={"number"}
        disabled={comp?.type !== EComponentType.Slider}
        edit={"min"}
      />
      <EditComponentHandler
        type={"number"}
        disabled={comp?.type !== EComponentType.Slider}
        edit={"max"}
      />
      <EditComponentHandler
        w={
          comp?.type === EComponentType.Button ||
            comp?.type === EComponentType.Switch
            ? " 45%"
            : "90.5%"
        }
        type={"number"}
        disabled={false}
        edit={"polling"}
        valueMultiplier={1000}
      />
      {(comp?.type === EComponentType.Button ||
        comp?.type === EComponentType.Switch) && (
          <>
            <EditComponentHandler
              type={"select"}
              w={"45%"}
              opts={ICONS}
              //opts={ICONS}
              disabled={false}
              edit={"icon"}
            />
            {/* <HandleIconUpdate /> */}
          </>
        )}

    </Box>
  );
}
