import { Typography, Button, Grid } from "@mui/material";
import { IInstallation } from "../../../common-interfaces/interfaces";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from "react-router-dom";
import { selectedComponentIDsAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { currentSelectedComponentAtom } from "../../../recoil/Atom";
import { useEffect } from "react";
import CreateService from "../editServices/rest/CreateService";
import { useOnGet } from "../../../hooks/useOnGet";
import HandleAddDevice from "../../devices/HandleAddDevice";
import EditMultiServiceHosts from "./EditMultiServiceHosts";
import ServiceRow from "../ServiceRow";

const buttonStyle = { display: 'flex', mx: 'auto' }

const ServicesTable = () => {
    const { installationId, dashboardId, componentId } = useParams();
    const navigate = useNavigate();
    const GET = useOnGet()

    const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
    const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
    const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

    useEffect(() => {
        let tmpComp = GET.comp(currentSelectedComponent._id);
        if (tmpComp && JSON.stringify(tmpComp) !== JSON.stringify(currentSelectedComponent))
            setCurrentSelectedComponent(tmpComp);
    }, [selectedInstallation]);

    const navigateToEditComponentScreen = () => {
        navigate(`/installation/${installationId}/dashboard/${dashboardId}/component/${selectedComponentIDs[0]}`);
    }

    return (
        <Grid width="90%" minHeight={'30%'} container justifyContent={'center'} alignItems={'center'} gap={2} boxSizing="border-box" padding="10px" overflow="auto">
            {/* title */}
            <Grid item width={'100%'} >
                {componentId ?
                    <Typography className="underline-title">Services</Typography> :
                    <Button sx={buttonStyle} endIcon={<ArrowForwardIcon />} variant='outlined' onClick={navigateToEditComponentScreen} color="secondary">
                        <Typography color={'var(--secondary-color)'}>Services</Typography>
                    </Button>
                }
            </Grid>
            {/* rows */}
            {!currentSelectedComponent?.services?.length ?
                <Typography color={'white'}>No service available, please add one</Typography>
                : <>
                    <EditMultiServiceHosts component={currentSelectedComponent} />
                    {currentSelectedComponent.services?.map(service => <ServiceRow key={service._id} service={service} component={currentSelectedComponent} />)}
                    < HandleAddDevice />
                </>
            }
            <CreateService />
        </Grid >
    )
}

export default ServicesTable