import { Tooltip } from "@mui/material";
import { useRef, useEffect, ReactNode, useState } from "react";

const slotPropsStyle = { popper: { modifiers: [{ name: "offset", options: { offset: [0, -10], }, },], }, }
const popperPropsStyle = { sx: { pointerEvents: "none" } }

const TooltipOverflow = ({ tooltipChildren, tooltipTitle }: { tooltipChildren: JSX.Element, tooltipTitle?: ReactNode }) => {

    const tooltipRef = useRef<Element>()
    const [displayTooltip, setDisplayTooltip] = useState(true)

    useEffect(() => {
        if (tooltipRef.current && tooltipRef.current.clientWidth >= tooltipRef.current.scrollWidth)
            setDisplayTooltip(false)
    }, [])

    return (
        displayTooltip ?
            <Tooltip
                ref={tooltipRef}
                children={tooltipChildren}
                placement="top"
                title={tooltipTitle ?? tooltipChildren.props.children}
                PopperProps={popperPropsStyle}
                slotProps={slotPropsStyle} /> :
            tooltipChildren
    )
}

export default TooltipOverflow