import { Undo } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { previousRouteAtom } from "../../../recoil/atoms";
import { allRequestsAtom } from "../utils/requestAtoms";
import NotificationsIcon from '@mui/icons-material/Notifications';
import usePopover from "./usePopover";
import NotificationElementPopover from "./NotificationElementPopover";
import { useCallback } from "react";
import useRequestNotificationUtils from "../hooks/useRequestNotificationUtils";

const NotificationButton = () => {
  const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteAtom)
  const [allRequests] = useRecoilState(allRequestsAtom)

  const { getPendingRequestRecived, installerEmail } = useRequestNotificationUtils()
  const navigate = useNavigate();
  const currentRoute = useLocation()
  const notificationPopover = usePopover()

  
  const handlePreviousRoute = async (event: any) => {
    event.preventDefault()
    navigate(previousRoute);
    setPreviousRoute('/')
  };
  const getNotificationLength = useCallback((): number => {
    return getPendingRequestRecived().length
  }, [installerEmail, allRequests])



  return (<>{
    currentRoute.pathname.includes('request') ?
      <IconButton
        onClick={handlePreviousRoute}
      >
        <Undo fontSize={"small"} sx={{ color: '#ccc' }} />
      </IconButton> :
      <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{
        mr: 2, '& .MuiBadge-badge': {
          top: '23%',
          right: '2%',
          color: '#333'
        },
      }} overlap='circular' variant='standard' badgeContent={getNotificationLength()} color='secondary'>
        <IconButton size='large' edge='end' color='inherit' onClick={(e: any) => notificationPopover.open(e.currentTarget)}>
          <NotificationsIcon />
        </IconButton>
      </Badge>
  }
    {notificationPopover.isOpen && <NotificationElementPopover open={notificationPopover.isOpen} anchorEl={notificationPopover.anchor} onClose={notificationPopover.close} />}
  </>)
}
export default NotificationButton