const regex = /@@(.*?)@@/g;

export const isParamSegment = (segment: string): boolean => segment.toString()?.match(regex) !== null;

export const removeParamIdentifier = (inputString: string): string => {
  const result = inputString.replace(
    regex,
    (_, capturedGroup) => capturedGroup,
  );

  return result;
};