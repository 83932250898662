import { useState } from "react"
import { TextField, InputAdornment, IconButton } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useRecoilState } from "recoil";
import { docsTreeAtom } from "../common/DocsAtom";
import { deepCopy } from "../../../helpers/utils";
import { DOCUMENTAZIONE } from "../../../config/docs";
import { DocsTreeNode } from "../../../local-interfaces/docs";

const DocsSearch = () => {

  const [textfieldValue, setTextfieldValue] = useState("")
  const [_, setTree] = useRecoilState(docsTreeAtom)

  const recFilterTreeItems = (input: DocsTreeNode[], inputValue: string, output: DocsTreeNode[] = []): DocsTreeNode[] => {
    // per ogni oggetto di input
    input.forEach(obj => {
      // se include inputValue lo aggiungo a output
      if (obj.label.toLowerCase().includes(inputValue.toLowerCase())) {
        output = [...output, obj]
        // altrimenti, ricorsivamente su tutti i suoi figli, se esistono
      } else if (obj.subcontent) {
        // se la chiamata ricorsiva mi restituisce un array non vuoto, lo setto come nuovo figlio del padre, e quindi aggiungo il padre all'output
        const tmp = recFilterTreeItems(obj.subcontent, inputValue)
        if (!!tmp.length) {
          obj.subcontent = tmp
          output = [...output, obj]
        }
      }
    })
    // quindi ritorno l'albero
    return output
  }

  const handleTextfieldChange = async (inputValue: string) => {
    setTextfieldValue(inputValue)
    setTree(recFilterTreeItems(await deepCopy(DOCUMENTAZIONE), inputValue))
  }

  return (
    <TextField
      type='text'
      size='small'
      label='Search'
      value={textfieldValue}
      autoFocus
      autoComplete="off"
      InputProps={{
        startAdornment: <InputAdornment position="start"><SearchIcon sx={{ color: 'white' }} /></InputAdornment>,
        endAdornment: textfieldValue &&
          <InputAdornment position="end">
            <IconButton onClick={() => handleTextfieldChange("")} edge="end">
              <ClearIcon sx={{ color: 'white', ":hover": { color: "var(--secondary-color)" } }} />
            </IconButton>
          </InputAdornment>
      }}
      onChange={event => handleTextfieldChange(event.target.value)}
      sx={{
        width: '90%',
        color: 'white',
        '& label, label.Mui-focused': {
          color: 'white',
        },
        '& .MuiOutlinedInput-root': {
          color: 'white',
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'var(--secondary-color)',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'var(--selected-color)',
          },
        },
      }}
    />
  )
};

export default DocsSearch;