import { Box } from "@mui/material";
import { useDrop } from "react-dnd";
import { useOnDND } from "../../../../hooks/useOnDND";
import { IWorkspaceLocation, ItemTypes } from "./utils/dndUtils";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  busyCellsAtom,
  copyingComponentAtom,
  hoveredCellAtom,
  movingComponentIDAtom,
  selectedContainerAtom,
} from "../../../../recoil/workspace";
import Component from "./Component";
import { EComponentType, IComponent } from "../../../../common-interfaces/interfaces";
import {
  allComponentsAtom,
  selectedDashboardAtom,
} from "../../../../recoil/atoms";
import { PASTE_SHORTCUT } from "../../../../config/shortcut";

export default function WorkspaceSquare(props: any) {
  const [allComponents] = useRecoilState(allComponentsAtom);
  const [componentOrigin, setComponentOrigin] = useState<
    IComponent | undefined
  >();
  const [componentInside, setComponentInside] = useState<
    IComponent | undefined
  >();
  const [busyCells] = useRecoilState(busyCellsAtom);
  const [willBeBusy, setBusy] = useState<boolean>();
  const stroke = willBeBusy ? "#000" : "";
  const [draggingID, setDraggingID] = useRecoilState(movingComponentIDAtom);
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);
  const [copyingComponent] = useRecoilState(copyingComponentAtom);
  const [hoveredCell, setHoveredCell] = useRecoilState(hoveredCellAtom);
  const resetCell = useResetRecoilState(hoveredCellAtom);

  const [location, setLocation] = useState<IWorkspaceLocation>({
    x: props.x,
    y: props.y,
  });
  const DND = useOnDND();

  useEffect(() => {
    if (hoveredCell !== location) {
      return;
    }
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [hoveredCell]);

  const handleKeyPress = async (event: any) => {
    event.stopPropagation();
    if (PASTE_SHORTCUT(event)) {
      await handleCtrlV();
    }
  };

  const handleCtrlV = async () => {
    if (!copyingComponent || !hoveredCell) {
      return;
    }
    if (hoveredCell === location) {
      await DND.createComponent(
        copyingComponent._id,
        location.x,
        location.y,
        true
      );
    }
  };

  useEffect(() => {
    setLocation({
      x: props.x,
      y: props.y,
    });
  }, [props.x, props.y]);

  const [{ isOver, canDrop }, drop] = useDrop(
    //DRAG AND DROP
    () => ({
      accept: [ItemTypes.COMPONENT, ItemTypes.PREVIEW],
      canDrop: (item) => {
        let canDrop = !DND.hasComponentCollision(
          item.ID,
          location.x,
          location.y
        );
        return canDrop;
      },
      drop: async (item: any, monitor: any) => {
        const itemType = monitor.getItemType();
        if (itemType === ItemTypes.COMPONENT) {
          await DND.modifyCompPosition(item.ID, location.x, location.y);
        }
        if (itemType === ItemTypes.PREVIEW) {
          DND.createComponent(item.ID, location.x, location.y);
        }
        setDraggingID("");
      },

      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
        ItemType: monitor.getItemType(),
      }),
    }),
    [
      selectedDashboard,
      allComponents,
      selectedContainer,
      location,
      hoveredCell,
      copyingComponent,
    ]
  );

  const findComponentIside = (
    components: IComponent[],
    x: number,
    y: number
  ): IComponent | undefined => {
    if (components?.length > 0) {
      for (const component of components) {
        if (
          component.top === y &&
          component.left === x &&
          component.type !== EComponentType.Container
        ) {
          return component;
        }
        if (component?.components?.length) {
          return findComponentIside(component.components, x, y);
        }
      }
    }
    return undefined;
  };

  // useffect per assegnare il component origin alla cella.
  useEffect(() => {
    let componentOrigin: IComponent | undefined;
    if (!selectedContainer) {
      // nel caso sia il workspace generico, cerco il primo componente con x e y corrispondenti
      componentOrigin = selectedDashboard?.components?.find(
        (comp) => comp.left === location.x && comp.top === location.y
      );
      const tempComponentInside = findComponentIside(
        selectedDashboard?.components,
        location.x,
        location.y
      );
      if (tempComponentInside) {
        setComponentInside(tempComponentInside);
      }
    } else {
      //altrimenti cerco nei componenti del contenitore il componente corrispondente
      let container = selectedDashboard?.components.find(
        (comp) => comp._id === selectedContainer._id
      );
      if (!container || !container?.components) {
        return;
      }
      componentOrigin = container?.components.find(
        (comp) => {
          return (
            comp.left === location.x &&
            comp.top === location.y &&
            comp._id !== selectedContainer._id
          );
        } // evito il render del componente stesso
      );
    }
    setComponentOrigin(componentOrigin);
  }, [selectedDashboard, location, selectedContainer]);

  useEffect(() => {
    // EFFECT for flag as busy (where the comp is going to drop) the cells
    if (isOver) {
      let comp: IComponent = DND.getWorkspaceComponent(draggingID);
      comp.left = location.x;
      comp.top = location.y;
      DND.getBusyCells(comp);
    }
  }, [isOver]);

  useEffect(() => {
    // EFFECT for flag as busy the cells included in atom
    if (busyCells) {
      const isbusy = busyCells.find(
        (cell) => cell.x === location.x && cell.y === location.y
      );
      isbusy ? setBusy(true) : setBusy(false);
    } else {
      setBusy(false);
    }
  }, [busyCells, selectedContainer]);

  const cellBackground = isOver
    ? canDrop
      ? "var(--secondary-color)"
      : "red"
    : willBeBusy && draggingID
      ? "#e2dbdb47"
      : location === hoveredCell && copyingComponent
        ? "#646464"
        : "#595959";

  const handleHover = () => {
    setHoveredCell(location);
  };

  const handleMouseLeave = () => {
    resetCell();
  };

  return (
    <Box
      sx={{
        backgroundColor: cellBackground,
        color: stroke,
        width: "calc(100% - 0px)", //borders
        height: "calc(100% - 0px)",
        zIndex: "1",
        border: "1px solid #333",
      }}
      ref={drop}
      className={"square"}
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      {componentOrigin && (
        <Component
          comp={componentOrigin}
          isOver={isOver}
          location={location}
          inside={componentInside}
        />
      )}
    </Box>
  );
}
