import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useOnSet } from "../../../hooks/useOnSet";
import { useOnGet } from "../../../hooks/useOnGet";
import { EComponentType, IComponent } from "../../../common-interfaces/interfaces";
import { selectedComponentIDsAtom } from "../../../recoil/atoms";
import { deepCopy } from "../../../helpers/utils";
import "../../../styles/EditComponentHandler.css";
import {
  containersArrayAtom,
  selectedContainerAtom,
} from "../../../recoil/workspace";
import { DELETE_SHORTCUT } from "../../../config/shortcut";
import { IAutocompleteOPT } from "../../../local-interfaces/local-interfaces";
import { ControlWhiteTextField } from "../../../theme";

// tmpValue --> initial value
// edit --> the object.key to pass in body
// method --> method to call
// index --> index

export default function EditComponentHandler(props: any) {
  const [tmpValue, setTmpValue] = useState<any>(null);
  const [maxWidth, setMaxWidth] = useState<number>(8);
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [containersArray, setContainersArray] = useRecoilState<IComponent[]>(containersArrayAtom);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);
  const [isNAN, setIsNAN] = useState<boolean>(false);
  const GET = useOnGet();
  const SET = useOnSet();

  useEffect(() => { init(); }, [selectedComponentIDs]);

  const init = async () => {
    const editComp: any = GET.comp(selectedComponentIDs[0]); // any messo perché TS non gradiche il IComponent[`{any}`]
    if (!editComp) { return };
    if (props.type === "slider" && props.width && props.left !== undefined) {
      let newMW = calculateMaxWidth();
      setMaxWidth(newMW);
    }
    if (editComp[`${props.edit}`] === undefined)
      return props.type !== "select"
        ? setTmpValue("")
        : setTmpValue(props.opts[0]);
    let value = editComp[`${props.edit}`];
    if (props.valueMultiplier) {
      value = value / props.valueMultiplier;
    }
    setTmpValue(value);
  };

  const calculateMaxWidth = () => {
    const maxGridWidth = 12;
    return maxGridWidth - props.left;
  };

  const handleNumberChange = (e: any) => {
    e.stopPropagation();
    if (isNaN(e.target.value)) {
      return setIsNAN(true);
    }
    setIsNAN(false);
    setTmpValue(e.target.value);
  };

  const handleChange = (e: any) => {
    setTmpValue(e.target.value);
  };

  const handleChangeColor = (selectedColor: any) => {
    setTmpValue(selectedColor);
  };

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setTmpValue(newValue);
  };

  const applyNumberChanges = (e: any) => {
    if (isNAN || isNaN(e.target.value)) return;
    applyChanges(e);
  };

  const applyChanges = async (e: any, selectedValue?: any) => {
    if (!selectedComponentIDs[0]) { return };
    if (!tmpValue && selectedValue) {
      setTmpValue(selectedValue);
    }

    let value = e.target?.dataset?.value ?? tmpValue;
    if (props.type === "select") {
      value = selectedValue.value;
    }

    let editComp: IComponent = selectedContainer
      ? await applyChangeInContainer(value)
      : await applyNormalChange(value);

    if (props.edit === "name" && editComp.type === EComponentType.Container) {
      let tempContainersArray: IComponent[] = await deepCopy(containersArray);
      let container = tempContainersArray.find((c) => c._id == editComp._id);
      if (container) {
        container.name = value;
        setContainersArray(tempContainersArray);
      }
    }
    SET.comp(editComp);
  };

  const applyChangeInContainer = async (value: any) => {
    let tempComp = await deepCopy(selectedContainer);
    let editComp = tempComp.components.find(
      (x: IComponent) => x._id === selectedComponentIDs[0]
    );
    if (props.valueMultiplier) {
      value = value * props.valueMultiplier;
    }
    editComp[`${props.edit}`] = value;
    return tempComp;
  };

  const applyNormalChange = async (value: any) => {
    let selectedDashboard = await deepCopy(GET.dash());
    let tempComponentsArray = await selectedDashboard?.components;
    let editComp = tempComponentsArray.find(
      (x: IComponent) => x._id === selectedComponentIDs[0]
    );
    if (props.valueMultiplier) {
      value = value * props.valueMultiplier;
    }
    editComp[`${props.edit}`] = value;
    return editComp;
  };

  return (
    <Box
      className="componentInputContainer"
      width={props.w ?? "45%"}
      borderBottom={props.borderBottom}
      borderTop={props.borderTop}
      bgcolor={props.disabled ? 'transparent' : '#222'}
    >
      <span className="componentInputLabel">
        {props.edit === "polling" ? "Refresh Frequency (seconds)" : props.edit}
      </span>
      {props.type === "input" && (
        <ControlWhiteTextField
          variant="standard"
          className="input componentInput"
          type="text"
          disabled={props.disabled}
          value={tmpValue ?? ''}
          onChange={handleChange}
          onBlur={applyChanges}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter") {
              applyChanges(e);
            }
          }}
        />
      )}
      {props.type === "number" && (
        <ControlWhiteTextField
          variant="standard"
          className="input componentInput"
          type="text"
          disabled={props.disabled}
          value={tmpValue ?? ''}
          onChange={handleNumberChange}
          onBlur={applyNumberChanges}
          sx={{ color: props.disabled ? '#333' : '#ccc' }}
          onKeyDown={(e) => {
            if (DELETE_SHORTCUT(e)) {
              e.stopPropagation();
            }
            if (e.key === "Enter") {
              applyNumberChanges(e);
            }
          }}
          error={isNAN}
          FormHelperTextProps={{ color: '#ff0000' }}
          helperText={isNAN ? "please enter a valid value" : ""}
        />
      )}
      {props.type === "slider" && (
        <Box sx={{ width: "90%" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                value={typeof tmpValue === "number" ? tmpValue : 0}
                onChange={handleSliderChange}
                onChangeCommitted={applyChanges}
                marks
                step={1}
                min={1}
                max={maxWidth}
                sx={{ color: "white" }}
                aria-labelledby="input-slider"
              />
            </Grid>
            <Grid item>
              <Typography>{tmpValue}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {props.type === "colorpicker" && (
        <span className="CPcontainer">
          <MuiColorInput
            value={tmpValue ? tmpValue : "#333"}
            onChange={handleChangeColor}
            onBlur={applyChanges}
            format={"hex"}
            //non chiama on select
            PopoverProps={{ onClose: applyChanges }}
            isAlphaHidden
            className="K-ARRAY-COLOR_PICKER"
          />
        </span>
      )}
      {props.type === "select" && props.opts && (
        <FormControl fullWidth className="componentSelect">
          <Autocomplete
            disableClearable={true}
            PaperComponent={CustomPaper}
            options={props.opts}
            noOptionsText={
              <Box display={"flex"} alignItems={"center"} color="#fff">
                <CircularProgress
                  color="info"
                  size={"14px"}
                  sx={{ mr: "8px" }}
                />
                <Typography fontSize={"13px"}>
                  Fetching {props.edit}s ...
                </Typography>
              </Box>
            }
            value={
              props.opts.find(
                (opt: IAutocompleteOPT) => opt.value === tmpValue
              ) ?? null
            }
            getOptionLabel={(option) => option.label} // Use the 'label' property for display
            sx={[
              { padding: "0 !important" },
              {
                "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                  paddingBottom: "0 !important",
                  fontSize: "13px !important",
                },
              },
            ]}
            onChange={(event, selectedOption) => {
              applyChanges(event, selectedOption); // Pass the 'value' to the handleChange method
            }}
            onKeyDown={(e) => {
              if (DELETE_SHORTCUT(e)) {
                e.stopPropagation();
              }
              if (e.key === "Enter") {
                applyNumberChanges(e);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
      )}
    </Box>
  );
}

//render the bg of select
const CustomPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      backgroundColor: "#222",
      border: "var(--primary_border)",
      color: "var(--grey_background-color)",
    }}
  />
);
