import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { deepCopy } from "../../../../helpers/utils";
import { IDashboard, IInstallation } from "../../../../common-interfaces/interfaces";
import { selectedInstallationAtom, isLoadingAtom } from "../../../../recoil/atoms";
import { useOnGet } from "../../../../hooks/useOnGet";
import { useOnSet } from "../../../../hooks/useOnSet";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function CopyDashboardButton(props: any) {
  const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [isLoading, setIsloading] = useRecoilState<boolean>(isLoadingAtom);
  const GET = useOnGet();
  const SET = useOnSet();

  const generateDash = async () => {
    const originalDashboard = selectedInstallation.configuration.dashboards.find(d => d._id === props.id)
    if (!originalDashboard) { return }
    let newDash: IDashboard = await deepCopy(originalDashboard)
    newDash._id = GET.id()
    newDash.description = `Generated copying the ${originalDashboard.name} dashboard with id: ${originalDashboard._id}`
    newDash.name = `${originalDashboard.name}_Copy`
    newDash.components.forEach(comp => {
      comp._id = GET.id()
      comp.services && comp.services.forEach(s => s._id = GET.id())
    })
    return newDash
  };

  const handleDashboardCreation = async () => {
    setIsloading(true);
    const newDashboard = await generateDash();
    if (newDashboard) {
      let tmpSelectedInstallation = await deepCopy(selectedInstallation);
      tmpSelectedInstallation.configuration.dashboards.push(newDashboard);
      SET.installation(tmpSelectedInstallation);
    }
    setIsloading(false);
  };

  return (

    <Button
      size="small"
      color="apple"
      variant="contained"
      title="Copy Dashboard"
      disabled={isLoading}
      onClick={handleDashboardCreation}
    >
      <ContentCopyIcon sx={{ color: '#ccc' }} />
    </Button>

  );
}
