import axios, { AxiosError, AxiosResponse } from "axios";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ILoggedUser } from "../local-interfaces/local-interfaces";
import { loggedUserAtom } from "../recoil/atoms";
import { enqueueSnackbar } from "notistack";

export const BASE_URL: string = process.env.REACT_APP_PORT
  ? `${process.env.REACT_APP_APIBASEURL}:${process.env.REACT_APP_PORT}`
  : `${process.env.REACT_APP_APIBASEURL}`;

function useApiWrapper() {
  const [loggedUser, setLoggedUser] = useRecoilState<ILoggedUser | undefined>(
    loggedUserAtom
  );
  const reset = useResetRecoilState(loggedUserAtom);
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    getWithAuth: requestWithAuth("GET"),
    postWithAuth: requestWithAuth("POST"),
    putWithAuth: requestWithAuth("PUT"),
    deleteWithAuth: requestWithAuth("DELETE"),
  };

  function request(
    method: string
  ): (url: string, body: any) => Promise<AxiosResponse<any, any>> {
    return async (url: string, body: any): Promise<AxiosResponse<any, any>> => {
      let additional: any = {
        headers: {},
      };
      if (method !== "GET") {
        additional.data = body;
        additional.headers["Content-Type"] = "application/json";
      }
      const response = await axios({
        url: `${BASE_URL}/${url}`,
        method: method,
        ...additional,
      });
      return handleResponse(response);
    };
  }

  function requestWithAuth(
    method: string
  ): (url: string, body: any) => Promise<AxiosResponse<any, any>> {
    return async (url: string, body: any): Promise<any> => {
      //todo antonio: restore checkout or understand if those lines of code can removed
      //const auth = await checkAuth();
      if (true) {
        let additional: any = {
          headers: {
            Authorization: `Bearer ${loggedUser?.token}`,
          },
        };
        if (method !== "GET") {
          additional.data = body;
          additional.headers["Content-Type"] = "application/json";
        }
        return axios({
          url: `${BASE_URL}/${url}`,
          method: method,
          ...additional,
        })
          .catch(function (error: AxiosError) {
            if (!error.response) return Promise.reject(error);
            switch (error.response.status) {
              case 400:
                const timeout = setTimeout(() => {
                  enqueueSnackbar(
                    "there was an error, please try to login again",
                    {
                      variant: "error",
                    }
                  );
                  clearTimeout(timeout)
                }, 200)
                reset();
                localStorage.removeItem("logged_user");
                break;
              case 403:
                enqueueSnackbar("session expired, please login", {
                  variant: "error",
                });
                reset();
                break;
              case 404:
                return Promise.reject("NOT FOUND");
              case 409:
                return Promise.reject("ALREADY EXISTS");
              default:
                break;
            }
            return Promise.reject(error.response.status);
          })
          .then(handleResponse);
      } else {
        return Promise.reject("unauthorized");
      }
    };
  }

  function handleResponse(
    response: AxiosResponse<any, any>
  ): Promise<AxiosResponse<any, any>> {
    return Promise.resolve(response);
  }

  function handleResponseWithAuth(
    response: AxiosResponse<any, any>
  ): Promise<AxiosResponse<any, any>> {
    if (response.status === 403) {
      setLoggedUser(undefined);
      return Promise.reject("unauthorized");
    }
    if (response.status !== 200) {
      return Promise.reject("failed");
    }
    return Promise.resolve(response);
  }

  async function checkAuth(): Promise<boolean> {
    try {
      const res = await axios.post(`${BASE_URL}/auth/checkauth`, {
        token: loggedUser?.token,
      });
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      setLoggedUser(undefined);
      reset();
      localStorage.removeItem("logged_user");
      return false;
    }
  }
}

export { useApiWrapper };
