import { Typography, Button, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react'
import { isParamSegment, removeParamIdentifier } from '../common/utils';
import { useRecoilState } from 'recoil';
import { IQuery } from '../../../../common-interfaces/interfaces';
import { serviceParamsAtom } from '../../../../recoil/Atom';

const HandleRowBodyValue: FC<{
  propsValue: string,
  handleDoubleClickOnQuery: (query: IQuery) => void
  dispatchChange: (value: string) => void
}> = ({ propsValue, handleDoubleClickOnQuery, dispatchChange }) => {

  const [serviceParams] = useRecoilState<IQuery[]>(serviceParamsAtom);
  const [value, setValue] = useState<string>(propsValue)

  useEffect(() => {
    setValue(propsValue)
  }, [propsValue]);

  if (!value) {
    return <Typography fontSize={22.5} ml={2} color={'#aaa'}>
      {'Add segment or query'}
    </Typography>
  }

  if (serviceParams.length > 0 && isParamSegment(value)) {
    const query = serviceParams?.find(p => p.key === removeParamIdentifier(value));
    if (!query) return null;
    return (
      <Button onClick={() => handleDoubleClickOnQuery(query)} sx={{ py: 0, width: '45%' }}>
        <Typography textAlign={'center'} fontSize={25}>{`" @@${query.key}@@ "`}</Typography>
      </Button>
    );
  }

  const handleChangeConfirm = () => {
    dispatchChange(value)
  }

  return (
    <>
      <Typography fontSize={25} mr={2}>"</Typography>
      <TextField
        variant='standard'
        inputProps={{ min: 0, style: { textAlign: 'center', fontSize: 25 } }}
        onChange={(ev) => dispatchChange(ev.target.value)}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            handleChangeConfirm();
          }
        }}
        onBlur={handleChangeConfirm}
        sx={{ width: '45%', color: 'var(--white-color)' }}
        value={value}
      />
      <Typography fontSize={25} ml={2}>"</Typography>
    </>
  );
}
export default HandleRowBodyValue