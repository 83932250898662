import { Box, Button, Typography } from "@mui/material";
import { FC, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InstallationDeviceModal from "./InstallationDeviceModal";

const HandleAddDevice: FC<{ hideLabel?: boolean }> = ({ hideLabel }) => {

  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  }

  return (
    <Box>
      <Button
        onClick={handleDialogOpen}
        color="steelBlue"
        className="primaryButton addDashboardButton"
        sx={{ color: "white", padding: 0, minWidth: '100%', minHeight: '100%', maxWidth: '100%', maxHeight: '100%' }}
      >
        {!hideLabel && <Typography mr={0.5} fontSize={13}>Device</Typography>}
        <AddCircleOutlineIcon sx={{ display: 'flex', mb: 0.25 }} fontSize="small" />
      </Button>
      <InstallationDeviceModal open={open} setOpen={setOpen} />
    </Box>
  );
}

export default HandleAddDevice;