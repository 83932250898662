import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useRequestNotificationUtils from "./hooks/useRequestNotificationUtils";
import InboxIcon from '@mui/icons-material/Inbox';
import { useRecoilState } from "recoil";
import { allRequestsAtom, filterParamAtom } from "./utils/requestAtoms";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

const buttonContainer = { position: 'absolute', top: 0, bottom: 0, right: 0, padding: 0, height: '100%', borderRight: '.5px solid #333' };
const toggleVisibilityButton = { height: '100%', width: '100%', padding: 0, minWidth: '100%', maxWidth: '100%' };

const RequestSidebar = () => {
  const [allRequests] = useRecoilState(allRequestsAtom);
  const [filterParam] = useRecoilState(filterParamAtom)
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const [allRequestsLength, setAllRequestsLength] = useState<number>(0);
  const [receivedRequestsLength, setReceivedRequestsLength] = useState<number>(0);
  const [sentRequestsLength, setSentRequestsLength] = useState<number>(0);
  
  const { filterRequest, getPendingRequestRecived, getPendingRequestSent, getPendingRequest } = useRequestNotificationUtils();

  useEffect(() => {
    setAllRequestsLength(getPendingRequest().length);
    setReceivedRequestsLength(getPendingRequestRecived().length);
    setSentRequestsLength(getPendingRequestSent().length);
  }, [allRequests, getPendingRequestRecived, getPendingRequestSent, getPendingRequest]);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <Grid item xs={isHidden ? 0.15 : 12} md={isHidden ? 0.15 : 2} sx={{ position: 'relative', bgcolor: '#121212' }}>
      <Grid item xs={0} md={isHidden ? 0 : 11} sx={{ py: 5, px: 2 }}>
        {!isHidden &&
          <Box>
            <Button fullWidth
              onClick={() => filterRequest('all')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: filterParam === 'all' ? 'var(--secondary-color)' : '#333',
                color: filterParam === 'all' ? '#333' : '#ccc',
                my: .5,
                '&: hover': {
                  color: '#fff',
                  bgcolor: 'var(--secondary-color)',
                  opacity: .6
                }
              }}>
              <InboxIcon sx={{ fontSize: 20, paddingRight: '5%', marginLeft: '10%' }} />
              <Typography sx={{ fontWeight: filterParam === 'all' ? 600 : 200 }}>All Requests</Typography>
              <Typography paddingRight={'5%'} ml={'auto'}>{allRequestsLength}</Typography>
            </Button>

            <Button fullWidth onClick={() => filterRequest('to')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: filterParam === 'to' ? 'var(--secondary-color)' : '#333',
                color: filterParam === 'to' ? '#333' : '#ccc',
                my: .5,
                '&: hover': {
                  color: '#fff',
                  bgcolor: 'var(--secondary-color)',
                  opacity: .6
                }
              }}>
              <ArchiveIcon sx={{ fontSize: 20, paddingRight: '5%', marginLeft: '10%' }} />
              <Typography sx={{ fontWeight: filterParam === 'to' ? 600 : 200 }}>Received</Typography>
              <Typography paddingRight={'5%'} ml={'auto'}>{receivedRequestsLength}</Typography>
            </Button>

            <Button fullWidth onClick={() => filterRequest('from')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: filterParam === 'from' ? 'var(--secondary-color)' : '#333',
                color: filterParam === 'from' ? '#333' : '#ccc',
                my: .5,
                '&: hover': {
                  color: '#fff',
                  bgcolor: 'var(--secondary-color)',
                  opacity: .6
                }
              }}>
              <UnarchiveIcon sx={{ fontSize: 20, paddingRight: '5%', marginLeft: '10%' }} />
              <Typography sx={{ fontWeight: filterParam === 'from' ? 600 : 200 }}>Sent</Typography>
              <Typography paddingRight={'5%'} ml={'auto'}>{sentRequestsLength}</Typography>
            </Button>

          </Box>
        }
      </Grid>
      <Grid item xs={0} md={isHidden ? 12 : 1} sx={buttonContainer} bgcolor={'#000'}>
        <Button sx={toggleVisibilityButton} onClick={toggleVisibility}>{isHidden ? <ChevronRight color="info" /> : <ChevronLeft color="info" />}</Button>
      </Grid>
    </Grid >
  );
}

export default RequestSidebar;
