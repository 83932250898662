import { useResetRecoilState } from "recoil";
import { currentSelectedComponentAtom, uniquesDurationAtom } from "../recoil/Atom";
import { selectedInstallationAtom, allComponentsAtom, allPlantsFullAtom, allUsersAtom, selectedDashboardAtom } from "../recoil/atoms";

function useResetAllAtoms() {
  const resetSI = useResetRecoilState(selectedInstallationAtom);
  const resetAC = useResetRecoilState(allComponentsAtom);
  const resetAPF = useResetRecoilState(allPlantsFullAtom);
  const resetAU = useResetRecoilState(allUsersAtom);
  const resetSD = useResetRecoilState(selectedDashboardAtom);
  const resetCSC = useResetRecoilState(currentSelectedComponentAtom);
  const resetUD = useResetRecoilState(uniquesDurationAtom);


  const resetAll = () => {
    resetSI();
    resetAC();
    resetAPF();
    resetAU();
    resetSD();
    resetCSC();
    resetUD();
  };
  return { resetAll }
}

export default useResetAllAtoms;
