import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SyntheticEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { docsTreeAtom } from "../common/DocsAtom";
import TooltipOverflow from "../../../common-components/common/TooltipOverflow";
import { DocsTreeNode } from "../../../local-interfaces/docs";

const typographyStyle = { ":hover": { color: 'var(--secondary-color)' } }
const treeItemStyle = {
    // lear color
    color: "grey",
    "& .MuiTreeItem-content": { paddingX: "0" },
    // non leaf color
    "& .MuiTreeItem-iconContainer:has(svg)+div": { color: 'white' },
    "& .MuiTreeItem-label": { paddingX: "0" },
    "& .MuiCollapse-root": {
        boxSizing: "border-box",
        paddingTop: "1px",
        paddingLeft: "7px",
        marginLeft: "7px",
        borderLeft: "1px dashed var(--selected-color)",
    },
}
const iconStyle = { color: "white", ":hover": { color: "var(--secondary-color)" } }
const treeViewStyle = { width: "100%" }

const DocsTree = ({ callable, withValue }: { callable: React.Dispatch<React.SetStateAction<boolean>>, withValue: boolean }) => {

    const [expandedTreeItems, setExpandedTreeItems] = useState<string[]>([])
    const [docsTree] = useRecoilState(docsTreeAtom)
    const navigate = useNavigate()

    const recInitExpandedTreeItems = (tree: DocsTreeNode[], output: string[] = []): string[] => {
        tree.forEach(obj => {
            if (obj.subcontent)
                output = [...output, obj.id, ...recInitExpandedTreeItems(obj.subcontent)]
        })
        return output
    }

    useEffect(() => setExpandedTreeItems(recInitExpandedTreeItems(docsTree)), [docsTree])

    const handleTreeViewClick = (event: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
        if ((event.target as Element).tagName.toLowerCase() !== 'p')
            setExpandedTreeItems(nodeIds)
    }

    const renderNodeStructure = (node: DocsTreeNode) => {

        const handleTreeItemClick = (event: any) => {
            if ((event.target as Element).tagName.toLowerCase() === 'p') {
                callable(withValue)
                navigate(`/docs/${node.id}/${node.label.toLowerCase()}`)
            }
        }

        return (<TreeItem
            key={node.id}
            nodeId={node.id}
            label={<TooltipOverflow
                tooltipChildren={
                    <Typography
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        fontSize={"1.2em"}
                        sx={typographyStyle}
                        children={node.label}
                    />}
                tooltipTitle={node.label}
            />}
            sx={treeItemStyle}
            onClick={handleTreeItemClick}
        >
            {node.subcontent?.map(renderNodeStructure)}
        </TreeItem>)
    }

    return <TreeView
        expanded={expandedTreeItems}
        defaultCollapseIcon={<ExpandMoreIcon sx={iconStyle} />}
        defaultExpandIcon={<ChevronRightIcon sx={iconStyle} />}
        onNodeToggle={handleTreeViewClick}
        sx={treeViewStyle}
    >
        {docsTree.map(renderNodeStructure)}
    </TreeView>
};

export default DocsTree;