import { Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, PaperProps } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { useRecoilState } from "recoil";
import { useOnSet } from "../../../../hooks/useOnSet";
import { useOnGet } from "../../../../hooks/useOnGet";
import { usePosition } from "../../../../hooks/usePosition";
import { IComponent, IDashboard } from "../../../../common-interfaces/interfaces";
import { selectedComponentIDsAtom, isLinkingAtom } from "../../../../recoil/atoms";
import { deepCopy } from "../../../../helpers/utils";
import { ControlWhiteDialog } from "../../../../theme";
import LinkIcon from "@mui/icons-material/Link";

const HandleLink = () => {
  const position = usePosition();
  const GET = useOnGet();
  const SET = useOnSet();

  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);

  const selectedComponents: IComponent[] = useMemo(() => {
    let dash: IDashboard = JSON.parse(JSON.stringify(GET.dash()));
    if (!dash || !dash.components) {
      console.error("no dahsboard found");
      return []
    }
    return dash.components.filter(c => selectedComponentIDs.includes(c._id))
  }, [selectedComponentIDs])
  const [isLinking, setIsLinking] = useRecoilState(isLinkingAtom);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && event.ctrlKey)
      handleDialogOpen();
  };

  const linkComponents = async () => {
    if (isLinking)
      return;
    let dash: IDashboard = await deepCopy(GET.dash());
    if (!dash || !dash.components) {
      console.error("no dahsboard found");
      return
    }
    setIsLinking(true);
    let tmpComp = await deepCopy(selectedComponents[0]);
    tmpComp.origin = tmpComp._id;
    tmpComp._id = GET.id();
    tmpComp.name = "LINKED";
    tmpComp.description = "Linked Component";
    tmpComp.services = [];
    tmpComp.editable = true;
    tmpComp.slaves = selectedComponents.map(c => c._id);
    const coord = position.position(tmpComp.height, tmpComp.width);
    tmpComp.top = coord.top;
    tmpComp.left = coord.left;
    // check for multi-master
    tmpComp.masters = [];
    dash.components = dash.components.map(c => {
      if (selectedComponents.find(sc => sc._id === c._id))
        c.masters ? c.masters.push(tmpComp._id) : (c.masters = [tmpComp._id]);
      return c;
    });
    dash.components.push(tmpComp);
    SET.dash(dash);
    setIsLinking(false);
  };

  return (
    <>
      <Button
        type="submit"
        className="primaryButton"
        variant="contained"
        color="steelBlue"
        onClick={handleDialogOpen}
        sx={{ width: "60px" }}
      >
        {
          isLinking ?
            <CircularProgress sx={{ color: "black !important" }} size="20px" /> :
            <LinkIcon />
        }
      </Button>
      <ControlWhiteDialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        onKeyDown={(e) => {
          // catch enter key for
          if (e.key === "Enter") {
            linkComponents();
            handleDialogClose();
          }
        }}
      >
        <DialogTitle>Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to link selected components?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {selectedComponentIDs.length > 0 && <Button onClick={linkComponents}>Link</Button>}
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default HandleLink