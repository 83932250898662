import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';
import { currentSelectedComponentAtom, selectedServiceAtom, uniquesDurationAtom } from '../../../recoil/Atom';
import { Grid, Tab, Tabs } from '@mui/material';
import ServiceLevel from './ServiceLevel';
import EditComponentNavigation from './EditComponentNavigation';
import { IUniquesDuration } from '../../../local-interfaces/local-interfaces';
import { enqueueSnackbar } from 'notistack';
import { IComponent, IService } from '../../../common-interfaces/interfaces';
import { PASTE_SHORTCUT } from '../../../config/shortcut';
import { deepCopy } from '../../../helpers/utils';
import { useOnGet } from '../../../hooks/useOnGet';
import { useOnSet } from '../../../hooks/useOnSet';
import HandleAddLevel from '../../../not-used/HandleAddLevel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const tabStyle = { color: '#ccc' }
const tabsStyle = { borderRight: 1, borderColor: 'divider', color: '#ccc', pt: '10px' }

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
      width={'100%'}
      height={'100%'}
    >
      {value === index && <Box width="100%" height="100%">{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
  };
}

const EditComponentWorkspace = () => {

  const [currentSelectedService] = useRecoilState(selectedServiceAtom)
  const [comp, setComp] = useRecoilState(currentSelectedComponentAtom)
  const [uniquesDuration, setUniqueDuration] = useRecoilState<IUniquesDuration>(uniquesDurationAtom)

  const memorizedService = useRef(currentSelectedService);
  const memorizedComp = useRef(comp);
  const containerRef = React.useRef<any>(null);

  const { id } = useOnGet()
  const SET = useOnSet()

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    memorizedService.current = currentSelectedService;
    memorizedComp.current = comp;
  }, [currentSelectedService, comp]);

  const handleKeyPress = (event: any) => {
    if (PASTE_SHORTCUT(event)) {
      handleCopy();
    }
  };

  const handleCopy = async () => {
    if (!memorizedService.current)
      return enqueueSnackbar("There was an error", {
        variant: "error",
      });
    const tmpComp: IComponent = await deepCopy(memorizedComp.current);
    const tmpService: IService = await deepCopy(memorizedService.current);
    tmpService.origin = memorizedService.current._id;
    tmpService._id = id();
    if (memorizedComp.current.services) {
      const copyNumber = memorizedComp.current.services.filter(
        (service) => service.origin === memorizedService.current._id
      ).length;
      tmpService.name = copyNumber
        ? `${tmpService.name}_copy_${copyNumber}`
        : `${tmpService.name}_copy`;
    }
    tmpComp.services = [...(tmpComp.services ?? []), tmpService];
    setComp(tmpComp);
    SET.comp(tmpComp);
  };

  const handleChange = (_: any, value: number) => setUniqueDuration(currentValue => { return { ...currentValue, selectedDurationIndex: value } })

  return (
    <Grid
      ref={containerRef}
      item
      xs={7}
      container
      direction="column"
      height="100%"
      width='100%'
      position="relative"
      flexWrap='nowrap'
    >
      <Grid item xs={1} width='100%' container><EditComponentNavigation /></Grid>
      <Grid item xs={11} container overflow="auto">
        {/*
          REMOVE FOR DURATION
        <Grid
          item container
          xs={3}
          md={2}
          xl={1}
          width={'100%'}
          bgcolor={'#101010'}
          borderRight={'1px solid var(--primary_background-color)'}
          borderLeft={'1px solid var(--primary_background-color)'}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={uniquesDuration.selectedDurationIndex}
            onChange={handleChange}
            indicatorColor='secondary'
            textColor='secondary'
            sx={tabsStyle}
          >
            {uniquesDuration && uniquesDuration.durations.map(duration => <Tab label={`await ${duration}sec`} sx={tabStyle} key={duration} {...a11yProps(duration)} />)}
            <HandleAddLevel />
          </Tabs>
        </Grid> */}
        {uniquesDuration &&
          <Grid
            item
            container
            //REMOVE FOR
            // xs={9}
            // md={10}
            // xl={11}
            alignItems={'flex-start'}
          >
            {uniquesDuration.durations.map((duration, index) =>
              <TabPanel value={uniquesDuration.selectedDurationIndex} key={duration} index={index}>
                <ServiceLevel duration={duration} />
              </TabPanel>
            )}
          </Grid>
        }
      </Grid >
    </Grid >
  );
}
export default EditComponentWorkspace;
