export const HANDLER_HEIGHT = 14;
export interface ICellDimension {
  width: number;
  height: number;
}

export interface IWorkspaceLocation {
  x: number;
  y: number;
}

export const ItemTypes = {
  COMPONENT: "component",
  PREVIEW: "preview",
};

export interface IWorkspaceCell {
  x: number;
  y: number;
  busyByID?: string;
}

export function generateRandomColor() {
  var minBrightness = 228; // Minimum brightness threshold (adjust as desired)
  var color;
  do {
    // Generate random values for red, green, and blue channels
    var red = Math.floor(Math.random() * 256);
    var green = Math.floor(Math.random() * 256);
    var blue = Math.floor(Math.random() * 256);

    // Calculate the brightness of the color
    var brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    // Create the color string in hexadecimal format
    color = "#" + red.toString(16) + green.toString(16) + blue.toString(16);
  } while (brightness < minBrightness);

  return color;
}
