import { useEffect, useState } from "react";
import { useOnIcons } from "../../../../../hooks/useOnIcons";


function ComponentIcon(props: any) {
  const [compIcon, setCompIcon] = useState<string>();
  const [fontSize, setFontSize] = useState<number>();
  const { images } = useOnIcons()


  useEffect(() => {
    if (!props.comp) {
      return;
    }
    setCompIcon(props.comp?.icon);
    const tempFont = Math.min(props.comp.width, props.comp.height) * 50;
    setFontSize(tempFont);
  }, [props.comp]);

  return compIcon && fontSize ? images(compIcon, fontSize) : <></>
}

export default ComponentIcon;
