import { Box, Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ADMINTOOOL_COLUMNS } from "./adminToolConfig";
import { ControlWhiteTextField } from "../../theme";
import { useRecoilState } from "recoil";
import { adminToolSearchQueryAtom } from "./AdminToolAtom";
import AdminToolPopOver from "./AdminToolModal";
import CodeIcon from '@mui/icons-material/Code';
import React from "react";

const ColumnLoader: FC<{
  columnIndex: number;
  value: Record<string, any>;
}> = ({ columnIndex, value }) => {
  const [adminToolSearchQuery, setAdminToolSearchQuery] = useRecoilState(adminToolSearchQueryAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState<(Record<string, any>)[]>([]);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null);

  const handleInputChange = (value: string) => {
    setAdminToolSearchQuery({
      section: ADMINTOOOL_COLUMNS[columnIndex],
      value
    });
  };

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>, item: Record<string, any>) => {
    event.stopPropagation()
    setAnchor(event.currentTarget);
    setSelectedItem(item);
    setOpen(true);
  };

  useEffect(() => {
    if (value && value[ADMINTOOOL_COLUMNS[columnIndex]]) {
      setItems(Object.values(value[ADMINTOOOL_COLUMNS[columnIndex]]));
    }
  }, [value]);

  return (
    <Grid item xs={12 / ADMINTOOOL_COLUMNS.length} borderRight={ADMINTOOOL_COLUMNS.length === columnIndex + 1 ? 0 : 1} borderColor={'#333'}>
      <Box height={'100vh'} width={'100%'}>
        <Box height={'10vh'}>
          <Typography color={'#ccc'} fontSize={25}>{ADMINTOOOL_COLUMNS[columnIndex].toUpperCase()}</Typography>
          <ControlWhiteTextField
            variant="standard"
            className="input"
            type="text"
            label='_id:'
            onChange={e => handleInputChange(e.target.value)}
            value={
              adminToolSearchQuery && adminToolSearchQuery.section === ADMINTOOOL_COLUMNS[columnIndex]
                ? adminToolSearchQuery?.value
                : ''
            }
            sx={{ width: '80%' }}
          />
        </Box>
        <Box maxHeight={'90vh'} overflow={'auto'}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Tooltip title={item._id}>
                <Box
                  width={'100%'}
                  display={'flex'}

                  bgcolor={item._id === adminToolSearchQuery?.value ? '#ccc' : 'transparent'}>
                  <Button
                    sx={{ padding: 0 }}
                    onClick={(e) => openPopover(e, item)}
                  >
                    <CodeIcon sx={{ fontSize: 15, color: item._id === adminToolSearchQuery?.value ? '#000' : '#ccc' }} />
                  </Button>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    color={item._id === adminToolSearchQuery?.value ? '#000' : '#ccc'}
                    fontSize={15}
                    onDoubleClick={() => handleInputChange(item._id)}>
                    {item.name}
                  </Typography>
                </Box>
              </Tooltip>
              {open && selectedItem && (
                <AdminToolPopOver
                  item={selectedItem}
                  type={ADMINTOOOL_COLUMNS[columnIndex]}
                  open={open}
                  anchorEl={anchor}
                  onClose={() => setOpen(false)}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default ColumnLoader;
