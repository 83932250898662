import { Box, Grid, } from "@mui/material";
import { useEffect } from "react";
import "../../styles/EditDashboard.css";
import { useRecoilState, useResetRecoilState } from "recoil";
import { isLoadingAtom } from "../../recoil/atoms";
import EditdashboardLeftBar from "./leftBar/EditdashboardLeftBar";
import EditdashboardRightBar from "./rightBar/EditdashboardRightBar";
import Loading from "../Loading/Loading";
import useOnInit from "../../hooks/useOnInit";
import WorkspaceButtons from "./actions/WorkspaceButtons";
import WorkspaceContainer from "./workspace/WorkspaceContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GRIDCOL_Atom, GRIDROW_Atom, busyCellsAtom, containersArrayAtom, selectedContainerAtom } from "../../recoil/workspace";
import { useOnUser } from "../../hooks/useOnUser";
import BC from "../Layout/Breadcrumbs";
import Header from "../Layout/header/Header";

const EditDashboard = () => {
  const onUser = useOnUser()
  const onInit = useOnInit()

  const [isLoading] = useRecoilState(isLoadingAtom)
  const reset_containersArrayAtom = useResetRecoilState(containersArrayAtom)
  const reset_selectedContainerAtom = useResetRecoilState(selectedContainerAtom);
  const reset_busyCellsAtom = useResetRecoilState(busyCellsAtom)
  const reset_GRIDCOL_Atom = useResetRecoilState(GRIDCOL_Atom)
  const reset_GRIDROW_Atom = useResetRecoilState(GRIDROW_Atom)

  const resetWorkspaceAtoms = () => {
    reset_containersArrayAtom();
    reset_selectedContainerAtom();
    reset_busyCellsAtom();
    reset_GRIDCOL_Atom();
    reset_GRIDROW_Atom();
  };

  useEffect(() => {
    resetWorkspaceAtoms();
    let init = onInit.all();
    if (!init)
      onUser.logout();
  }, []);

  if (isLoading) return <Loading></Loading>;
  return (
    <Box className="editdashboardWrapper">
      <DndProvider backend={HTML5Backend}>
        <Header />
        <Grid width={"100%"} position={"relative"} borderBottom={"var(--primary_border)"}>
          <BC />
          <WorkspaceButtons />
        </Grid>
        <Grid width="100%" height="var(--workspace-height)" display="flex" justifyContent="space-between">
          <EditdashboardLeftBar />
          <WorkspaceContainer />
          <EditdashboardRightBar />
        </Grid>
      </DndProvider>
    </Box>
  );
};

export default EditDashboard;
