import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { adminToolFilterByAtom, adminToolSearchQueryAtom, adminToolStoreAtom } from "./AdminToolAtom";
import { IAdminToolStore, IAdminToolFilterBy, ADMINTOOOL_Value, emptyQueries } from "./adminToolConfig";

export default function useAdminToolFilterQuery() {
  const [filterBy, setFilterBy] = useRecoilState(adminToolFilterByAtom);
  const [adminToolSearchQuery] = useRecoilState(adminToolSearchQueryAtom);
  const [adminToolStore] = useRecoilState<IAdminToolStore>(adminToolStoreAtom);

  const resetFilter = useResetRecoilState(adminToolFilterByAtom)

  const handleFilter = async (acc: IAdminToolFilterBy = emptyQueries, value: string, section: ADMINTOOOL_Value): Promise<IAdminToolFilterBy> => {
    if (!adminToolStore) return acc;

    let prev = JSON.parse(JSON.stringify(acc));

    switch (section) {
      case ADMINTOOOL_Value.installation: {
        const selectedInstallation = adminToolStore[ADMINTOOOL_Value.installation][value];
        if (!selectedInstallation) break;

        prev.installation.id = selectedInstallation._id;
        return handleFilter(prev, selectedInstallation.configuration._id, ADMINTOOOL_Value.configuration);
      }

      case ADMINTOOOL_Value.configuration: {
        const selectedConfiguration = adminToolStore[ADMINTOOOL_Value.configuration][value];
        if (!selectedConfiguration) break;

        prev.configuration.id = selectedConfiguration._id;
        for (const dash of selectedConfiguration.dashboards) {
          prev = await handleFilter(prev, dash._id, ADMINTOOOL_Value.dashboard);
        }
        return prev;
      }

      case ADMINTOOOL_Value.dashboard: {
        const selectedDash = adminToolStore[ADMINTOOOL_Value.dashboard][value];
        if (!selectedDash) break;

        prev.dashboard.ids = [...prev.dashboard.ids, selectedDash._id];
        if (selectedDash.components) {
          for (const comp of selectedDash.components) {
            prev = await handleFilter(prev, comp._id, ADMINTOOOL_Value.component);
          }
        }
        return prev;
      }

      case ADMINTOOOL_Value.component: {
        const selectedComponent = adminToolStore[ADMINTOOOL_Value.component][value];
        if (!selectedComponent) break;

        prev.component.ids = [...prev.component.ids, selectedComponent._id];
        if (selectedComponent.services) {
          for (const serv of selectedComponent.services) {
            prev = await handleFilter(prev, serv._id, ADMINTOOOL_Value.service);
          }
        }
        return prev;
      }

      case ADMINTOOOL_Value.service: {
        const selectedService = adminToolStore[ADMINTOOOL_Value.service][value];
        if (!selectedService) break;

        prev.service.ids = [...prev.service.ids, selectedService._id];
        return prev;
      }

      default:
        return prev;
    }

    return prev;
  };

  const handleRevert = async (acc: IAdminToolFilterBy = emptyQueries, value: string, section: ADMINTOOOL_Value): Promise<IAdminToolFilterBy> => {
    if (!adminToolStore) return acc;

    let prev = JSON.parse(JSON.stringify(acc));

    switch (section) {
      case ADMINTOOOL_Value.service: {
        const selectedService = Object.values(adminToolStore[ADMINTOOOL_Value.service]).find(s => s._id === value);
        if (!selectedService) break;

        prev.service.ids = [...prev.service.ids, selectedService._id];
        const parentComponent = Object.values(adminToolStore[ADMINTOOOL_Value.component]).find(c => c.services && c.services.some(s => s._id === selectedService._id));
        if (parentComponent) {
          return handleRevert(prev, parentComponent._id, ADMINTOOOL_Value.component);
        }
        return prev;
      }

      case ADMINTOOOL_Value.component: {
        const selectedComponent = Object.values(adminToolStore[ADMINTOOOL_Value.component]).find(c => c._id === value);
        if (!selectedComponent) break;

        prev.component.ids = [...prev.component.ids, selectedComponent._id];
        const parentDashboard = Object.values(adminToolStore[ADMINTOOOL_Value.dashboard]).find(d => d.components && d.components.some(c => c._id === selectedComponent._id));
        if (parentDashboard) {
          return handleRevert(prev, parentDashboard._id, ADMINTOOOL_Value.dashboard);
        }
        return prev;
      }

      case ADMINTOOOL_Value.dashboard: {
        const selectedDashboard = Object.values(adminToolStore[ADMINTOOOL_Value.dashboard]).find(d => d._id === value);
        if (!selectedDashboard) break;

        prev.dashboard.ids = [...prev.dashboard.ids, selectedDashboard._id];
        const parentConfiguration = Object.values(adminToolStore[ADMINTOOOL_Value.configuration]).find(cfg => cfg.dashboards && cfg.dashboards.some(d => d._id === selectedDashboard._id));
        if (parentConfiguration) {
          return handleRevert(prev, parentConfiguration._id, ADMINTOOOL_Value.configuration);
        }
        return prev;
      }

      case ADMINTOOOL_Value.configuration: {
        const selectedConfiguration = Object.values(adminToolStore[ADMINTOOOL_Value.configuration]).find(cfg => cfg._id === value);
        if (!selectedConfiguration) break;

        prev.configuration.id = selectedConfiguration._id;
        const parentInstallation = Object.values(adminToolStore[ADMINTOOOL_Value.installation]).find(inst => inst.configuration && inst.configuration._id === selectedConfiguration._id);
        if (parentInstallation) {
          prev.installation.id = parentInstallation._id;
        }
        return prev;
      }

      default:
        return prev;
    }

    return prev;
  };

  const handleQueryChange = async (value: string, section: ADMINTOOOL_Value) => {
    let filtered
    if (section === ADMINTOOOL_Value.installation) {
      filtered = await handleFilter(filterBy, value, section);
    }
    else {
      const reverted = await handleRevert(filterBy, value, section);
      filtered = await handleFilter(reverted, reverted.configuration.id, ADMINTOOOL_Value.configuration);
    }
    setFilterBy(filtered);
  };

  useEffect(() => {
    if (adminToolSearchQuery && adminToolSearchQuery.value !== '') {
      const { value, section } = adminToolSearchQuery;
      handleQueryChange(value, section);
    } else {
      resetFilter()
    }
  }, [adminToolStore, adminToolSearchQuery]);

  return { filterBy, setFilterBy };
}
