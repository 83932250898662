import { useRecoilState, useResetRecoilState } from "recoil";
import { IComponent } from "../../../common-interfaces/interfaces";
import { GRIDCOL_Atom, GRIDROW_Atom, containersArrayAtom, selectedContainerAtom } from "../../../recoil/workspace";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { deepCopy } from "../../../helpers/utils";
import CloseIcon from "@mui/icons-material/Close";
import { useOnGet } from "../../../hooks/useOnGet";

const buttonStyle: React.CSSProperties = { marginLeft: "1px", marginTop: "1px" }

const fakeTab: React.CSSProperties = {
  display: "flex",
  borderRadius: "5px 5px 0 0",
  cursor: "pointer",
  userSelect: "none",
  marginRight: "1px",
  minWidth: "125px",
  alignItems: "center",
  border: "solid 1px var(--grey-color)",
  borderBottom: "none",
};

const closeButtonStyle: React.CSSProperties = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: 0,
  border: "none",
  height: "100%",
};

const tabTitle: React.CSSProperties = {
  fontFamily: `"Roboto","Helvetica","Arial", sans serif`,
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: 1.75,
  letterSpacing: "0.02857em",
  border: "none",
};

const selected: React.CSSProperties = {
  background: "var(--primary_background-color)",
  color: "var(--secondary-color)",
};

const unselected: React.CSSProperties = {
  background: "var(--selected-color)",
  color: "#888383",
};

const WorkspaceSelector = () => {
  const GET = useOnGet();

  const [containersArray, setContainersArray] = useRecoilState<IComponent[]>(containersArrayAtom);
  const [_, setGRIDCOL] = useRecoilState(GRIDCOL_Atom);
  const [__, setGRIDROW] = useRecoilState(GRIDROW_Atom);
  const [selectedContainer, setSelectedContainer] = useRecoilState(selectedContainerAtom);
  const resetGRIDCOL = useResetRecoilState(GRIDCOL_Atom);
  const resetGRIDROW = useResetRecoilState(GRIDROW_Atom);
  const resetWorkspace = useResetRecoilState(selectedContainerAtom);

  const handleClickOnWorkspace = () => {
    resetGRIDCOL();
    resetGRIDROW();
    resetWorkspace();
  };

  const WorkspaceTab = ({ container }: any) => {
    const handleSelected = selectedContainer?._id === container._id ? selected : unselected;

    const selectedOrUnselectedFakeTabStyle = { ...fakeTab, ...handleSelected };
    const selectedOrUnselectedTabTitleStyle = { ...tabTitle, ...handleSelected };
    const selectedOrUnselectedCloseButtonStyle = { ...closeButtonStyle, ...handleSelected };

    const handleClickOnContainer = () => {
      const tmpContainer = GET.comp(container._id);
      if (tmpContainer) {
        setGRIDCOL(tmpContainer.width);
        setGRIDROW(tmpContainer.height);
        setSelectedContainer(tmpContainer);
      }
    };

    const handleRemoveContainer = async (ev: any) => {
      ev.stopPropagation();
      let tempContainer: IComponent[] = await deepCopy([...(containersArray ?? [])]);
      tempContainer = tempContainer.filter(c => c._id !== container._id);
      selectedContainer?._id === container._id && handleClickOnWorkspace();
      setContainersArray(tempContainer);
    };

    return (
      <ButtonGroup style={selectedOrUnselectedFakeTabStyle}>
        <Button style={selectedOrUnselectedTabTitleStyle} onClick={handleClickOnContainer}>
          {container.name.toUpperCase()}
        </Button>
        <Button style={selectedOrUnselectedCloseButtonStyle} onClick={handleRemoveContainer}>
          <CloseIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    );
  };

  const selectedOrUnselectedStyle = { ...fakeTab, ...(!selectedContainer ? selected : unselected) };
  return (
    <Grid item xs={12} bgcolor="#282828" borderBottom="var(--primary_border)">
      <Button
        style={selectedOrUnselectedStyle}
        onClick={handleClickOnWorkspace}
        sx={buttonStyle}
        children="WORKSPACE"
      />
      {containersArray?.map(container => <WorkspaceTab container={container} key={container._id} />)}
    </Grid>
  );
}

export default WorkspaceSelector