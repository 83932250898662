import { Box } from "@mui/material";
import { useDrop } from "react-dnd";
import { useOnDND } from "../../../../../hooks/useOnDND";
import { ItemTypes } from "./dndUtils";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedContainerAtom } from "../../../../../recoil/workspace";
import { useOnGet } from "../../../../../hooks/useOnGet";
import { Print, deepCopy } from "../../../../../helpers/utils";
import { IComponent, IDashboard } from "../../../../../common-interfaces/interfaces";
import { useOnSet } from "../../../../../hooks/useOnSet";
import { selectedDashboardAtom } from "../../../../../recoil/atoms";
import { usePosition } from "../../../../../hooks/usePosition";
import ComponentInside from "./ComponentInside";

export default function ContainerComponent(props: any) {
  const [componentOrigin, setComponentOrigin] = useState<IComponent>();
  const [componentInside, setComponentInside] = useState<IComponent[]>();
  const [selectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [selectedContainer] = useRecoilState(selectedContainerAtom);
  const GET = useOnGet();
  const SET = useOnSet();
  const DND = useOnDND();
  const POSITION = usePosition();

  // todo deepcopy service 
  const handleDropInside = async (ID: string, type: string) => {
    Print(["dropped inside the container ", componentOrigin?.name]);
    if (!componentOrigin) {
      return Print(["no component origin"]);
    }
    let tmpDash: IDashboard = await deepCopy(selectedDashboard);
    let tmpContainer: IComponent | undefined = tmpDash.components.find(
      (c) => c._id === componentOrigin?._id
    );
    if (!tmpContainer) return console.error("no container");
    let droppedComp: IComponent | undefined = getDroppedCompWithPosition(ID);
    if (!droppedComp) return;
    if (type === "preview") {
      droppedComp.editable = true;
      droppedComp.origin = droppedComp._id;
      droppedComp._id = GET.id();
    }
    tmpContainer.components = [...(tmpContainer.components ?? []), droppedComp];
    tmpDash.components = tmpDash.components.filter((c) => c._id !== ID);
    setComponentInside(tmpContainer.components);
    await SET.dash(tmpDash);
    await SET.syncAllComp(tmpContainer);
  };

  const getDroppedCompWithPosition = (ID: string) => {
    if (!componentOrigin) {
      return undefined;
    }
    let droppedComp: IComponent = DND.getWorkspaceComponent(ID);
    let coord = POSITION.checkContainer(
      droppedComp.height,
      droppedComp.width,
      componentOrigin
    );
    if (
      coord.top + droppedComp.height > componentOrigin.height ||
      coord.left + droppedComp.width > componentOrigin.width
    ) {
      return undefined;
    }
    droppedComp.top = coord.top + componentOrigin.top;
    droppedComp.left = coord.left + componentOrigin.left;
    return droppedComp;
  };

  const [{ isOvering }, drop] = useDrop(
    //DRAG AND DROP
    () => ({
      accept: [ItemTypes.COMPONENT, ItemTypes.PREVIEW],
      drop: async (item: any, monitor: any) => {
        const itemType = monitor.getItemType();
        handleDropInside(item.ID, itemType);
      },
      collect: (monitor) => ({
        isOvering: !!monitor.isOver(),
        ItemType: monitor.getItemType(),
      }),
      canDrop: (item) => {
        let canDrop = getDroppedCompWithPosition(item.ID);
        return canDrop ? true : false;
      },
    }),
    [selectedContainer, props]
  );

  useEffect(() => {
    if (!props.comp) {
      return;
    }
    setComponentOrigin(props.comp);
    setComponentInside(props.comp?.components);
  }, [props.comp, selectedDashboard, selectedContainer]);

  return (
    <>
      <Box
        style={props.style}
        sx={{
          display: "flex",
        }}
        ref={drop}
      >
        {componentInside &&
          componentInside.map((c) => (
            <ComponentInside
              key={c._id}
              compInside={c}
              containerX={componentOrigin?.left}
              containerY={componentOrigin?.top}
            ></ComponentInside>
          ))}
      </Box>
    </>
  );
}
