import { FC, useEffect, useMemo, useState } from "react"
import { serviceParamsAtom } from "../../../../recoil/Atom";
import { useRecoilState } from "recoil";
import { IQuery } from "../../../../common-interfaces/interfaces";
import { isParamSegment, removeParamIdentifier } from "./utils";
import HandleQuerySegment from "./HandleQuerySegment";
import HandleStringSegment from "./HandleStringSegment";
import { Grid, Button, Typography, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { MdEdit } from "react-icons/md";
import { ControlWhiteDialog } from "../../../../theme";
import HandleAddSegment from "./HandleAddSegment";
import { emptyQuery } from "../../../../config/config";

const buttonStyle = { maxWidth: '100%', border: '.5px solid #fff', '&:hover': { borderColor: 'var(--secondary-color)' } }
const typographyStyle = { maxWidth: '100%', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: '14px', color: '#fff' }
const controlWhiteDialogStyle = { height: '850px' }

const RenderSplittedString: FC<{
    label: string,
    handleUpdate: (params: IQuery[], joinedString: string) => void,
    inputString: string,
    divider: string
}> = ({ label, handleUpdate, inputString, divider }) => {
    const [serviceParams, setServiceParams] = useRecoilState<IQuery[]>(serviceParamsAtom);

    const [splittedInput, setSplittedInput] = useState<string[]>([]);
    const [editingQuery, setEditingQuery] = useState<IQuery | undefined>();
    const [ep, setEp] = useState<string>('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (splittedInput.length === 0) {
            setEp('')
            return;
        }
        setEp(splittedInput.map(segment => {
            if (isParamSegment(segment))
                return serviceParams?.find(p => p.key === removeParamIdentifier(segment))?.value || segment;
            return segment;
        }).join(divider));
    }, [splittedInput, serviceParams]);

    useEffect(() => {
        setEp(inputString);
        inputString && setSplittedInput(inputString.split(divider).filter(s => s !== ""));
    }, [inputString]);

    const splittedEpMemo = useMemo(() => splittedInput.join(divider), [splittedInput])

    const handleDialogClose = async () => {
        handleUpdate(serviceParams, splittedEpMemo)
        setOpen(false);
        setEditingQuery(undefined)
    };

    const setOpenTrue = () => {
        setOpen(true)
    }

    const handleDispatch = (newValue: string) => {
        newValue.trim() && setSplittedInput(prevValue => [...prevValue, ...newValue.split(divider).filter(s => s !== "")]);
    }

    const handleDoubleClickOnQuery = (query: IQuery) => {
        setEditingQuery({ ...query });
    };

    const handleEditQuery = (param: IQuery) => {
        setServiceParams(prevValue => prevValue.map(v => v.key === param.key ? { ...param } : v));
    };

    const handleDeleteQuery = (key: string) => {
        const editedParams: IQuery[] = serviceParams.filter(p => p.key !== key)
        setServiceParams(editedParams)
        const segment = splittedInput.find(segment => isParamSegment(segment) && removeParamIdentifier(segment) === key)
        const tmpSplittedInput = [...splittedInput.filter(s => s !== segment)]
        setSplittedInput(tmpSplittedInput)
        handleUpdate(editedParams, tmpSplittedInput.join(divider))
        setEditingQuery({ ...emptyQuery })
    }

    const handleSplittedRender = () => {
        return splittedInput.map((segment: string, index: number) => {
            if (serviceParams.length > 0 && isParamSegment(segment)) {
                const query = serviceParams?.find(p => p.key === removeParamIdentifier(segment));
                if (!query)
                    return null;
                return <HandleQuerySegment key={`${segment}_${index}`} segment={query} handleDoubleClick={handleDoubleClickOnQuery} dispatchSetQuery={handleEditQuery} divider={divider} handleDelete={handleDeleteQuery} />
            }
            return <HandleStringSegment key={`${segment}_${index}`} segment={segment} index={index} cb={setSplittedInput} divider={divider} />
        });
    };

    return (
        <Grid container alignItems={'space-around'} height={'70%'} direction={'column'} justifyContent={'space-around'}>
            <Typography className='no-underline-title' textAlign={'start'}>{label}</Typography>
            <Button variant='outlined' onClick={setOpenTrue} sx={buttonStyle} startIcon={<MdEdit color='#ccc' />}>
                <Typography
                    title={splittedEpMemo}
                    sx={typographyStyle}
                >
                    {splittedInput[0] ? ep : 'Add Endpoint'}
                </Typography>
            </Button>
            <ControlWhiteDialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={handleDialogClose}
                PaperProps={controlWhiteDialogStyle}
            >
                <DialogTitle>Edit Endpoint <Typography variant='body2'>{ep}</Typography></DialogTitle>
                <DialogContent>
                    <Grid container item direction="column" p={1} height={'680px'}>
                        <Grid item xs container direction="row" overflow="auto" px={1} borderRadius={2} boxSizing="border-box" bgcolor="#383838">
                            <Grid item container maxHeight={'33%'}>
                                {handleSplittedRender()}
                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            <HandleAddSegment cb={handleDispatch} editingQuery={editingQuery} handleDelete={handleDeleteQuery} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                </DialogActions>
            </ControlWhiteDialog>
        </Grid>
    );

}

export default RenderSplittedString