import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import GroupIcon from "@mui/icons-material/Group";
import ReactLoading from "react-loading";
import { allPlantsFullAtom } from "../../../recoil/atoms";
import { IPlantFull, IPlantUser } from "../../../common-interfaces/interfaces";
import { deepCopy } from "../../../helpers/utils";
import { useOnGet } from "../../../hooks/useOnGet";
import { useOnSet } from "../../../hooks/useOnSet";
import { useApiWrapper } from "../../../hooks/useApiWrapper";
import { ControlWhiteDialog } from "../../../theme";
import { wizardSelectedPlantAtom } from "../../../recoil/CreateInstallationAtoms";
import LinkInstallationToClient from "../common/LinkInstallationToClient";

const HandleEditInstallation: FC<{
  plantID: number;
  text?: string;
  ml?: string | number;
}> = ({ plantID, text, ml }) => {
  const [allPlantsFull, setAllPlantFull] = useRecoilState(allPlantsFullAtom);
  const [selectedPlant, setSelectedPlant] = useRecoilState<
    IPlantFull | undefined
  >(wizardSelectedPlantAtom);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiWrapper = useApiWrapper();
  const SET = useOnSet();
  const GET = useOnGet();
  const resetSp = useResetRecoilState(wizardSelectedPlantAtom);

  const init = async () => {
    let plantFull: IPlantFull | undefined = allPlantsFull.find((pf) => {
      if (pf.installationName === undefined) return;
      return pf.plant.id === plantID;
    });
    setSelectedPlant(plantFull);
  };

  const handleDialogOpen = () => {
    resetSp();
    init();
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleEditPlant = async (): Promise<IPlantUser[]> => {
    let tempSelectedPlant: IPlantFull = await deepCopy(selectedPlant);
    tempSelectedPlant.clients = await deepCopy(selectedPlant?.clients);
    let tmpAllPlant: IPlantFull[] = await deepCopy(allPlantsFull);
    tmpAllPlant = tmpAllPlant.map((pf) => {
      if (pf.plant.id === plantID) {
        return tempSelectedPlant;
      }
      return pf;
    });
    setAllPlantFull(tmpAllPlant);
    const url = "api/plant/edituserplantandinstallation";
    let client = await apiWrapper.postWithAuth(url, tempSelectedPlant);
    return client.data.clients;
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    await handleEditPlant();
    handleDialogClose();
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip
        title={"EDIT CLIENTS"}
        placement="top"
        PopperProps={{ sx: { pointerEvents: "none" } }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <Button
          onClick={handleDialogOpen}
          className="primaryButton"
          sx={{
            padding: 0,
            minWidth: "100%",
            minHeight: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            color: "#fff",
          }}
        >
          {text}
          <GroupIcon fontSize="small" sx={{ ml: ml }} />
        </Button>
      </Tooltip>
      <ControlWhiteDialog open={open} fullWidth onClose={handleDialogClose}>
        <DialogTitle>Edit {selectedPlant?.plant?.name} clients</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box
              height={"180px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {" "}
              <ReactLoading type={"bars"} color={"#ccc"} width={"5vw"} />
            </Box>
          ) : (
            <>
              <DialogContentText mt={2}>
                <Box display={"flex"}>
                  <Typography mr={2}>Current selected clients: </Typography>
                  <Box>
                    {selectedPlant?.clients &&
                      selectedPlant?.clients.length > 0 &&
                      selectedPlant.clients.map((client) => (
                        <Typography key={client.user.email}>
                          {client.user.first_name} {client.user.last_name}
                        </Typography>
                      ))}
                  </Box>
                </Box>
              </DialogContentText>
              <Box className="center" mb="20px">
                <LinkInstallationToClient></LinkInstallationToClient>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading || (!plantID && !selectedPlant?.clients)}
            color="steelBlue"
            onClick={handleConfirm}
            sx={{
              m: 2,
              border: "1px solid #444 !important",
              px: 4,
              background: "var(--primary_background-color)",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
};

export default HandleEditInstallation;
